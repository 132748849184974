import { viewsetActions } from '@/service/plugin';

const extraActions = {
  activate: { path: 'activate', method: 'post', detail: true },
};
const opsPriceBooks = viewsetActions('pricebooks', { basePath: '/api/ops/', extraActions });

const opsPriceBookEntries = viewsetActions('pricebook-entries', { basePath: '/api/ops/' });

export {
  opsPriceBooks,
  opsPriceBookEntries,
};
