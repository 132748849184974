const moduleState = {
  countryMap: {},
};

const moduleGetters = {
  countryItems: (state) => {
    const countries = Object.entries(state.countryMap).filter((entry) => !entry[1].deprecated);
    return countries.map(
      (entry) => ({ value: entry[0], text: entry[1].name }),
    ).sort((a, b) => {
      if (a.value === 'GBR') {
        return -1;
      } if (b.value === 'GBR') {
        return 1;
      }
      return 0;
    });
  },
  ebulkCountryItems: (state) => {
    const countries = Object.entries(state.countryMap).filter((entry) => !entry[1].ebulk_missing);
    return countries.map((entry) => ({ value: entry[0], text: entry[1].name }));
  },
  nationalityItems: (state) => {
    const countries = Object.entries(state.countryMap).filter((entry) => !entry[1].deprecated);
    return countries.map(
      (entry) => ({ value: entry[0], text: `${entry[1].nationality} (${entry[1].name})` }),
    ).sort((a, b) => {
      if (a.value === 'GBR') {
        return -1;
      } if (b.value === 'GBR') {
        return 1;
      }
      return 0;
    });
  },
};

const mutations = {
  setCountryMap: (state, payload) => {
    state.countryMap = payload;
  },
};

const actions = {};

export default {
  namespaced: true,
  state: moduleState,
  getters: moduleGetters,
  mutations,
  actions,
};
