export default [
  {
    path: '/ops/imports',
    component: () => import(
      /* webpackChunkName: "ops-imports" */
      '@/pages/product/ops/Imports.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "ops-imports" */
          '@/components/product/ops/imports/CollectionList.vue'
        ),
        name: 'collection-list',
        meta: {
          permissions: ['umbraco_imports.view_umbracoimportcollection'],
        },
      },
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "ops-imports" */
          '@/components/product/ops/imports/CollectionDetail.vue'
        ),
        props: true,
        name: 'collection-detail',
        meta: {
          permissions: ['umbraco_imports.view_umbracoimportcollection'],
        },
      },
    ],
  },
];
