export default [
  {
    path: '/subscriptions/',
    component: () => import(
      /* webpackChunkName: "subscriptions" */
      '@/pages/product/agents/Subscriptions.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "subscriptions" */
          '@/components/product/subscriptions/List.vue'
        ),
        name: 'subscription-list',
        meta: {
          permissions: ['processes.view_subscription'],
          entitlements: ['cycles'],
        },
      },
    ],
  },
];
