import agentGenericActivity from '@/components/product/case_activities/generic/AgentDetail.vue';
import agentAddressHistory from '@/components/product/case_activities/address_history/AgentDetail.vue';
import agentBasicDetails from '@/components/product/case_activities/basic_details/AgentDetail.vue';
import agentDocumentCollection from '@/components/product/case_activities/document_collection/AgentDetail.vue';
import agentDbsBasic from '@/components/product/case_activities/dbs_basic/AgentDetail.vue';
import agentCantiumBasic from '@/components/product/case_activities/cantium/AgentDetailBasic.vue';
import agentCantiumEbulk from '@/components/product/case_activities/cantium/AgentDetailEbulk.vue';
import agentEbulk from '@/components/product/case_activities/ebulk/AgentDetail.vue';
import agentDbsUpdate from '@/components/product/case_activities/dbs_update/AgentDetail.vue';
import agentKnowledgeTest from '@/components/product/case_activities/knowledge_test/AgentDetail.vue';
import agentTrust2Ride from '@/components/product/case_activities/trust_2_ride/AgentDetail.vue';
import agentLicenceCheck from '@/components/product/case_activities/licence_check/AgentDetail.vue';
import agentOnlineIdv from '@/components/product/case_activities/online_idv/AgentDetail.vue';
import agentDriverData from '@/components/product/case_activities/driver_data/AgentDetail.vue';
import agentAdultFirst from '@/components/product/case_activities/adult_first/AgentDetail.vue';
import agentRtw from '@/components/product/case_activities/rtw/AgentDetail.vue';

import opsKnowledgeTest from '@/components/product/case_activities/knowledge_test/OpsDetail.vue';
import opsLicenceCheck from '@/components/product/case_activities/licence_check/OpsDetail.vue';
import opsCantiumEnhanced from '@/components/product/case_activities/cantium/OpsDetail.vue';
import opsEbulkEnhanced from '@/components/product/case_activities/ebulk/OpsDetail.vue';
import opsDbsBasicEnhanced from '@/components/product/case_activities/dbs_basic/OpsDetail.vue';
import opsAdultFirst from '@/components/product/case_activities/adult_first/OpsDetail.vue';

import subjectAddressHistory from '@/components/subject/case_activities/address_history/SubjectDetail.vue';
import subjectBasicDetails from '@/components/subject/case_activities/basic_details/SubjectDetail.vue';
import subjectDocumentCollection from '@/components/subject/case_activities/document_collection/SubjectDetail.vue';
import subjectDbsBasic from '@/components/subject/case_activities/dbs_basic/SubjectDetail.vue';
import subjectEbulk from '@/components/subject/case_activities/ebulk/SubjectDetail.vue';
import subjectOnlineIdv from '@/components/subject/case_activities/online_idv/SubjectDetail.vue';
import subjectDriverData from '@/components/subject/case_activities/driver_data/SubjectDetail.vue';
import subjectAdultFirst from '@/components/product/case_activities/adult_first/SubjectDetail.vue';
import subjectRtw from '@/components/subject/case_activities/rtw/SubjectDetail.vue';

export const opsComponent = (activityType) => {
  const componentMap = {
    'knowledge-test-system': opsKnowledgeTest,
    'licence-check-davis': opsLicenceCheck,
    'dbs-enhanced-cantium': opsCantiumEnhanced,
    'dbs-enhanced-chexx': opsEbulkEnhanced,
    'dbs-standard-chexx': opsEbulkEnhanced,
    'dbs-basic-chexx': opsDbsBasicEnhanced,
    'dbs-adult-first-dbs': opsAdultFirst,
  };
  return componentMap[activityType.key];
};

export const agentComponent = (activityType) => {
  const componentMap = {
    'address-history-system': agentAddressHistory,
    'basic-details-system': agentBasicDetails,
    'document-collection-system': agentDocumentCollection,
    'rtw-system': agentRtw,
    'dbs-basic-cantium': agentCantiumBasic,
    'dbs-standard-cantium': agentCantiumEbulk,
    'dbs-enhanced-cantium': agentCantiumEbulk,
    'dbs-basic-chexx': agentDbsBasic,
    'dbs-enhanced-chexx': agentEbulk,
    'dbs-standard-chexx': agentEbulk,
    'dbs-update-dbs': agentDbsUpdate,
    'dbs-update-chexx': agentDbsUpdate,
    'knowledge-test-system': agentKnowledgeTest,
    'trust-2-ride-system': agentTrust2Ride,
    'licence-check-davis': agentLicenceCheck,
    'online-idv-yoti': agentOnlineIdv,
    'driver-data-chexx': agentDriverData,
    'dbs-adult-first-dbs': agentAdultFirst,
  };
  return activityType.key in componentMap ? componentMap[activityType.key] : agentGenericActivity;
};

export const subjectActivityDetailComponent = (activityType) => {
  const componentMap = {
    'address-history-system': subjectAddressHistory,
    'basic-details-system': subjectBasicDetails,
    'document-collection-system': subjectDocumentCollection,
    'rtw-system': subjectRtw,
    'dbs-basic-chexx': subjectDbsBasic,
    'dbs-enhanced-chexx': subjectEbulk,
    'dbs-standard-chexx': subjectEbulk,
    'online-idv-yoti': subjectOnlineIdv,
    'driver-data-chexx': subjectDriverData,
    'dbs-adult-first-dbs': subjectAdultFirst,
  };
  return componentMap[activityType.key];
};

export const activityTypeIcon = (code) => {
  const iconMap = {
    'dbs-enhanced': 'mdi-fingerprint',
    'dbs-standard': 'mdi-fingerprint',
    'dbs-basic': 'mdi-fingerprint',
    'dbs-update': 'mdi-file-refresh-outline',
    'licence-check': 'mdi-id-card',
    'trust-2-ride': 'mdi-taxi',
    'knowledge-test': 'mdi-brain',
    'basic-details': 'mdi-account-details',
    'address-history': 'mdi-map-marker',
    'document-collection': 'mdi-file-document-multiple-outline',
    'online-idv': 'mdi-check-decagram-outline',
    'driver-data': 'mdi-card-account-details-outline',
    'dbs-adult-first': 'mdi-run-fast',
    rtw: 'mdi-briefcase-variant',
  };
  return code in iconMap ? iconMap[code] : 'mdi-magnify-scan';
};

export const caseStateIcon = (state) => ({
  pending: 'mdi-progress-alert',
  'awaiting-subject': 'mdi-account-outline',
  'in-progress': 'mdi-progress-clock',
  'pending-case-decision': 'mdi-progress-question',
  complete: 'mdi-progress-check',
  cancelled: 'mdi-cancel',
}[state]);

export const activityStateIcon = (state) => ({
  'ca-pending': 'mdi-progress-alert',
  'ca-awaiting-subject': 'mdi-account-outline',
  'ca-awaiting-agent': 'mdi-face-agent',
  'ca-awaiting-ops': 'mdi-face-agent',
  'ca-in-progress': 'mdi-progress-clock',
  'ca-pending-activity-decision': 'mdi-progress-question',
  'ca-complete': 'mdi-progress-check',
  'ca-cancelled': 'mdi-cancel',
}[state]);

export const decisionIcon = (name) => {
  const iconMap = {
    Pass: 'mdi-thumb-up-outline',
    Fail: 'mdi-thumb-down-outline',
  };
  return name in iconMap ? iconMap[name] : 'mdi-magnify-scan';
};
