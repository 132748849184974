<template>
  <v-row>
    <v-col>
      <span
        class="overline"
      >
        Stage:
      </span>
      <p>{{ stage }}</p>
      <div v-if="showDbsCaseLink">
        <span
          class="overline"
        >
          DBS Case:
        </span>
        <p>
          <router-link
            :to="{ name: 'case-detail', params: { id: dbsCaseId } }"
          >
            {{ dbsCaseId }}
          </router-link>
        </p>
      </div>
    </v-col>

    <v-col>
      <p v-if="isPending" class="mt-7">
        This Adult First Request is waiting to be sent to the DBS.
      </p>
      <div v-if="isRequested" class="mt-7">
        <p>This Adult First Request has been sent to the DBS.</p>
        <p><strong>Note:</strong> it can take up to 72 hours for a result to be received.</p>
      </div>
      <div v-if="isResult">
        <span
          class="overline"
        >
          Result:
        </span>
        <p>{{ result }}</p>

        <p v-if="result === 'Certificate Already Issued'">
          <em>
            It is not possible to provide an Adult First result as the
            certificate has already been issued.
          </em>
        </p>
      </div>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'DBSAdultFirstActivity',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    stage() {
      return this.caseActivityDetails.result_data.stage;
    },
    dbsCaseId() {
      return this.caseActivityDetails.result_data.dbs_case_id;
    },
    result() {
      return this.caseActivityDetails.result_data.result;
    },
    isPending() {
      return this.stage === 'Pending';
    },
    isRequested() {
      return this.stage === 'Requested';
    },
    isResult() {
      return this.stage === 'Result Received';
    },
    showDbsCaseLink() {
      return this.dbsCaseId && this.dbsCaseId !== this.caseActivityDetails.case.id;
    },
  },
};
</script>
