import Vue from 'vue';
import getProduct from '@/util/product-mappings';

const data = {
  bootstrapped: undefined,
  darkTheme: false,
  drawerOpen: false,
  breadcrumbs: [],
  auditLogParams: {},
  product: {
    logo: '',
    name: 'Unkown Product Name',
    themes: {},
  },
  verbs: {},
  contentTypes: {},
  activeFlags: {},
  entitlements: {},
  maintenanceWindow: {},
};

const getters = {
  environment: () => process.env.NODE_ENV,
  hasEntitlement: (state) => (entitlementCode) => state.entitlements.some(
    (ent) => ent.entitlement === entitlementCode,
  ),
  isDown: (state) => {
    const now = new Date();
    return (
      state.maintenanceWindow.start
      && state.maintenanceWindow.start < now
      && state.maintenanceWindow.end > now
    );
  },
  showMaintenanceWarning: (state) => {
    const now = new Date();
    return (
      state.maintenanceWindow.start
      && state.maintenanceWindow.start > now
      && state.maintenanceWindow.warningStart < now
    );
  },
};

const mutations = {
  toggleDarkTheme: (state) => {
    state.darkTheme = !state.darkTheme;
  },
  setBootstrapped: (state, value) => {
    state.bootstrapped = value;
  },
  setDrawer: (state, drawState) => {
    state.drawerOpen = drawState;
  },
  setBreadCrumbs: (state, payload) => {
    if (payload.length > 0) {
      state.breadcrumbs = payload;
      document.title = `${payload[payload.length - 1].text} | ${state.product.name}`;
    } else {
      document.title = state.product.name;
    }
  },
  addBreadCrumbs: (state, payload) => {
    state.breadcrumbs.push(payload);
  },
  setAuditLogParams: (state, payload) => {
    state.auditLogParams = payload;
  },
  clearBreadCrumbsAndAuditLogParams: (state) => {
    state.auditLogParams = [];
    state.auditLogParams = {};
  },
  setProduct: (state, payload) => {
    state.product = getProduct(payload.hostname);
    document.title = state.product.name;
    if ('favicon' in state.product) {
      const favicon = document.getElementById('favicon');
      favicon.href = state.product.favicon;
    }
  },
  setVerbs: (state, payload) => {
    state.verbs = payload;
  },
  setEntitlements: (state, payload) => {
    state.entitlements = payload;
  },
  setContentTypes: (state, payload) => {
    payload.forEach((ct) => {
      state.contentTypes[ct.id] = ct.name;
    });
  },
  setActiveFlags: (state, payload) => {
    state.activeFlags = payload.activeFlags;
  },
  setMaintenanceWindow: (state, payload) => {
    state.maintenanceWindow = {
      start: new Date(payload.start),
      end: new Date(payload.end),
      warningStart: new Date(payload.warning_start),
    };
  },
  clearMaintenanceWindow: (state) => {
    state.maintenanceWindow = {};
  },
};

const actions = {
  async init({ commit, dispatch, state }, payload) {
    commit('setProduct', { hostname: window.location.hostname });
    if (state.product.themes) {
      Object.assign(payload.vuetify.theme.themes.light, state.product.themes.light);
      Object.assign(payload.vuetify.theme.themes.dark, state.product.themes.dark);
    }
    await dispatch('auth/checkSession', { initial: true }, { root: true });
  },
  async getContentTypes({ commit, rootState }) {
    if (rootState.auth.userType === 'ProductUser') {
      const resp = await Vue.service.request('get', '/api/content-types/', { params: { page_size: 100 } });
      if (resp.status === 200) {
        commit('setContentTypes', resp.data.results);
      }
    } else if (rootState.auth.userType === 'SubjectUser') {
      const resp = await Vue.service.subject.contentTypes.list();
      if (resp.status === 200) {
        commit('setContentTypes', resp.data);
      }
    }
  },
  async getVerbs({ commit }) {
    const resp = await Vue.service.request('get', '/api/verbs/');
    if (resp.status === 200) {
      commit('setVerbs', resp.data);
    }
  },
  async getEntitlements({ commit }) {
    const resp = await Vue.service.request('get', '/api/entitlements/');
    if (resp.status === 200) {
      commit('setEntitlements', resp.data.results);
    }
  },
  async getActiveFlagcount({ commit }) {
    const resp = await Vue.service.product.flags.activeCount();
    if (resp.status === 200) {
      commit('setActiveFlags', { activeFlags: resp.data.active });
    }
  },
  bootstrap({ commit, dispatch, rootState }) {
    const promises = [
      dispatch('users/getProfile', {}, { root: true }),
      dispatch('getContentTypes'),
    ];
    if (rootState.auth.userType === 'ProductUser') {
      const productPromises = [
        dispatch('getVerbs'),
        dispatch('getActiveFlagcount'),
        dispatch('getEntitlements'),
      ];
      promises.push(...productPromises);
    }
    return Promise.all(promises).then(() => {
      commit('setBootstrapped', true);
    }).catch(() => {
      commit('notifications/notify', { text: 'Error in bootstrap promises', type: 'error' }, { root: true });
    });
  },
};

export default {
  namespaced: true,
  state: data,
  getters,
  mutations,
  actions,
};
