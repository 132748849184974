import { request } from '@/service/plugin';

import accounts from './accounts';
import payments from './payments';

export default {
  ...accounts,
  ...payments,
  registrationPortals: {
    retrieve: (segmentId, portalPath, options) => {
      const opt = options || {};
      opt.errorMessage = opt.errorMessage || 'Error retrieving portal';
      return request('get', `/api/public/registration-portals/${segmentId}/${portalPath}/`, opt);
    },
  },
  profile: {
    retrieve: () => request('get', '/api/user/'),
    update: (data) => request('put', '/api/user/', { data }),
    generateTotpToken: (data) => request('patch', '/api/user/totp-generate/', { data }),
    confirmTotpToken: (data) => request('patch', '/api/user/totp-validate/', { data }),
    disableTotpToken: (data) => request('patch', '/api/user/totp-disable/', { data }),
  },
};
