export default [
  {
    path: '/ops/trust-2-ride',
    component: () => import(
      /* webpackChunkName: "ops-trust2Ride" */
      '@/pages/product/ops/Trust2Ride.vue'
    ),
    name: 'trust-2-ride',
    meta: {
      permissions: ['cases.view_all_activities'],
    },
  },
];
