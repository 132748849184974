export default [
  {
    path: '/outstanding-activities',
    component: () => import(
      /* webpackChunkName: "activities" */
      '@/pages/product/agents/OutstandingActivities.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "activities" */
          '@/components/product/outstanding_activities/List.vue'
        ),
        name: 'outstanding-activity-list',
        meta: {
          permissions: ['cases.view_caseactivity'],
        },
      },
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "activities" */
          '@/components/product/outstanding_activities/Detail.vue'
        ),
        props: true,
        name: 'outstanding-activity-detail',
        meta: {
          permissions: ['cases.view_caseactivity'],
        },
      },
    ],
  },
  {
    path: '/case-activities',
    component: () => import(
      /* webpackChunkName: "activities" */
      '@/pages/product/agents/OutstandingActivities.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "activities" */
          '@/components/product/case_activities/List.vue'
        ),
        name: 'case-activity-list',
        meta: {
          permissions: ['cases.view_caseactivity'],
        },
      },
    ],
  },
];
