export default [
  {
    path: 'consents',
    component: () => import(
      /* webpackChunkName: "subject-consents" */
      '@/pages/subject/Consents.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "subject-consents" */
          '@/components/subject/consents/List.vue'
        ),
        name: 's:consent-list',
      },
    ],
  },
];
