export default [
  {
    path: '/processes',
    component: () => import(
      /* webpackChunkName: "processes" */
      '@/pages/product/config/ProcessManagement.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "processes" */
          '@/components/product/processes/List.vue'
        ),
        name: 'process-list',
        meta: {
          permissions: ['processes.view_process'],
        },
      },
      {
        path: 'add',
        component: () => import(
          /* webpackChunkName: "processes" */
          '@/components/product/processes/Add.vue'
        ),
        props: true,
        name: 'process-add',
        meta: {
          permissions: ['processes.add_process'],
        },
      },
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "processes" */
          '@/components/product/processes/Detail.vue'
        ),
        props: true,
        children: [
          {
            path: '',
            component: () => import(
              /* webpackChunkName: "processes" */
              '@/components/product/processes/detail_tabs/Overview.vue'
            ),
            name: 'process-overview',
            meta: {
              permissions: ['processes.view_process'],
            },
          },
          {
            path: 'details/',
            component: () => import(
              /* webpackChunkName: "processes" */
              '@/components/product/processes/detail_tabs/Details.vue'
            ),
            name: 'process-details',
            meta: {
              permissions: ['processes.change_process'],
            },
          },
          {
            path: 'configuration/',
            component: () => import(
              /* webpackChunkName: "processes" */
              '@/components/product/processes/detail_tabs/Configuration.vue'
            ),
            name: 'process-configuration',
            meta: {
              permissions: ['processes.change_processconfig'],
            },
          },
          {
            path: 'requirements/',
            component: () => import(
              /* webpackChunkName: "processes" */
              '@/components/product/processes/detail_tabs/Requirements.vue'
            ),
            name: 'process-requirements',
            meta: {
              permissions: ['processes.change_process'],
            },
          },
          {
            path: 'registration-portals/',
            component: () => import(
              /* webpackChunkName: "processes" */
              '@/components/product/processes/detail_tabs/registration_portals/List.vue'
            ),
            name: 'process-reg-portal-list',
            meta: {
              permissions: ['processes.view_registrationportal'],
              entitlements: ['regportals'],
            },
          },
          {
            path: 'registration-portals/add/',
            component: () => import(
              /* webpackChunkName: "processes" */
              '@/components/product/processes/detail_tabs/registration_portals/Form.vue'
            ),
            name: 'process-reg-portal-add',
            meta: {
              permissions: ['processes.add_registrationportal'],
              entitlements: ['regportals'],
            },
          },
          {
            path: 'registration-portals/:regPortalId/edit/',
            component: () => import(
              /* webpackChunkName: "processes" */
              '@/components/product/processes/detail_tabs/registration_portals/Form.vue'
            ),
            name: 'process-reg-portal-edit',
            props: true,
            meta: {
              permissions: ['processes.change_registrationportal'],
              entitlements: ['regportals'],
            },
          },
          {
            path: 'cycles/',
            component: () => import(
              /* webpackChunkName: "processes" */
              '@/components/product/processes/detail_tabs/cycles/List.vue'
            ),
            name: 'process-cycle-list',
            meta: {
              permissions: ['processes.view_cycle'],
              entitlements: ['cycles'],
            },
          },
          {
            path: 'cycles/add/',
            component: () => import(
              /* webpackChunkName: "processes" */
              '@/components/product/processes/detail_tabs/cycles/Add.vue'
            ),
            name: 'process-cycle-add',
            meta: {
              permissions: ['processes.add_cycle'],
              entitlements: ['cycles'],
            },
          },
          {
            path: 'cycles/:cycleId/edit/',
            component: () => import(
              /* webpackChunkName: "processes" */
              '@/components/product/processes/detail_tabs/cycles/Edit.vue'
            ),
            name: 'process-cycle-edit',
            props: true,
            meta: {
              permissions: ['processes.change_cycle'],
              entitlements: ['cycles'],
            },
          },
        ],
      },
    ],
  },
];
