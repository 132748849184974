<template>
  <div>
    <v-expansion-panels accordion>
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="subtitle-1">
            <v-icon
              left
            >
              mdi-cogs
            </v-icon>
            Activity Configuration
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="requirement-config">
          <slot />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>

export default {
  name: 'CaseActivityConfig',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
};
</script>
