export default [
  {
    path: 'case-portal/:id',
    component: () => import(
      /* webpackChunkName: "case-portal" */
      '@/pages/subject/cases/Portal.vue'
    ),
    props: true,
    name: 's:case-portal',
    children: [
      {
        path: ':section',
        component: () => import(
          /* webpackChunkName: "case-portal" */
          '@/pages/subject/cases/PortalSection.vue'
        ),
        props: true,
        name: 's:case-portal-section',
      },
    ],
  },
  {
    path: 'cases',
    component: () => import(
      /* webpackChunkName: "subject-cases" */
      '@/pages/subject/cases/Cases.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "subject-cases" */
          '@/components/subject/cases/List.vue'
        ),
        name: 's:case-list',
      },
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "subject-cases" */
          '@/components/subject/cases/Detail.vue'
        ),
        props: true,
        name: 's:case-detail',
      },
    ],
  },
];
