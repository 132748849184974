<template>
  <v-container>
    <v-card class="my-4">
      <v-card-title>Basic Details</v-card-title>
      <v-card-text>
        <edit-case-basic-detail :case-id="caseId" :ops="ops" />
      </v-card-text>
    </v-card>
    <v-card class="my-4">
      <v-card-title>Birth Details</v-card-title>
      <v-card-text>
        <edit-case-birth-detail
          :case-id="caseId"
          :ops="ops"
          :is-ebulk="isEbulk"
        />
      </v-card-text>
    </v-card>
    <v-card class="my-4">
      <v-card-title>Other Names</v-card-title>
      <v-card-text>
        <edit-case-other-names :case-id="caseId" :ops="ops" />
      </v-card-text>
    </v-card>
    <v-card class="my-4">
      <v-card-title>Address History</v-card-title>
      <v-card-text>
        <list-case-addresses
          :case-id="caseId"
          :ops="ops"
          :is-ebulk="isEbulk"
        />
      </v-card-text>
    </v-card>
    <v-card class="my-4">
      <v-card-title>Passport</v-card-title>
      <v-card-text>
        <EditCasePassport
          :case-id="caseId"
          :ops="ops"
          :is-ebulk="isEbulk"
        />
      </v-card-text>
    </v-card>
    <v-card class="my-4">
      <v-card-title>Driving Licence</v-card-title>
      <v-card-text>
        <EditCaseDrivingLicence
          :case-id="caseId"
          :ops="ops"
          :is-ebulk="isEbulk"
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';

import EditCaseBasicDetail from './EditCaseBasicDetail.vue';
import EditCaseBirthDetail from './EditCaseBirthDetail.vue';
import EditCaseOtherNames from './EditCaseOtherNames.vue';
import EditCasePassport from './EditCasePassport.vue';
import EditCaseDrivingLicence from './EditCaseDrivingLicence.vue';
import ListCaseAddresses from './ListCaseAddresses.vue';

export default {
  name: 'EditCaseRelatedData',
  components: {
    EditCaseBasicDetail,
    EditCaseBirthDetail,
    ListCaseAddresses,
    EditCaseOtherNames,
    EditCasePassport,
    EditCaseDrivingLicence,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    ops: {
      type: Boolean,
      default: false,
    },
    isEbulk: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('utils', ['countryItems']),
  },
  created() {
    this.getCountryMap();
  },
  methods: {
    async getCountryMap() {
      if (this.countryItems.length) return;
      const resp = await this.$service.product.countryMap();
      if (resp.status === 200) {
        this.$store.commit('utils/setCountryMap', resp.data);
      }
    },
  },
};
</script>
