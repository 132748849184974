<template>
  <v-row>
    <v-col>
      <template v-if="showForm">
        <v-alert
          v-for="(error, index) in value.processing_data.errors"
          :key="index"
          type="error"
        >
          {{ error }}
        </v-alert>
        <v-form
          v-model="formValid"
          @submit.prevent="formSubmit"
        >
          <v-text-field
            v-model="form['*Forename']"
            :rules="[(v) => !!v || 'Required']"
            label="Forename"
          />
          <v-text-field
            v-model="form['*Surname']"
            :rules="[(v) => !!v || 'Required']"
            label="Surname"
          />
          <v-text-field
            v-model="form['*Email']"
            :rules="[(v) => !!v || 'Required', (v) => /.+@.+\..+/.test(v) || 'Invalid email']"
            label="Email"
          />
          <v-text-field
            v-model="form['*Manager Forename']"
            :rules="[(v) => !!v || 'Required']"
            label="Manager forename"
          />
          <v-text-field
            v-model="form['*Manager Surname']"
            :rules="[(v) => !!v || 'Required']"
            label="Manager surname"
          />
          <v-text-field
            v-model="form['*Manager Email']"
            :rules="[(v) => !!v || 'Required', (v) => /.+@.+\..+/.test(v) || 'Invalid email']"
            label="Manager email"
          />
          <v-text-field
            v-model="form['*Position']"
            :rules="[(v) => !!v || 'Required']"
            label="Position"
          />
          <v-text-field
            v-model="form['*ID Verifier Username']"
            :rules="[(v) => !!v || 'Required']"
            label="ID verifier username"
          />
          <v-text-field
            v-model="form['*Email Group']"
            :rules="[(v) => !!v || 'Required']"
            label="Email group"
          />
          <v-btn
            small
            class="mt-2 mb-4"
            type="submit"
            :loading="loading"
            :disabled="!formValid"
          >
            <v-icon
              left
              small
            >
              mdi-content-save
            </v-icon>
            Update
          </v-btn>
        </v-form>
      </template>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'DBSEnhancedOpsDetail',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localData: this.value,
      form: { ...this.value.processing_data.cantium_config },
      showForm: this.value.state === 'ca-error-initiating',
      formValid: false,
      loading: false,
    };
  },
  methods: {
    async formSubmit() {
      this.loading = true;
      await this.$service.product.ops.caseActivities.partialUpdate(
        this.value.id,
        {
          processing_data: {
            ...this.value.processing_data,
            cantium_config: { ...this.value.processing_data.cantium_config, ...this.form },
          },
        },
      );
      this.loading = false;
    },
  },
};
</script>
