import caseRoutes from './cases';
import consentRoutes from './consents';

export default [
  {
    path: 'dashboard',
    component: () => import(
      /* webpackChunkName: "subject-portal" */ '@/pages/subject/Dashboard.vue'
    ),
    name: 's:dashboard',
    meta: {
      hideBreadcrumbs: true,
    },
  },
  {
    path: 'profile',
    component: () => import(/* webpackChunkName: "subject-portal" */ '@/pages/subject/Profile.vue'),
    name: 's:profile',
  },
  {
    path: 'complete-profile',
    component: () => import(
      /* webpackChunkName: "subject-portal" */
      '@/pages/subject/CompleteProfile.vue'
    ),
    name: 's:complete-profile',
  },
  {
    path: 'self-initiate/:segmentId/:portalPath',
    component: () => import(
      /* webpackChunkName: "public" */
      '@/components/public/registration_portals/Landing.vue'
    ),
    props: true,
    name: 's:public-registration-portal',
  },
  ...caseRoutes,
  ...consentRoutes,
];
