import { viewsetActions } from '@/service/plugin';

const collectionExtraActions = {
  parse: { path: 'parse', method: 'post', detail: true },
  poll: { path: 'poll', method: 'get', detail: true },
};
const opsUmbracoCollections = viewsetActions('collections', { basePath: '/api/ops/imports/umbraco/', extraActions: collectionExtraActions });

const importExtraActions = {
  createCases: { path: 'create_cases', method: 'post', detail: true },
  poll: { path: 'poll', method: 'get', detail: true },
  setProcessForAll: { path: 'set_process_for_all', method: 'post', detail: true },
};
const opsUmbracoImports = viewsetActions('imports', { basePath: '/api/ops/imports/umbraco/', extraActions: importExtraActions });
const opsUmbracoRecords = viewsetActions('records', { basePath: '/api/ops/imports/umbraco/' });

export {
  opsUmbracoCollections,
  opsUmbracoImports,
  opsUmbracoRecords,
};
