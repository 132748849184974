export default [
  {
    path: '/ops/customers',
    component: () => import(
      /* webpackChunkName: "ops-customers" */
      '@/pages/product/ops/Customers.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "ops-customers" */
          '@/components/product/ops/customers/List.vue'
        ),
        name: 'customer-list',
        meta: {
          permissions: ['customers.view_all_customers'],
        },
      },
      {
        path: 'add',
        component: () => import(
          /* webpackChunkName: "ops-customers" */
          '@/components/product/ops/customers/Add.vue'
        ),
        props: true,
        name: 'customer-add',
        meta: {
          permissions: ['customers.add_customer'],
        },
      },
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "ops-customers" */
          '@/components/product/ops/customers/Detail.vue'
        ),
        props: true,
        name: 'customer-detail',
        children: [
          {
            path: 'overview',
            component: () => import(
              /* webpackChunkName: "ops-customers" */
              '@/components/product/ops/customers/Overview.vue'
            ),
            props: true,
            name: 'customer-overview',
            meta: {
              permissions: ['customers.view_all_customers'],
            },
          },
          {
            path: 'edit',
            component: () => import(
              /* webpackChunkName: "ops-customers" */
              '@/components/product/ops/customers/Edit.vue'
            ),
            props: true,
            name: 'customer-edit',
            meta: {
              permissions: ['customers.view_all_customers', 'customers.change_customer'],
            },
          },
          {
            path: 'entitlements',
            component: () => import(
              /* webpackChunkName: "ops-customers" */
              '@/components/product/ops/customers/Entitlements.vue'
            ),
            props: true,
            name: 'customer-entitlements',
            meta: {
              permissions: ['customers.view_all_customers', 'customers.change_customer'],
            },
          },
          {
            path: 'segments',
            component: () => import(
              /* webpackChunkName: "ops-customers" */
              '@/components/product/ops/customers/segments/List.vue'
            ),
            props: true,
            name: 'customer-segment-list',
            meta: {
              permissions: ['customers.view_all_customers', 'customers.view_segment'],
            },
          },
          {
            path: 'users',
            component: () => import(
              /* webpackChunkName: "ops-customers" */
              '@/components/product/ops/customers/Users.vue'
            ),
            props: true,
            children: [
              {
                path: '',
                component: () => import(
                  /* webpackChunkName: "ops-customers" */
                  '@/components/product/ops/customers/users/List.vue'
                ),
                props: true,
                name: 'customer-user-list',
                meta: {
                  permissions: ['users.view_all_product_users'],
                },
              },
            ],
          },
          {
            path: 'pricebooks',
            props: true,
            component: () => import(
              /* webpackChunkName: "ops-customers" */
              '@/components/product/ops/customers/Pricebooks.vue'
            ),
            children: [
              {
                path: '',
                component: () => import(
                  /* webpackChunkName: "ops-customers" */
                  '@/components/product/ops/customers/price_books/List.vue'
                ),
                props: true,
                name: 'customer-pricebook-list',
                meta: {
                  permissions: ['pricebooks.view_pricebook'],
                },
              },
              {
                path: 'add',
                component: () => import(
                  /* webpackChunkName: "ops-customers" */
                  '@/components/product/ops/customers/price_books/Add.vue'
                ),
                props: true,
                name: 'customer-pricebook-add',
                meta: {
                  permissions: ['pricebooks.add_pricebook'],
                },
              },
              {
                path: ':pbId/edit',
                component: () => import(
                  /* webpackChunkName: "ops-customers" */
                  '@/components/product/ops/customers/price_books/Edit.vue'
                ),
                props: true,
                name: 'customer-pricebook-edit',
                meta: {
                  permissions: ['pricebooks.change_pricebook'],
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
