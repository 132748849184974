<template>
  <div>
    <OpsTestDetail
      v-for="item in value.processingData.cm_results"
      :key="item.link_result_id"
      :result="item"
    />

    <v-form
      id="form"
      ref="form"
    >
      <h4>Result to report to customer</h4>
      <v-radio-group
        v-model="pass"
        :mandatory="false"
        class="ml-4"
      >
        <v-radio
          label="Pass"
          :value="true"
        />
        <v-radio
          label="Fail"
          :value="false"
        />
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import OpsTestDetail from './OpsTestDetail.vue';

export default {
  name: 'KnowledgeTest',
  components: {
    OpsTestDetail,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    newResults: {},
    pass: undefined,
  }),
  computed: {
    keyResult() {
      if (this.pass === true) {
        return [['Status', 'Pass']];
      } if (this.pass === false) {
        return [['Status', 'Fail']];
      }
      return [];
    },
  },
  watch: {
    pass: {
      deep: false,
      handler() {
        this.newResults.resultData = {
          pass: this.pass,
          ...this.value.processingData,
        };
        this.newResults.keyResult = this.keyResult;
        this.$emit('input', this.newResults);
      },
    },
  },
  mounted() {
    this.newResults = { ...this.value };
  },
  methods: {
  },
};
</script>
