<template>
  <v-row>
    <v-col
      cols="12"
      lg="6"
    >
      <template v-if="showForm">
        <p v-if="existingDriver">
          Please confirm the Davis email address if the existing driver matches the subject,
          otherwise provide a new email address for the Davis check.
        </p>
        <p v-if="onAnotherCustomer">
          There seem to be a driver with this email on another Davis company, you can enter a new
          email below to start the check.
        </p>
        <v-form
          v-model="formValid"
          @submit.prevent="formSubmit"
        >
          <v-text-field
            v-model="confirmEmail"
            :rules="[(v) => !!v || 'Required', (v) => /.+@.+\..+/.test(v) || 'Invalid email']"
            label="Davis driver email"
          />
          <v-btn
            small
            type="submit"
            :loading="loading"
            :disabled="!formValid"
          >
            <v-icon
              left
              small
            >
              mdi-content-save
            </v-icon>
            {{ buttonText }}
          </v-btn>
        </v-form>
      </template>
      <template v-else-if="caseActivity.state === 'ca-error-initiating'">
        Click Start to initiate the Davis check with email
        <a :href="`mailto:${ email }`">{{ email }}</a>.
      </template>
    </v-col>
    <v-col
      v-if="existingDriver"
      cols="12"
      lg="4"
      offset-lg="1"
    >
      <h2 class="mb-2">
        Existing Davis Driver
      </h2>
      <span class="body-2 text--secondary">Name: </span>
      <span>{{ existingDriver.Firstname }} {{ existingDriver.Lastname }}</span>
      <br>
      <span class="body-2 text--secondary">Email: </span>
      <span>{{ existingDriver.EmailAddress.toLowerCase() }}</span>
      <br>
      <span class="body-2 text--secondary">Date of Birth: </span>
      <span>{{ existingDriver.DateOfBirth|aspdate|longdate }}</span>
      <br>
      <span class="body-2 text--secondary">Davis Licence Number: </span>
      <span>{{ existingDriver.DriverNumber }}</span>
      <span class="body-2 text--secondary">Product Licence Number: </span>
      <span>{{ caseActivity.processing_data.existing_number }}</span>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'LicenceCheckOpsDetail',
  props: {
    caseActivity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localData: this.caseActivity,
      existingDriver: this.caseActivity.processing_data.existing_driver,
      onAnotherCustomer: this.caseActivity.processing_data.on_another_customer,
      confirmEmail: '',
      confirmed: this.caseActivity.state !== 'ca-error-initiating',
      formValid: false,
      loading: false,
    };
  },
  computed: {
    showForm() {
      return (this.existingDriver || this.onAnotherCustomer) && !this.confirmed;
    },
    email() {
      const email = this.localData.processing_data.davis_email || this.localData.case.subject.email;
      return email.toLowerCase();
    },
    buttonText() {
      return this.confirmEmail === this.email ? 'Confirm' : 'Update';
    },
  },
  created() {
    if (this.caseActivity.processing_data.existing_driver) {
      const driverData = this.caseActivity.processing_data.existing_driver;
      this.confirmEmail = driverData.EmailAddress.toLowerCase();
    }
  },
  methods: {
    async formSubmit() {
      this.loading = true;
      const data = { ...this.caseActivity.processing_data, davis_email: this.confirmEmail };
      const resp = await this.$service.product.ops.caseActivities.partialUpdate(
        this.caseActivity.id,
        { processing_data: data },
      );
      if (resp.status === 200) {
        this.confirmed = true;
      }
      this.loading = false;
    },
  },
};
</script>
