export default [
  {
    path: '/definitions',
    component: () => import(
      /* webpackChunkName: "definition" */
      '@/pages/lms/product/config/DefinitionManagement.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "definition" */
          '@/components/lms/product/definitions/List.vue'
        ),
        name: 'definition-list',
        meta: {
          permissions: ['lms_definitions.view_definition'],
        },
      },
      {
        path: 'add',
        component: () => import(
          /* webpackChunkName: "definition" */
          '@/components/lms/product/definitions/Add.vue'
        ),
        name: 'definition-add',
        meta: {
          permissions: ['lms_definitions.add_definition'],
        },
      },
      {
        path: ':id/edit',
        component: () => import(
          /* webpackChunkName: "definition" */
          '@/components/lms/product/definitions/Edit.vue'
        ),
        name: 'definition-edit',
        props: true,
        meta: {
          permissions: ['lms_definitions.change_definition'],
        },
      },
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "definition" */
          '@/components/lms/product/definitions/Detail.vue'
        ),
        name: 'definition-detail',
        props: true,
        meta: {
          permissions: ['lms_definitions.view_definition'],
        },
      },
    ],
  },
];
