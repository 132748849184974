import { viewsetActions } from '@/service/plugin';

const extraActions = {
  activate: { path: 'activate', method: 'post', detail: true },
  deactivate: { path: 'deactivate', method: 'post', detail: true },
  resetPassword: { path: 'reset-password', method: 'post', detail: true },
  unlock: { path: 'unlock', method: 'post', detail: true },
  removeTotp: { path: 'remove-totp', method: 'post', detail: true },
};
const users = viewsetActions('users', { extraActions });
const roles = viewsetActions('roles');

export {
  users,
  roles,
};
