<template>
  <div>
    <v-expansion-panels accordion class="mt-3">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span class="subtitle-1">
            <v-icon
              left
            >
              mdi-notebook-outline
            </v-icon>
            Notes
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="requirement-config">
          <slot>
            <v-textarea
              v-model="notes"
              class="mb-2"
              rows="4"
              label="Notes"
              :rules="[v => !v || v.length < 400 || 'Max length 400']"
            />
            <v-btn
              small
              :disabled="notes.length > 400"
              :loading="loadingNotes"
              @click="updateNotes"
            >
              <v-icon
                left
                small
              >
                mdi-content-save
              </v-icon> Save Notes
            </v-btn>

            <v-dialog
              v-model="showNoteHistory"
              width="500"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  small
                  class="ml-2"

                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    left
                    small
                  >
                    mdi-history
                  </v-icon> Show Notes History
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  Note History
                </v-card-title>

                <v-card-text>
                  <div
                    v-for="note, index in notesHistory"
                    :key="index"
                  >
                    <strong>
                      {{ note.actor.first_name }} {{ note.actor.last_name }}
                      @ {{ note.timestamp|longdatetime }}
                    </strong><br />
                    <pre>{{ note.description }}</pre>
                    <v-divider class="mt-1 mb-2" />
                  </div>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    text
                    @click="showNoteHistory = false"
                  >
                    close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </slot>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>

export default {
  name: 'CaseActivityNotes',
  components: {
  },
  props: {
    ca: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data: () => ({
    notes: '',
    loadingNotes: false,
    notesHistory: [],
    showNoteHistory: false,
  }),
  created() {
    if (this.ca.notes) {
      this.notes = this.ca.notes;
    }
    this.getNotesHistory();
  },
  methods: {
    async updateNotes() {
      this.loadingNotes = true;
      await this.$service.product.caseActivities.notes(this.ca.id, { notes: this.notes });
      this.loadingNotes = false;
    },
    async getNotesHistory() {
      const resp = await this.$service.product.caseActivities.notes_history(this.ca.id);
      if (resp.status === 200) {
        this.notesHistory = resp.data;
      }
    },
  },
};
</script>
