import { ukPostcodeValidator } from '@/util/validators';

const dbsNameRules = [
  (v) => !!v || 'Required',
  (v) => !v || v.length <= 60 || 'Max 60 characters',
  (v) => /(^[a-zA-Z]+$)|(^[a-zA-Z][a-zA-Z '-]*[a-zA-Z]$)/.test(v) || 'Contains invalid characters',
];

const dbsSurnameRules = [
  (v) => !!v || 'Required',
  (v) => !v || v.length <= 50 || 'Max 50 characters',
  (v) => /(^[a-zA-Z]+$)|(^[a-zA-Z][a-zA-Z '-]*[a-zA-Z]$)/.test(v) || 'Contains invalid characters',
];

const dbsEmailRules = [
  (v) => !!v || 'Required',
  (v) => !v || v.length <= 100 || 'Max 100 characters',
  (v) => /(^[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+\.[a-zA-Z]{2,4}$)/.test(v) || 'Invalid email',
];

const dbsOptionalAddressLineRules = [
  (v) => !v || v.length <= 60 || 'Max 60 characters',
  (v) => (
    !v
    || /(^[a-zA-Z0-9()\-/'&]+$)|(^[a-zA-Z0-9()\-/'&][a-zA-Z 0-9()\-/'&]*[a-zA-Z0-9()\-/'&]$)/.test(v)
    || 'Contains invalid characters'
  ),
];

const dbsAddressLineRules = [
  (v) => !!v || 'Required',
  ...dbsOptionalAddressLineRules,
];

const dbsPlaceRules = [
  (v) => !!v || 'Required',
  (v) => !v || v.length <= 30 || 'Max 30 characters',
  (v) => (
    /(^[a-zA-Z0-9()\-/'&]+$)|(^[a-zA-Z0-9()\-/'&][a-zA-Z 0-9()\-/'&]*[a-zA-Z0-9()\-/'&]$)/.test(v)
    || 'Contains invalid characters'
  ),
];

const dbsDepartmentNameRules = [
  (v) => !!v || 'Required',
  (v) => !v || v.length <= 100 || 'Max 100 characters',
  (v) => /(^[a-zA-Z]+$)|(^[a-zA-Z][a-zA-Z '-]*[a-zA-Z]$)/.test(v) || 'Contains invalid characters',
];

const dbsOptionalPositionRules = [
  (v) => !v || v.length <= 60 || 'Max 60 characters',
  (v) => (
    /(^[a-zA-Z0-9()\-/'&]+$)|(^[a-zA-Z0-9()\-/'&][a-zA-Z 0-9()\-/'&]*[a-zA-Z0-9()\-/'&]$)/.test(v)
    || !v
    || 'Contains invalid characters'
  ),
];

const dbsPositionRules = [
  (v) => !!v || 'Required',
  ...dbsOptionalPositionRules,
];

const dbsUpdateOrganisationRules = [
  (v) => !!v || 'Required',
  (v) => !v || v.length <= 60 || 'Max 60 characters',
  (v) => (
    /(^[a-zA-Z0-9\-']+$)|(^[a-zA-Z0-9\-'][a-zA-Z 0-9\-']*[a-zA-Z0-9\-']$)/.test(v)
    || 'Contains invalid characters'
  ),
];

const dbsPostcodeRules = [
  ukPostcodeValidator,
];

const dbsOptionalPostcodeRules = [
  (v) => !v || v.length <= 30 || 'Max 30 characters',
  (v) => (
    !v
    || /(^[A-Z0-9()\-/']+$)|(^[A-Z0-9()\-/'][A-Z 0-9()\-/']*[A-Z0-9()\-/']$)/.test(v)
    || 'Invalid format'
  ),
];

const dbsOptionalEmailRules = [
  (v) => !v || /^[-0-9a-zA-Z.+_]+@[-0-9a-zA-Z.+_]+\.[a-zA-Z]{2,4}$/.test(v) || 'Invalid email',
];

const optionalNinRules = [
  (v) => !v || /^[A-CEGHJ-NOPR-TW-Z]{2}[0-9]{6}[ABCD\s]{1}$/.test(v) || 'Invalid number',
];

const ninRules = [
  (v) => !!v || 'Required',
  ...optionalNinRules,
];

const passportRules = [
  (v) => !!v || 'Required',
  (v) => !v || v.length <= 11 || 'Max 11 characters',
  (v) => (
    /(^[A-Z0-9()\-/'&]+$)|(^[A-Z0-9()\-/'&][A-Z 0-9()\-/'&]*[A-Z0-9()\-/'&]$)/.test(v)
    || 'Invalid number'
  ),
];

export {
  dbsNameRules,
  dbsSurnameRules,
  dbsEmailRules,
  dbsOptionalAddressLineRules,
  dbsAddressLineRules,
  dbsPlaceRules,
  dbsPostcodeRules,
  dbsOptionalPostcodeRules,
  dbsOptionalEmailRules,
  dbsDepartmentNameRules,
  dbsOptionalPositionRules,
  dbsPositionRules,
  dbsUpdateOrganisationRules,
  optionalNinRules,
  ninRules,
  passportRules,
};
