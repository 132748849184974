<template>
  <div v-if="caseActivityDetails.id">
    <v-icon> {{ icon }} </v-icon> {{ label }} on {{ eventDate }}.
  </div>
</template>

<script>

import { parse, format } from 'date-fns';

export default {
  name: 'AgentTrust2Ride',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    eventDate() {
      const d = parse(this.caseActivityDetails.result_data.event_date, 'yyyy-MM-dd', new Date());
      return format(d, 'do MMM y');
    },
    attended() {
      return this.caseActivityDetails.result_data.attended;
    },
    icon() {
      return (this.attended ? 'mdi-check-bold' : 'mdi-close-thick');
    },
    label() {
      return (this.attended ? 'Attended the session' : 'Did not attend the session');
    },

  },
};
</script>
