<template>
  <div>
    <v-list v-if="basicDetails.id && rtwDetails.id">
      <v-row>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.forename }}</v-list-item-title>
              <v-list-item-subtitle>Forename</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="basicDetails.middlename">
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.middlename }}</v-list-item-title>
              <v-list-item-subtitle>Middlename</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.surname }}</v-list-item-title>
              <v-list-item-subtitle>Surname</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ basicDetails.nationality_display }}</v-list-item-title>
              <v-list-item-subtitle>Nationality</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col cols="6">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ rtwDetails.state_display }}</v-list-item-title>
              <v-list-item-subtitle>Result</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'RtwDetails',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    basicDetails: {},
    rtwDetails: {},
  }),
  created() {
    this.getBasicDetails();
    this.getRtwDetails();
  },
  methods: {
    async getBasicDetails() {
      const resp = await this.$service.subject.caseBasicDetails.retrieve(
        this.caseActivityDetails.case.id,
      );
      if (resp.status === 200) {
        this.basicDetails = resp.data;
      }
    },
    async getRtwDetails() {
      const resp = await this.$service.subject.caseRtwDetails.retrieve(
        this.caseActivityDetails.case.id,
      );
      if (resp.status === 200) {
        this.rtwDetails = resp.data;
      }
    },
  },
};
</script>
