import activityRoutes from './activities';
import auditRoutes from './audit';
import caseRoutes from './cases';
import paymentRoutes from './payments';
import subjectRoutes from './subjects';
import subscriptionRoutes from './subscriptions';

export default [
  ...activityRoutes,
  ...auditRoutes,
  ...caseRoutes,
  ...paymentRoutes,
  ...subjectRoutes,
  ...subscriptionRoutes,
];
