export default [
  {
    path: '/users',
    component: () => import(
      /* webpackChunkName: "users" */
      '@/pages/product/config/UserManagement.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "users" */
          '@/components/product/users/List.vue'
        ),
        name: 'user-list',
        meta: {
          permissions: ['users.view_productuser'],
        },
      },
      {
        path: 'add',
        component: () => import(
          /* webpackChunkName: "users" */
          '@/components/product/users/Add.vue'
        ),
        props: true,
        name: 'user-add',
        meta: {
          permissions: ['users.add_productuser'],
        },
      },
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "users" */
          '@/components/product/users/Detail.vue'
        ),
        props: true,
        name: 'user-detail',
        meta: {
          permissions: ['users.view_productuser'],
        },
      },
      {
        path: ':id/edit',
        component: () => import(
          /* webpackChunkName: "users" */
          '@/components/product/users/Edit.vue'
        ),
        props: true,
        name: 'user-edit',
        meta: {
          permissions: ['users.change_productuser'],
        },
      },

    ],
  },
];
