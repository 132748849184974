<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <template v-if="extension === 'pdf'">
        <template v-for="(_, index) in pages">
          <v-divider
            v-if="index > 0"
            :key="`divider-${index}`"
          />
          <canvas
            ref="canvas"
            :key="`canvas-${index}`"
            class="canvas mt-8 mb-8"
          />
        </template>
      </template>
      <v-img
        v-else
        :src="src"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
  name: 'DocumentViewer',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    extension: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: this.value,
      pages: [],
    };
  },
  watch: {
    value(val) {
      this.show = val;
    },
  },
  mounted() {
    if (this.extension === 'pdf') {
      this.renderPdf();
    }
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
    async renderPdf() {
      const loadingTask = pdfjsLib.getDocument(this.src);
      const pdf = await loadingTask.promise;
      const numPages = await pdf.numPages;
      this.pages = new Array(numPages).fill(true);
      this.$nextTick(() => {
        this.pages.forEach((_, index) => {
          this.renderPage(pdf, index);
        });
      });
    },
    async renderPage(pdf, index) {
      const page = await pdf.getPage(index + 1);
      const canvas = this.$refs.canvas[index];
      const viewport = page.getViewport({
        scale: canvas.clientWidth / page.getViewport({ scale: 1 }).width,
      });

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render({
        canvasContext: canvas.getContext('2d'),
        viewport,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.canvas {
  width: 100%;
}
</style>
