const dbsUpdateResult = (status) => {
  let result = 'Unexpected status.';
  if (status === 'BLANK_NO_NEW_INFO') {
    result = `
      This DBS certificate did not reveal any information and remains current as no further
      information has been identified since its issue.
    `;
  } else if (status === 'NON_BLANK_NO_NEW_INFO') {
    result = `
      This DBS certificate remains current as no further information has been identified since
      its issue.
    `;
  } else if (status === 'NEW_INFO') {
    result = `
      This DBS Certificate is no longer current. Please apply for a new DBS check to get the
      most up-to-date information.
    `;
  } else if (status === 'NO_MATCH_FOUND') {
    result = 'No match found.';
  }
  return result;
};

const docQuestionsMapUK = {
  Passport: [
    'Is your passport currently still in date and not expired?',
  ],
  'Biometric residence permit': [
    'Was the document issued in the UK?',
    'Was the document issued by the Home Office to the holder?',
  ],
  'Photocard driving licence - (full or provisional)': [
    'Was the driving licence issued from the UK, Isle of Man or the Channel Islands?',
    'Is your driving licence currently still in date and not expired?',
  ],
  'Original birth certificate': [
    'Was the Birth Certificate issued by the UK, Isle of Man, and Channel Islands - including those issued by UK authorities overseas, for example embassies, High Commissions and HM Forces?',
    'Was the Birth Certificate issued within 12 months of birth?',
  ],
  'Adoption certificate': [
    'Was the Adoption Certificate issued by the UK / Channel Islands?',
  ],
  'Non-UK photocard driving licence - (full or provisional)': [
    'Was the licence issued outside the UK (excluding Isle of Man and Channel Islands)?',
    'Is your driving licence currently still in date and not expired?',
  ],
  'UK paper driving licence (full or provisional)': [
    'Was the licence issued in the UK, Isle of Man or Channel Islands?',
    'Is your driving licence currently still in date and not expired?',
    'Was the licence issued before 1998?',
  ],
  'Certified copy of birth certificate': [
    'Was the certificate issued in the UK, Isle of Man, or Channel Islands?',
    'Was the certificate issued 12 months or more after time of birth?',
  ],
  'Marriage/civil partnership certificate': [
    'Was the certificate issued in the UK or Channel Islands?',
    'Is the doucument an original (i.e. Not a photocopy)?',
  ],
  'Immigration document, visa, or work permit': [
    'Are you living or working outside of the UK?',
    'Is the role outside of the UK?',
    'Was the document issued by a country outside the UK?',
    'Does the Visa/permit relate to the non-UK country in which the role is based?',
  ],
  'HM Forces ID card': [
    'Was the document issed in the UK?',
  ],
  'Firearms licence': [
    'Was the document issed in the UK, Isle of Man, or Channel Islands?',
  ],
  'Mortgage statement': [
    'Is the issue date on this document within the last 12 months?',
    'Was the document issued in the UK?',
  ],
  'UK bank or building society statement': [
    'Is the issue date on this document within the last 3 months?',
    'Was the document issued in the UK?',
    'Is the statement a full official statement received through the post/printed directly in branch and is NOT a mini statement?',
    'I confirm that the statement is NOT an online statement; either a screenshot or printed at home?',
  ],
  'Non-UK bank or building society statement': [
    'Is the issue date on this document within the last 3 months?',
    'Is the branch shown on the statement in the country they will live or work in?',
    'Is the statement a full official statement received through the post/printed directly in branch and is NOT a mini statement?',
    'I confirm that the statement is NOT an online statement; either a screenshot or printed at home?',
  ],
  'Bank or building society account opening confirmation letter': [
    'Was the document issued in the UK?',
    'Is the issue date on this document within the last 3 months?',
    'I confirm that the statement is NOT an online statement; either a screenshot or printed at home?',
  ],
  'Credit card statement': [
    'Was the document issued in the UK?',
    'Is the document dated within the last 3 months’ to ‘is the issue date on this document within the last 3 months?',
    'Add I confirm that the statement is NOT an online statement; either a screenshot or printed at home?',
  ],
  'Financial statement (e.g. pension, endowment, ISA)': [
    'Was the document issued in the UK or Channel Islands?',
    'Is the issue date on this document within the last 12 months?',
    'I confirm that the statement is NOT an online statement; either a screenshot or printed at home?',
  ],
  'P45 or P60 statement': [
    'Was the document issued in the UK or Channel Islands?',
    'Is the document dated within the last 12 months?',
  ],
  'Council Tax statement': [
    'Was the document issued in the UK or Channel Islands?',
    'Is the document dated within the last 12 months?',
  ],
  'Letter of sponsorship from future employment provider': [
    'Do you currently live outside of the UK?',
    'Is the document valid, e.g. not expired?',
  ],
  'Utility bill': [
    'Was the document issued in the UK or Channel Islands',
    'Is the document for a utility OTHER THAN mobile phones?',
    'Is the document dated within the last 3 months?',
    'I confirm that the bill is NOT an online bill; either a screenshot or printed at home?',
  ],
  'Benefit statement': [
    'Was the document issued in the UK?',
    'Is the document dated within the last 3 months?',
  ],
  'Central or local government, government agency, or local council document giving entitlement': [
    'Does the document give an entitlement, for example from the Department for Work and Pensions, the Employment Service, HMRC?',
    'Was the document issued in the UK or Channel Islands?',
    'Is the document dated within the last 3 months?',
  ],
  'EEA National ID card': [
    'Is the document current, i.e. not expired?',
  ],
  'Irish Passport Card': [
    'Do you confirm you are NOT using this card in conjunction with an Irish passport?',
    'Is the document current, i.e. not expired?',
  ],
  'Cards carrying the PASS accreditation logo': [
    'Was the document issed in the UK, Isle of Man, or Channel Islands?',
    'Is the document current, i.e. not expired?',
  ],
  'Letter from head teacher or college principal': [
    'Was the document issued in the UK?',
    'Are you aged 16 to 19 years old & in full time education?',
    'Confirm this document is rarely used, only in exceptional circumstances when all other documents have been exhausted?',
  ],
};

const docQuestionsMapNonUK = {
  Passport: [
    'Is your passport currently still in date and not expired?',
    'Is the passport endorsed to show that the holder is exempt from immigration control, is allowed to stay indefinitely in the UK, has the right of abode in the UK, or has no time limit on their stay in the UK OR Endorsed to show that the holder is allowed to stay in the UK and is currently allowed to do the type of work in question?',
  ],
  'Photocard driving licence - (full or provisional)': [
    'Was the driving licence issued from the UK, Isle of Man or the Channel Islands?',
    'Is the driving licence current, i.e. not expired?',
  ],
  'Original birth certificate': [
    'Was the Birth Certificate issued by the UK, Isle of Man, and Channel Islands - including those issued by UK authorities overseas, for example embassies, High Commissions and HM Forces?',
    'Was the Birth Certificate issued within 12 months of birth?',
  ],
  'Adoption certificate': [
    'Was the Adoption certificate issue by the UK or Channel Islands?',
  ],
  'Biometric residence permit': [
    'Was the document issued in the UK?',
    'Was the document issued by the Home Office to the holder?',
    'Does the BRP indicate that the person named is allowed to stay indefinitely in the UK, or has no time limit on their stay in the UK?',
  ],
  'Confirmation of application for leave to enter or remain': [
    'Was the application made under Appendix EU to the immigration rules on or before 30 June 2021?',
    'Was the document issued by the Home Office?',
  ],
  'Home Office Application Registration Card': [
    'Was the document issued by the Home Office?',
    'Does the document state that the holder is permitted to take the employment in question?',
    'Confirm this document will be submitted together with a Positive Verification Notice from the Home Office Employer Checking Service?',
  ],
  'Home Office Letter to family member of a Swiss or EAA citizen': [
    'Are you a family member of an EEA or Swiss citizen?',
    'Was the document issued by the Home Office?',
    'Is the document current, i.e. not expired?',
    'Does the document indicate you are permitted to stay in the United Kingdom indefinitely?',
  ],
  'Positive Verification Notice': [
    'Was the document issued by the Home Office to the employer or prospective employer?',
    'Does the document indicate that the named person may stay in the UK and is permitted to do the work in question?',
  ],
  'Immigration Status Document with photograph': [
    'Is the document current, i.e. not expired?',
    'Was the document issued by the Home Office?',
    'Does the document have a valid endorsement indicating that the named person may stay in the UK, and is allowed to do the type of work in question?',
    'Confirm this document will be submitted together with an official document giving the person’s permanent National Insurance number and their name issued by a Government agency or a previous employer?',
  ],
  'Immigration Status Document': [
    'Is the document current, i.e. not expired?',
    'Was the document issued by the Home Office?',
    'Does the document have a valid endorsement indicating that the named person is allowed to stay indefinitely in the UK or has no time limit on their stay in the UK?',
    'Confirm this document will be submitted together with an official document giving the person’s permanent National Insurance number and their name issued by a Government agency or a previous employer?',
  ],
  'Online evidence of immigration status': [
    'Is the evidence via the View and Prove service or using the BRP or BRC online service?',
    'Is the evidence Issued by the Home Office to the employer or prospective employer?',
    'Does the evidence indicate that the named person may stay in the UK and is permitted to do the work in question?',
    'Is the evidence current, i.e. not expired Note: this includes the EUSS digital status confirmation?',
  ],
  'Frontier worker permit': [
    'Was the permit Issued under regulation 8 of the Citizens Rights (Frontier Workers) (EU Exit) Regulations 2020?',
  ],
  'Irish Passport or Passport Card': [
    'Is the Passport or Passport Card current, i.e. not expired?',
    'Is the holder a national of the Republic of Ireland?',
  ],
  'Non-UK photocard driving licence - (full or provisional)': [
    'Was the licence issued outside the UK (excluding Isle of Man and Channel Islands)?',
    'Is current and valid?',
  ],
  'UK Paper driving licence (full or provisional)': [
    'Was the licence issued in the UK, Isle of Man or Channel Islands?',
    'Is your driving licence currently still in date and not expired?',
    'Was the licence issued before 1998?',
  ],
  'Marriage/civil partnership certificate': [
    'Was the certificate issued in the UK or Channel Islands?',
    'Is the doucument an original (i.e. Not a photocopy)?',
  ],
  'Immigration document, visa, or work permit': [
    'Are you living or working outside of the UK?',
    'Is the role outside of the UK?',
    'Was the document issued by a country outside the UK?',
    'Does the Visa/permit relate to the non-UK country in which the role is based?',
  ],
  'HM Forces ID card': [
    'Was the document issed in the UK?',
  ],
  'Firearms licence': [
    'Was the document issed in the UK, Isle of Man, or Channel Islands?',
  ],
  'Mortgage statement': [
    'Is the issue date on this document within the last 12 months?',
    'Was the document issued in the UK?',
  ],
  'UK bank or building society statement': [
    'Is the issue date on this document within the last 3 months?',
    'Was the document issued in the UK?',
    'Is the statement a full official statement received through the post/printed directly in branch and is NOT a mini statement?',
    'I confirm that the statement is NOT an online statement; either a screenshot or printed at home?',
  ],
  'Non-UK bank or building society statement': [
    'Is the issue date on this document within the last 3 months?',
    'Is the branch shown on the statement in the country they will live or work in?',
    'Is the statement a full official statement received through the post/printed directly in branch and is NOT a mini statement?',
    'I confirm that the statement is NOT an online statement; either a screenshot or printed at home?',
  ],
  'Bank or building society account opening confirmation letter': [
    'Was the document issued in the UK?',
    'Is the issue date on this document within the last 3 months?',
    'I confirm that the statement is NOT an online statement; either a screenshot or printed at home?',
  ],
  'Credit card statement': [
    'Was the document issued in the UK?',
    'Is the document dated within the last 3 months’ to ‘is the issue date on this document within the last 3 months?',
    'Add I confirm that the statement is NOT an online statement; either a screenshot or printed at home?',
  ],
  'Financial statement (e.g. pension, endowment, ISA)': [
    'Was the document issued in the UK or Channel Islands?',
    'Is the issue date on this document within the last 12 months?',
    'I confirm that the statement is NOT an online statement; either a screenshot or printed at home?',
  ],
  'P45 or P60 statement': [
    'Was the document issued in the UK or Channel Islands?',
    'Is the document dated within the last 12 months?',
  ],
  'Council Tax statement': [
    'Was the document issued in the UK or Channel Islands?',
    'Is the document dated within the last 12 months?',
  ],
  'Letter of sponsorship from future employment provider': [
    'Do you currently live outside of the UK?',
    'Is the document valid, e.g. not expired?',
  ],
  'Utility bill': [
    'Was the document issued in the UK or Channel Islands',
    'Is the document for a utility OTHER THAN mobile phones?',
    'Is the document dated within the last 3 months?',
    'I confirm that the bill is NOT an online bill; either a screenshot or printed at home?',
  ],
  'Benefit statement': [
    'Was the document issued in the UK?',
    'Is the document dated within the last 3 months?',
  ],
  'Central or local government, government agency, or local council document giving entitlement': [
    'Does the document give an entitlement, for example from the Department for Work and Pensions, the Employment Service, HMRC?',
    'Was the document issued in the UK or Channel Islands?',
    'Is the document dated within the last 3 months?',
  ],
  'EEA National ID card': [
    'Is the document current, i.e. not expired?',
  ],
  'Irish Passport Card': [
    'Do you confirm you are NOT using this card in conjunction with an Irish passport?',
    'Is the document current, i.e. not expired?',
  ],
  'Cards carrying the PASS accreditation logo': [
    'Was the document issed in the UK, Isle of Man, or Channel Islands?',
    'Is the document current, i.e. not expired?',
  ],
  'Letter from head teacher or college principal': [
    'Was the document issued in the UK?',
    'Are you aged 16 to 19 years old & in full time education?',
    'Confirm this document is rarely used, only in exceptional circumstances when all other documents have been exhausted?',
  ],
};

export {
  dbsUpdateResult,
  docQuestionsMapUK,
  docQuestionsMapNonUK,
};
