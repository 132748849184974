import { request } from '@/service/plugin';

const path = '/api/accounts/';

const accounts = {
  checkSession: () => request('get', `${path}check-session/`),
  login: (data) => request(
    'post',
    `${path}login/`,
    {
      data,
      codeErrorMessages: {
        401: 'Invalid login details',
        400: 'MFA token required',
      },
      notificationTypes: {
        400: 'info',
      },
    },
  ),
  logout: () => request('post', '/api/accounts/logout/'),
  requestPasswordReset: (data) => request('post', `${path}password-reset/`, { data }),
  confirmPasswordReset: (data, options) => request('post', `${path}password-reset-confirm/`, { data, ...options }),
  registration: (data) => request('post', `${path}registration/`, { data }),
  claimAccount: (data) => request('post', '/api/user/claim-account/', { data }),
};

export default {
  accounts,
};
