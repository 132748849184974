export default [
  {
    path: '/payments/',
    component: () => import(
      /* webpackChunkName: "payments" */
      '@/pages/product/agents/Payments.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "payments" */
          '@/components/product/payments/List.vue'
        ),
        name: 'product-payment-list',
        meta: {
          permissions: ['payments.view_productpayment'],
        },
      },
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "payments" */
          '@/components/product/payments/Pay.vue'
        ),
        props: true,
        name: 'product-payment-pay',
        meta: {
          permissions: ['payments.view_productpayment', 'payments.change_productpayment'],
        },
      },
    ],
  },
];
