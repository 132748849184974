<template>
  <div v-if="caseActivityDetails.id">
    <template v-if="hasResult">
      <p>
        Overall assessment result: <v-icon> {{ icon }} </v-icon> {{ label }}
      </p>
      <agent-test-detail
        v-for="item in caseActivityDetails.result_data.cm_results"
        :key="item.link_result_id"
        :result="item"
      />
    </template>
    <template v-else>
      This knowledge test has not yet been completed
    </template>
  </div>
</template>

<script>
import AgentTestDetail from './AgentTestDetail.vue';

export default {
  name: 'AgentKnowledgeTest',
  components: {
    AgentTestDetail,
  },
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasResult() {
      return 'pass' in this.caseActivityDetails.result_data;
    },
    pass() {
      return this.caseActivityDetails.result_data.pass;
    },
    icon() {
      return (this.pass ? 'mdi-check-bold' : 'mdi-close-thick');
    },
    label() {
      return (this.pass ? 'Pass' : 'Fail');
    },

  },
};
</script>
