<template>
  <div class="mb-6">
    <v-divider />
    <v-row>
      <v-col>
        <span class="text--secondary">Overall Percentage:</span>
        <span class="font-weight-black mr-4"> {{ result.percentage }}%</span>
        <span class="text--secondary">Test Completed At:</span>
        <span class="font-weight-black"> {{ finishedAt|longdatetime }}</span>
      </v-col>
      <v-col
        cols="3"
        class="text-right"
      >
        <v-btn
          target="_blank"
          rel="noopener noreferrer"
          small
          :href="result.view_results_url"
        >
          <v-icon
            left
            small
          >
            mdi-open-in-new
          </v-icon>
          View full results
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="4"
      >
        <span class="overline">Introduction Information</span>
        <ValueList
          :items="introInfo"
        />
      </v-col>
      <v-col>
        <span class="overline">Categorised Results</span>
        <ResultTable
          :items="result.categories"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ValueList from '@/components/common/ValueList.vue';
import ResultTable from './ResultTable.vue';

export default {
  name: 'AgentTestDetail',
  components: {
    ResultTable,
    ValueList,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  computed: {
    finishedAt() {
      return this.result.time_finished * 1000;
    },
    introInfo() {
      const info = [
        {
          name: 'First Name',
          value: this.result.first,
        },
        {
          name: 'Last Name',
          value: this.result.last,
        },
        {
          name: 'Email',
          value: this.result.email,
        },
      ];

      if (this.result.extra_info.length > 0) {
        info.push(
          {
            name: 'Extra Info 1',
            value: this.result.extra_info,
          },
        );
      }
      if (this.result.extra_info2.length > 0) {
        info.push(
          {
            name: 'Extra Info 2',
            value: this.result.extra_info2,
          },
        );
      }

      return info;
    },
  },
};
</script>
