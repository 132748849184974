import activityRoutes from './activities';
import customerRoutes from './customers';
import trust2RideRoutes from './trust_to_ride';
import umbracoRoutes from './umbraco_imports';
import payments from './payments';

export default [
  {
    path: '/ops/dashboard',
    component: () => import(
      /* webpackChunkName: "ops-dashboard" */
      '@/pages/product/ops/Dashboard.vue'
    ),
    name: 'ops-dashboard',
    meta: {
      permissions: ['customers.ops_access'],
    },
  },
  ...activityRoutes,
  ...customerRoutes,
  ...trust2RideRoutes,
  ...umbracoRoutes,
  ...payments,
];
