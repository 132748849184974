<template>
  <div v-if="caseActivityDetails.id">
    <v-row>
      <v-col
        v-if="hasApplicationData"
      >
        <span
          class="overline"
        >
          Application Details
        </span>
        <p>
          <span class="body-2 text--secondary">E-Bulk Reference:</span>
          <span> {{ applicationData['Application ID'] }}</span>
          <br>
          <span class="body-2 text--secondary">Status:</span>
          <span> {{ applicationData['Status'] }}</span>
          <br>
          <span class="body-2 text--secondary">Volunteer:</span>
          <span> {{ applicationData['Volunteer'] }}</span>
          <br>
          <span class="body-2 text--secondary">Name:</span>
          <span> {{ applicationData['Name'] }}</span>
          <br>
          <span class="body-2 text--secondary">Date of Birth:</span>
          <span> {{ applicationData['Date of Birth'] }}</span>
          <br>
          <span class="body-2 text--secondary">Current address:</span>
          <span> {{ applicationData['Current address'].trim() }}</span>
        </p>
        <span
          class="overline"
        >
          Barring Lists
        </span>
        <p>
          <span class="body-2 text--secondary">Children's List:</span>
          <span> {{ applicationData['DBS Children\'s Barred List'] }}</span>
          <br>
          <span class="body-2 text--secondary">Vulnerable Adults List:</span>
          <span> {{ applicationData['DBS Vulnerable Adults Barred List'] }}</span>
          <br>
        </p>
      </v-col>
      <v-divider
        vertical
        inset
      />
      <v-col>
        <span class="overline">Receipt Data</span>
        <template
          v-if="hasReceipts"
        >
          <p>
            <span class="body-2 text--secondary">Status</span>
            <span> {{ receiptData['Status'] }}</span>
            <br>
            <span class="body-2 text--secondary">DBS Reference</span>
            <span> {{ receiptData['DBS Reference'] }}</span>
            <br>
            <span class="body-2 text--secondary">Receipt Timestamp</span>
            <span> {{ receiptData['Timestamp'] }}</span>
          </p>
        </template>
        <template
          v-else
        >
          <p>
            <span class="font-italic text--secondary">DBS Receipts not yet available</span>
          </p>
        </template>
        <span class="overline">Tracking Data</span>
        <span
          v-if="hasTracking && applicationData.Tracking.last_checked_at"
          class="text-caption grey--text"
        > updated {{ applicationData.Tracking.last_checked_at|longdatetime }}</span>
        <template
          v-if="hasTracking"
        >
          <p>
            <span
              v-for="item in applicationData.Tracking.stages"
              :key="item.stage_id"
            >
              <span class="body-2 text--secondary">{{ item.stage }}</span>
              <span> {{ item.status }}</span> <span v-if="item.completed_at">
                on {{ addIsoTime(item.completed_at)|longdate }}
              </span>
              <br>
            </span>
          </p>
        </template>
        <template
          v-else
        >
          <p>
            <span class="font-italic text--secondary">DBS Tracking not yet available</span>
          </p>
        </template>
      </v-col>
      <v-divider
        vertical
        inset
      />
      <v-col>
        <span class="overline">Result Data</span>

        <template
          v-if="hasResults"
        >
          <p>
            <span class="body-2 text--secondary">Disclosure Status</span><br>
            <span class="font-weight-bold">{{ resultData['Disclosure Status'] }}</span>
          </p>
          <p>
            <span class="body-2 text--secondary">Disclosure Number</span>
            <span> {{ resultData['Disclosure Number'] }}</span>
            <br>
            <span class="body-2 text--secondary">Disclosure Issue Date</span>
            <span> {{ resultData['Disclosure Issue Date'] }}</span>
            <br>
            <span class="body-2 text--secondary">Disclosure Type</span>
            <span> {{ resultData['Disclosure Type'] }}</span>
            <br>
            <span class="body-2 text--secondary">Result Timestamp</span>
            <span> {{ resultData['Timestamp'] }}</span>
          </p>
          <p v-if="updateServiceStatus">
            <span class="body-2 text--secondary">Update Service</span>
            <span> {{ updateServiceStatus }}</span>
          </p>
        </template>
        <template
          v-else
        >
          <p>
            <span class="font-italic text--secondary">DBS Results not yet available</span>
          </p>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'CantiumEbulk',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    activityData() {
      return this.caseActivityDetails.result_data;
    },
    applicationData() {
      return 'Application Data' in this.activityData ? this.activityData['Application Data'] : null;
    },
    receiptData() {
      return 'DBS Receipts' in this.activityData ? this.activityData['DBS Receipts'] : null;
    },
    resultData() {
      return 'DBS Results' in this.activityData ? this.activityData['DBS Results'] : null;
    },
    hasApplicationData() {
      return this.applicationData && !('placeholder' in this.applicationData);
    },
    hasTracking() {
      return this.applicationData?.Tracking && this.applicationData.Tracking?.stages.length > 0;
    },
    hasReceipts() {
      return this.receiptData && !('placeholder' in this.receiptData);
    },
    hasResults() {
      return this.resultData && !('placeholder' in this.resultData);
    },
    updateServiceStatus() {
      const matchStatuses = ['BLANK_NO_NEW_INFO', 'NON_BLANK_NO_NEW_INFO', 'NEW_INFO'];
      const updateService = this.activityData.update_service;
      let status = null;
      if (updateService && matchStatuses.includes(updateService.status)) {
        status = 'Registered';
      } else if (updateService && this.caseActivityDetails.state === 'ca-in-progress') {
        status = 'Pending';
      } else if (updateService) {
        status = 'Not registered';
      }
      return status;
    },
  },
  methods: {
    addIsoTime(isoDate) {
      const parts = isoDate.split('-');
      return `${parts[2]}-${parts[1]}-${parts[0]}T00:00:00`;
    },
  },
};
</script>
