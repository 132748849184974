import { viewsetActions, request } from '@/service/plugin';

import cases from './cases';
import customerConfig from './customers';
import flags from './flags';
import ops from './ops';
import payments from './payments';
import pricebooks from './pricebooks';
import subjects from './subjects';
import { users, roles } from './users';

export default {
  ...cases,
  ...customerConfig,
  ...payments,
  ...pricebooks,
  ...subjects,
  activityTypes: viewsetActions('activity-types'),
  auditActions: viewsetActions('audit-actions'),
  flags,
  documentCollectors: viewsetActions('document-collectors'),
  dbsUpdateDetails: viewsetActions('dbs-update-details'),
  processes: viewsetActions('processes', {
    resourceSingular: 'process',
    extraActions: {
      requirement: { path: 'requirement', method: 'post', detail: true },
    },
  }),
  registrationPortals: viewsetActions('registration-portals', { resourceSingular: 'registration portal' }),
  processConfigs: viewsetActions('process-configs', { resourceSingular: 'process config' }),
  cycles: viewsetActions('cycles'),
  subscriptions: viewsetActions('subscriptions', {
    extraActions: {
      activate: { path: 'activate', method: 'post', detail: true },
      disable: { path: 'disable', method: 'post', detail: true },
      run: { path: 'run', method: 'post', detail: true },
    },
  }),
  users,
  roles,
  ops,
  countryMap: () => request('get', '/api/countries/'),
};
