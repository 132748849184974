import adminRoutes from './admins';
import agentRoutes from './agents';
import opsRoutes from './ops';

export default [
  ...agentRoutes,
  ...adminRoutes,
  ...opsRoutes,
  {
    path: 'dashboard',
    component: () => import(
      /* webpackChunkName: "generic" */
      '@/pages/product/agents/Dashboard.vue'
    ),
    name: 'dashboard',
    meta: {
      hideBreadcrumbs: true,
    },
  },
  {
    path: 'profile',
    component: () => import(/* webpackChunkName: "generic" */ '@/pages/product/Profile.vue'),
    name: 'profile',
  },
  {
    path: 'profile/totp',
    component: () => import(/* webpackChunkName: "generic" */ '@/components/product/users/AddTotp.vue'),
    name: 'profile-add-totp',
  },
  {
    path: 'profile/totp/remove',
    component: () => import(/* webpackChunkName: "generic" */ '@/components/product/users/RemoveTotp.vue'),
    name: 'profile-remove-totp',
  },
  {
    path: 'customer-account',
    component: () => import(
      /* webpackChunkName: "generic" */
      '@/pages/product/CustomerAccount.vue'
    ),
    name: 'customer-account',
    meta: {
      permissions: ['pricebooks.view_pricebookentry'],
    },
  },
  {
    path: 'support',
    component: () => import(/* webpackChunkName: "generic" */ '@/pages/product/agents/Support.vue'),
    name: 'support',
    meta: { hideBreadcrumbs: true },
  },
];
