<template>
  <div v-if="caseActivityDetails.id">
    <v-row>
      <v-col>
        <span
          class="overline"
        >
          Application Details
        </span>
        <p>
          <span class="body-2 text--secondary">Status:</span>
          <span> {{ applicationData['status'] }}</span>
          <br>
          <span class="body-2 text--secondary">Forename:</span>
          <span> {{ applicationData['forename'] }}</span>
          <br>
          <span class="body-2 text--secondary">Surname:</span>
          <span> {{ applicationData['surname'] }}</span>
          <br>
          <span class="body-2 text--secondary">Print date:</span>
          <span> {{ applicationData['print_date'] }}</span>
        </p>
      </v-col>
      <v-divider
        vertical
        inset
      />
      <v-col>
        <span
          class="overline"
        >
          Result
        </span>
        <p>
          <span class="font-italic text--secondary">{{ result }}</span>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { dbsUpdateResult } from '@/util/dbs';

export default {
  name: 'DbsUpdate',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    applicationData() {
      return this.caseActivityDetails.result_data;
    },
    result() {
      if (this.caseActivityDetails.state === 'ca-in-progress') {
        return 'Pending';
      }
      return dbsUpdateResult(this.applicationData.status);
    },
  },
};
</script>
