import Vue from 'vue';
import { format, parseJSON, parseISO } from 'date-fns';

Vue.filter('yesno', (value) => (value ? 'yes' : 'no'));

Vue.filter('valueornone', (value) => {
  if (!value) return 'none';
  return value;
});

const capitalize = (value) => {
  if (!value) return '';
  const v = value.toString();
  return v.charAt(0).toUpperCase() + v.slice(1);
};

const longDate = (value) => {
  if (!value) return null;
  const d = parseJSON(value);
  return format(d, 'do MMM y');
};

const longIsoDate = (value) => {
  if (!value) return null;
  const d = parseISO(value);
  return format(d, 'do MMM y');
};

const longDatetime = (value) => {
  if (!value) return null;
  const d = parseJSON(value);
  return format(d, "do MMM y 'at' HH:mm:ss");
};

const shortDate = (value) => {
  if (!value) return null;
  const d = parseJSON(value);
  return format(d, 'u-MM-dd');
};

const shortDatetime = (value) => {
  if (!value) return null;
  const d = parseJSON(value);
  return format(d, 'u-MM-dd  HH:mm');
};

const longTime = (value) => {
  if (!value) return null;
  const d = parseJSON(value);
  return format(d, 'HH:mm:ss');
};

const aspDate = (value) => {
  if (!value) {
    return value;
  }
  const ts = parseInt(value.substr(6), 10);
  const d = new Date(ts);
  if (d.getFullYear() < 1800) {
    return null;
  }
  return d;
};

const penceToPounds = (value) => {
  const formattedPrice = (Number(value) / 100).toFixed(2);
  return `£${formattedPrice}`;
};

const cyclePeriod = (cycle) => {
  const periods = {
    weekly: 'week',
    monthly: 'month',
    yearly: 'year',
  };
  if (cycle.interval > 1) {
    return `Every ${cycle.interval} ${periods[cycle.triggered]}s`;
  }
  return `Every ${periods[cycle.triggered]}`;
};

Vue.filter('capitalize', capitalize);
Vue.filter('longdatetime', longDatetime);
Vue.filter('longdate', longDate);
Vue.filter('longIsoDate', longIsoDate);
Vue.filter('longtime', longTime);
Vue.filter('shortdatetime', shortDatetime);
Vue.filter('shortdate', shortDate);
Vue.filter('aspdate', aspDate);
Vue.filter('penceToPounds', penceToPounds);
Vue.filter('cyclePeriod', cyclePeriod);

export {
  capitalize,
  longDate,
  longIsoDate,
  longDatetime,
  shortDate,
  shortDatetime,
  aspDate,
  penceToPounds,
  cyclePeriod,
};
