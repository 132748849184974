import { viewsetActions } from '@/service/plugin';

export default {
  productPaymentIntents: viewsetActions('product-payment-intents', {
    basePath: '/api/public/',
    extraActions: {
      getStripeDetails: { path: 'stripe-details', method: 'get', detail: true },
      fakePayment: { path: 'fake-payment', method: 'post', detail: true },
    },
  }),
};
