<template>
  <div v-if="documents.length">
    <CollectorDetails
      v-for="collector in caseActivityDetails.config.collectors"
      :key="collector.id"
      :collector="collector"
      :documents="documents.filter(doc => doc.collector === collector.id)"
    />
  </div>
</template>

<script>
import CollectorDetails from './SubjectDetailCollector.vue';

export default {
  name: 'DocumentCollection',
  components: {
    CollectorDetails,
  },
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    documents: [],
  }),
  created() {
    this.getDocuments();
  },
  methods: {
    async getDocuments() {
      const resp = await this.$service.subject.caseDocuments.list(
        { params: { case: this.caseActivityDetails.case.id } },
      );
      this.documents = resp.data;
    },
  },
};
</script>
