<template>
  <v-row>
    <edit-case-related-data
      :case-id="caseActivity.case.id"
      :ops="true"
    />
  </v-row>
</template>

<script>
import EditCaseRelatedData from '@/components/product/cases/related_data/EditCaseRelatedData.vue';

export default {
  name: 'BasicOpDetails',
  components: {
    EditCaseRelatedData,
  },
  props: {
    caseActivity: {
      type: Object,
      required: true,
    },
  },
  data: () => ({}),
};
</script>
