<template>
  <div>
    <h3>Generic case activity details component</h3>
    Raw Result Data: <pre>{{ caseActivityDetails.result_data }}</pre>
  </div>
</template>

<script>

export default {
  name: 'GenericActivity',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
};
</script>
