import { viewsetActions, request } from '@/service/plugin';

const customerConfig = {
  checkOpsAccess: (options) => request('get', '/api/customer/ops-access/', options),
  grantOpsAccess: (data) => request('put', '/api/customer/ops-access/', { data }),
};

const customerSettings = {
  retrieve: (opt) => request('get', '/api/customer/settings/', opt),
};

const paymentSettings = {
  retrieve: (opt) => request('get', '/api/customer/payment-settings/', opt),
  update: (data) => request('put', '/api/customer/payment-settings/', { data }),
};

export default {
  caseDecisions: viewsetActions('case-decisions'),
  caseActivityDecisions: viewsetActions('case-activity-decisions'),
  customerConfig,
  customerSettings,
  paymentSettings,
  paymentMethods: viewsetActions('payment-methods', {
    extraActions: {
      setupIntent: { path: 'setup-intent', method: 'post', detail: false },
    },
  }),
  segments: viewsetActions('segments'),
};
