import Vue from 'vue';
import Vuetify from '@/plugins/vuetify';

const data = {
  profile: {},
};

const userGetters = {
  isOps: (state) => state.profile.roles && state.profile.roles.includes('ops'),
  isAdmin: (state) => state.profile.roles && state.profile.roles.includes('admin'),
  fullName: (state) => `${state.profile.firstName} ${state.profile.lastName}`,
  profileClone: (state) => JSON.parse(JSON.stringify(state.profile)),
  canEditProcessRequirements: (state, getters) => getters.isOps || state.profile.customer.demo_mode,
};

const mutations = {
  setProductProfile(state, payload) {
    state.profile = {
      id: payload.id,
      firstName: payload.first_name,
      lastName: payload.last_name,
      userName: payload.username,
      jobTitle: payload.job_title,
      darkMode: payload.dark_mode,
      totpEnabled: payload.totp_enabled,
      notifications: payload.notifications,
      avatar: payload.avatar,
      roles: payload.roles,
      customer: payload.customer,
      permissions: payload.session_permissions,
      termsAgreedAt: payload.terms_agreed_at,
    };
    Vuetify.framework.theme.dark = payload.dark_mode;
  },
  setSubjectProfile(state, payload) {
    state.profile = {
      firstName: payload.first_name,
      lastName: payload.last_name,
      middlename: payload.middlename,
      email: payload.email,
      darkMode: payload.dark_mode,
      dob: payload.dob,
      canChangeDob: payload.can_change_dob,
      phoneNumber: payload.phone_number,
      termsAgreedAt: payload.terms_agreed_at,
      dbsEmailNotifications: payload.dbs_email_notifications,
    };
    Vuetify.framework.theme.dark = payload.dark_mode;
  },
  updateProductProfile(state, payload) {
    Object.assign(state.profile, {
      id: payload.id,
      firstName: payload.first_name,
      lastName: payload.last_name,
      darkMode: payload.dark_mode,
      notifications: payload.notifications,
      avatar: payload.avatar,
      termsAgreedAt: payload.terms_agreed_at,
    });
    Vuetify.framework.theme.dark = payload.dark_mode;
  },
  updateSubjectProfile(state, payload) {
    Object.assign(state.profile, {
      firstName: payload.first_name,
      lastName: payload.last_name,
      middlename: payload.middlename,
      email: payload.email,
      darkMode: payload.dark_mode,
      dob: payload.dob,
      phoneNumber: payload.phone_number,
      termsAgreedAt: payload.terms_agreed_at,
      dbsEmailNotifications: payload.dbs_email_notifications,
    });
    Vuetify.framework.theme.dark = payload.dark_mode;
  },
  updateSubjectName(state, payload) {
    Object.assign(state.profile, {
      firstName: payload.first_name,
      lastName: payload.last_name,
      middlename: payload.middlename,
    });
  },
  cleanProfile(state) {
    state.profile = {};
  },
};

const actions = {
  async getProfile({ commit, rootState }) {
    const resp = await Vue.service.public.profile.retrieve();
    if (resp.status === 200) {
      if (rootState.auth.userType === 'ProductUser') {
        commit('setProductProfile', resp.data);
      } else if (rootState.auth.userType === 'SubjectUser') {
        commit('setSubjectProfile', resp.data);
      }
    }
  },
  async saveProfile({ commit, rootState }, newProfile) {
    const resp = await Vue.service.public.profile.update(newProfile);
    if (resp.status === 200) {
      if (rootState.auth.userType === 'ProductUser') {
        commit('updateProductProfile', resp.data);
      } else if (rootState.auth.userType === 'SubjectUser') {
        commit('updateSubjectProfile', resp.data);
      }
    }
  },
};

export default {
  namespaced: true,
  state: data,
  getters: userGetters,
  actions,
  mutations,
};
