<template>
  <div v-if="caseActivityDetails.id">
    <v-row>
      <v-col
        cols="12"
        sm="4"
      >
        <span
          class="overline"
        >
          Result Details
        </span>
        <p>
          <span class="body-2 text--secondary">Status:</span>
          <span>
            {{ resultData.licence.status }}
            <span v-if="resultData.licence.status_qualifier">
              ({{ resultData.licence.status_qualifier }})
            </span>
          </span>
          <br>
          <span class="body-2 text--secondary">Endorsements:</span>
          <span> {{ resultData.endorsements ? resultData.endorsements.length : 0 }}</span>
          <br>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'DriverData',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
  }),
  computed: {
    resultData() {
      return this.caseActivityDetails.result_data;
    },
  },
};
</script>
