import processRoutes from './processes';
import userRoutes from './users';
import paymentSettingsRoutes from './payment_settings';
import segmentRoutes from './segments';

export default [
  ...processRoutes,
  ...userRoutes,
  ...paymentSettingsRoutes,
  ...segmentRoutes,
];
