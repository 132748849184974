<template>
  <div>
    <v-row>
      <v-col>
        <span class="text--secondary">Finished:</span>
        <span class="font-weight-black"> {{ finishedAt|longdatetime }}</span>
        |
        <span class="text--secondary">ID:</span>
        <span class="font-weight-black">  {{ result.test_id }}</span>
        |
        <span class="text--secondary">Overall Percentage:</span>
        <span class="font-weight-black"> {{ result.percentage }}%</span>
      </v-col>
      <v-col
        cols="3"
      >
        <v-btn
          target="_blank"
          rel="noopener noreferrer"
          small
          :href="result.view_results_url"
        >
          <v-icon
            left
            small
          >
            mdi-open-in-new
          </v-icon>
          View full results
        </v-btn>
      </v-col>
    </v-row>

    <ResultTable
      :items="result.categories"
    />

    <v-divider
      class="mt-1 mb-3"
      inset
    />
  </div>
</template>

<script>
import ResultTable from './ResultTable.vue';

export default {
  name: 'OpsTestDetail',
  components: {
    ResultTable,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
  },
  computed: {
    finishedAt() {
      return this.result.time_finished * 1000;
    },
  },
};
</script>
