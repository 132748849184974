<template>
  <div>
    <template v-if="resultData.LicenceStatusDescription">
      <v-row>
        <v-col cols="4">
          <v-card outlined>
            <v-card-title>Status</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <span class="overline">Status</span><br>
                  <span class="subtitle-1 font-weight-black">
                    {{ resultData.LicenceStatusDescription }}
                  </span>
                </v-col>
                <v-col>
                  <span class="overline">Risk</span><br>
                  <span class="subtitle-1 font-weight-black">
                    {{ caseActivityDetails.result_data.Risk }}
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span class="overline">Last Check</span><br>
                  <span class="font-weight-black">
                    {{ resultData.LastCheckDate|aspdate|longdate }}
                  </span>
                </v-col>
                <v-col>
                  <span class="overline">Next check</span><br>
                  <span class="font-weight-black">
                    {{ resultData.NextCheckDate|aspdate|longdate }}
                  </span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined>
            <v-card-title>Driving Licence</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <span class="overline">Licence Data</span><br>
                  <ValueList
                    :items="licenceInfo"
                  />
                </v-col>
                <v-col>
                  <span class="overline">Address</span><br>
                  <ValueList
                    :items="addressInfo"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-card
        class="my-4"
        outlined
      >
        <v-card-title class="headline">
          Endorsements
        </v-card-title>

        <v-data-table
          :headers="endorsementHeaders"
          :items="resultData.Endorsements"
          disable-pagination
          hide-default-footer
          dense
        >
          <template #item.Description="{ item }">
            {{ endorsementData(item.OffenceCode)[0] }}
          </template>
          <template #item.OffenceDate="{ item }">
            {{ item.OffenceDate|aspdate|longdate }}
          </template>
          <template #item.ConvictionDate="{ item }">
            {{ item.ConvictionDate|aspdate|longdate }}
          </template>
          <template #item.DisqualifiedEndDate="{ item }">
            {{ item.DisqualifiedEndDate|aspdate|longdate }}
          </template>
        </v-data-table>
        <!--
          <template v-slot:item.ConvictionDate="{ item }">
            {{ item.CommencedDate|aspdate|longdate }}
          </template>
        -->
      </v-card>

      <v-card outlined>
        <v-card-title class="headline">
          Entitlements
        </v-card-title>
        <v-card-text>
          <p>
            To view the definitions of Categories and Restrictions please visit the gov.uk pages:
            <a
              href="https://www.gov.uk/driving-licence-categories"
              target="_blank"
              rel="noopener noreferrer"
            >
              Driving Licence Categories
              <v-icon
                x-small
                color="blue"
              >
                mdi-open-in-new
              </v-icon>
            </a> &
            <a
              href="https://www.gov.uk/driving-licence-codes"
              target="_blank"
              rel="noopener noreferrer"
            >
              Driving Licence Restrictions
              <v-icon
                x-small
                color="blue"
              >
                mdi-open-in-new
              </v-icon>
            </a>
          </p>

          <v-data-table
            :headers="headers"
            :items="resultData.Entitlements"
            disable-pagination
            hide-default-footer
            dense
          >
            <template #item.ExpiryDate="{ item }">
              {{ item.ExpiryDate|aspdate|longdate }}
            </template>
            <template #item.CommencedDate="{ item }">
              {{ item.CommencedDate|aspdate|longdate }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>
    <template v-else-if="resultData.CheckStatus === 'AwaitingCheck'">
      Results not yet available.
    </template>
    <template v-else-if="resultData.CheckStatus === 'DQ3'">
      There is currently an issue on the driving licence record at the DVLA preventing us from
      retrieving the driving licence details electronically. A manual response from the DVLA will be
      received within 14-28 working days of the request being submitted. We will then update the
      driving licence record accordingly.
    </template>
    <template v-else>
      Waiting for subject to provide licence details.
    </template>
  </div>
</template>

<script>
import ValueList from '@/components/common/ValueList.vue';
import { endorsementData } from '@/util/dvla';
import { aspDate, longDate } from '@/filters/index';

export default {
  name: 'LicenceCheck',
  components: {
    ValueList,
  },
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    tab: 'licence',
    endorsementHeaders: [
      {
        text: 'Description',
        value: 'Description',
        filterable: true,
      },
      {
        text: 'Code',
        value: 'OffenceCode',
        filterable: true,
      },
      {
        text: 'Offence Date',
        value: 'OffenceDate',
        filterable: true,
      },
      {
        text: 'Conviction Date',
        value: 'ConvictionDate',
        filterable: true,
      },
      {
        text: 'Disqualified to',
        value: 'DisqualifiedEndDate',
        filterable: true,
      },
      {
        text: 'Points',
        value: 'Points',
        filterable: true,
      },
    ],
    headers: [
      {
        text: 'Category',
        value: 'Category',
        filterable: true,
      },
      {
        text: 'Status',
        value: 'Status',
        filterable: true,
      },
      {
        text: 'Restrictions',
        value: 'Restrictions',
        filterable: true,
      },
      {
        text: 'Expires',
        value: 'ExpiryDate',
        filterable: true,
      },
      {
        text: 'Commenced',
        value: 'CommencedDate',
        filterable: true,
      },
    ],
  }),
  computed: {
    resultData() {
      return this.caseActivityDetails.result_data;
    },
    addressInfo() {
      return [
        { name: 'Line 1', value: this.resultData.Address1 },
        { name: 'Line 2', value: this.resultData.Address2 },
        { name: 'Line 3', value: this.resultData.Address3 },
        { name: 'Town', value: this.resultData.PostalTown },
        { name: 'Postcode', value: this.resultData.PostalCode },
      ];
    },
    licenceInfo() {
      const issuedAt = this.resultData.CardIssueDate ? longDate(aspDate(this.resultData.CardIssueDate)) : '-';
      const photoExpiry = this.resultData.PhotoExpiryDate ? longDate(aspDate(this.resultData.PhotoExpiryDate)) : '-';
      const driver = this.resultData.Driver;
      return [
        { name: 'Driver Number', value: this.resultData.DriverNumber },
        { name: 'Issued At', value: issuedAt },
        { name: 'Issued #', value: this.resultData.IssueNumber },
        { name: 'Name', value: [driver.Firstname, driver.Middlename, driver.Lastname].join(' ') },
        { name: 'Date of Birth', value: longDate(aspDate(driver.DateOfBirth)) },
        { name: 'Valid From', value: longDate(aspDate(this.resultData.LicenceValidFromDate)) },
        { name: 'Valid To', value: longDate(aspDate(this.resultData.LicenceValidToDate)) },
        { name: 'Photo Expiry date', value: photoExpiry },
      ];
    },
  },
  methods: {
    endorsementData,
  },
};
</script>
