import { activityTypeIcon } from '@/util/component-mappings';

const moduleState = {
  id: null,
  portal: null,
  caseData: {
    basicDetails: null,
    documentation: { passport: null, drivingLicence: null },
    onlineIdv: null,
  },
  subjectData: {
    drivingLicenceDetails: null,
  },
  subscriptions: [],
  countryMap: {},
  subSectionProgress: 0,
};

const moduleGetters = {
  sections: (state) => {
    if (!state.portal) {
      return [];
    }
    const orderedCodes = [
      'online-idv',
      'basic-details',
      'address-history',
      'document-collection',
      'dbs-enhanced',
      'dbs-standard',
      'dbs-basic',
      'dbs-update',
      'driver-data',
      'rtw',
    ];
    const consentCodes = ['dbs-update', 'driver-data'];
    const sections = [{
      title: 'Welcome',
      route: { name: 's:case-portal-section', params: { id: state.id, section: 'welcome' } },
      icon: 'mdi-human-greeting',
    }];
    orderedCodes.forEach((code) => {
      let activity = state.portal.case_activities.find((act) => act.activity_type.code === code);
      let activityType;
      if (activity) {
        activityType = activity.activity_type;
      } else if (consentCodes.includes(code)) {
        const subscription = state.subscriptions.find(
          (s) => s.cycle.requirements.some((r) => r.activity_type.code === code),
        );
        if (subscription) {
          activity = subscription.cycle.requirements.find((r) => r.activity_type.code === code);
          activityType = activity.activity_type;
        } else {
          activityType = state.portal.required_consents.find((at) => at.code === code);
        }
      }
      if (activityType) {
        sections.push({
          title: activityType.code_display,
          route: {
            name: 's:case-portal-section',
            params: { id: state.id, section: activityType.code },
          },
          icon: activityTypeIcon(activityType.code),
        });
      }
    });
    sections.push({
      title: 'Review & Submit',
      route: { name: 's:case-portal-section', params: { id: state.id, section: 'submit' } },
      icon: 'mdi-clipboard-text',
    });
    sections.push({
      title: 'Done',
      route: { name: 's:case-portal-section', params: { id: state.id, section: 'done' } },
      icon: 'mdi-exit-run',
    });
    return sections;
  },
  completedIndex: (state, getters) => {
    if (state.portal === null) {
      return -1;
    }
    const section = state.portal.portal_last_completed_section.split('/')[0];
    const index = getters.sections.findIndex((s) => s.route.params.section === section);
    // Returns previous index if subsection
    return state.portal.portal_last_completed_section.includes('/') ? index - 1 : index;
  },
  progress: (state, getters) => (
    ((getters.completedIndex + 1) * 100 + state.subSectionProgress) / (getters.sections.length - 1)
  ),
  dbsLevel: (state) => {
    if (!state.portal) {
      return null;
    }
    const codes = ['dbs-basic', 'dbs-standard', 'dbs-enhanced'];
    const dbs = state.portal.case_activities.find((act) => codes.includes(act.activity_type.code));
    return dbs ? dbs.activity_type.code : null;
  },
  isEbulk: (state, getters) => ['dbs-standard', 'dbs-enhanced'].includes(getters.dbsLevel),
  countryItems: (state) => {
    const countries = Object.entries(state.countryMap).filter((entry) => !entry[1].deprecated);
    return countries.map(
      (entry) => ({ value: entry[0], text: entry[1].name }),
    ).sort((a, b) => {
      if (a.value === 'GBR') {
        return -1;
      } if (b.value === 'GBR') {
        return 1;
      }
      return 0;
    });
  },
  nationalityItems: (state) => {
    const countries = Object.entries(state.countryMap).filter((entry) => !entry[1].deprecated);
    return countries.map(
      (entry) => ({ value: entry[0], text: `${entry[1].nationality} (${entry[1].name})` }),
    ).sort((a, b) => {
      if (a.value === 'GBR') {
        return -1;
      } if (b.value === 'GBR') {
        return 1;
      }
      return 0;
    });
  },
  ebulkCountryItems: (state) => {
    const countries = Object.entries(state.countryMap).filter((entry) => !entry[1].ebulk_missing);
    return countries.map((entry) => ({ value: entry[0], text: entry[1].name }));
  },
  ebulkNationalityItems: (state) => {
    const countries = Object.entries(state.countryMap).filter((entry) => !entry[1].ebulk_missing);
    return countries.map((entry) => (
      { value: entry[0], text: `${entry[1].nationality} (${entry[1].name})` }
    ));
  },
  isBritish: (state) => {
    const britishCodes = ['GBR', 'GGY', 'IMN', 'JEY'];
    return (
      state.caseData.basicDetails && britishCodes.includes(state.caseData.basicDetails.nationality)
    );
  },
  idvSchemes: (state) => {
    const idProfile = state.caseData.onlineIdv?.session?.identity_profile;
    return idProfile?.identity_profile_report?.schemes_compliance || [];
  },
  onlineIdVerified: (state, getters) => (
    getters.idvSchemes.some((s) => s.requirements_met)
      && !state.caseData.onlineIdv?.manual_idv_approved
  ),
  onlineDbsVerified: (state, getters) => {
    const dbsScheme = getters.idvSchemes.find((s) => s.scheme.type === 'DBS');
    return dbsScheme?.requirements_met && !state.caseData.onlineIdv?.manual_idv_approved;
  },
  onlineRtwVerified: (state, getters) => {
    const rtwScheme = getters.idvSchemes.find((s) => s.scheme.type === 'RTW');
    return rtwScheme?.requirements_met;
  },
};

const mutations = {
  init: (state, payload) => {
    state.id = payload.id;
    state.portal = payload.portal;
    state.caseData = {
      basicDetails: null,
      documentation: { passport: null, drivingLicence: null },
      onlineIdv: null,
    };
    state.subjectData = {
      drivingLicenceDetails: null,
    };
    state.subscriptions = [];
  },
  clear: (state) => {
    state.id = null;
    state.portal = null;
    state.caseData = {};
    state.subjectData = {};
    state.subscirptions = [];
  },
  completeSection: (state, section) => {
    state.portal.portal_last_completed_section = section;
    state.subSectionProgress = 0;
  },
  setBasicDetails: (state, payload) => {
    state.caseData.basicDetails = { ...state.caseData.basicDetails, ...payload };
  },
  setDocumentation: (state, payload) => {
    state.caseData.documentation = { ...state.caseData.documentation, ...payload };
  },
  setOnlineIdv: (state, payload) => {
    state.caseData.onlineIdv = payload;
  },
  setSubjectDrivingLicence: (state, payload) => {
    state.subjectData.drivingLicenceDetails = payload;
  },
  setSubscriptions: (state, payload) => {
    state.subscriptions = payload;
  },
  setCountryMap: (state, payload) => {
    state.countryMap = payload;
  },
  setSubSectionProgress: (state, progress) => {
    state.subSectionProgress = progress;
  },
};

const actions = {};

export default {
  namespaced: true,
  state: moduleState,
  getters: moduleGetters,
  mutations,
  actions,
};
