<template>
  <v-form
    v-if="!fetchingData"
    ref="form"
    v-model="formValid"
    @submit.prevent="formSubmit"
  >
    <v-autocomplete
      v-model="form.country"
      :items="countryItems"
      :rules="[(v) => !!v || 'Required']"
      label="Country"
      placeholder="Start typing to search..."
    />
    <v-text-field
      v-model.trim="form.town"
      label="Town"
      :rules="[(v) => !!v || 'Required']"
    />
    <v-text-field
      v-if="form.country === 'GBR'"
      v-model.trim="form.county"
      label="County"
    />
    <v-alert
      v-if="invalidDbsCountry"
      class="mt-8"
      type="warning"
    >
      The selected country is not valid for a DBS application due
      to their list being outdated. Please select an alternative
      value from the list below.
    </v-alert>
    <v-alert
      v-if="invalidDbsTown"
      class="mt-8"
      type="warning"
    >
      Some of the address details don't meet the requirements for a DBS application.
      You can fix them above or provide an alternative value for the application
      (e.g. they don't accept some characters like dots or commas for addresses).
    </v-alert>
    <v-alert
      v-if="invalidDbsCounty"
      class="mt-8"
      type="warning"
    >
      Some of the address details don't meet the requirements for a DBS application.
      You can fix them above or provide an alternative value for the application
      (e.g. they don't accept some characters like dots or commas for addresses).
    </v-alert>
    <v-autocomplete
      v-if="invalidDbsCountry"
      v-model="form.country_dbs"
      :items="ebulkCountryItems"
      :rules="[(v) => !!v || 'Required']"
      label="Birth Country (for DBS)"
      placeholder="Start typing to search..."
    />
    <v-text-field
      v-if="invalidDbsTown"
      v-model.trim="form.town_dbs"
      label="Birth town (for DBS)"
      :rules="dbsPlaceRules"
    />
    <v-text-field
      v-if="invalidDbsCounty"
      v-model.trim="form.county_dbs"
      label="DBS county"
      :rules="dbsPlaceRules"
    />

    <v-row v-show="showConfirmation">
      <v-col>
        <p>Confirm the following:</p>
        <v-checkbox v-model="confirmation.subjectConsent" label="I have consent from the Subject to submit this data" :rules="[(v) => !!v || 'Please confirm']" />
        <v-checkbox v-model="confirmation.understandUpdate" label="I understand this form updates data on this case only, not other cases or the subject record" :rules="[(v) => !!v || 'Please confirm']" />
      </v-col>
    </v-row>

    <v-btn
      type="submit"
      color="primary"
      class="mt-8"
      :disabled="!formValid"
      :loading="loading"
    >
      <v-icon left>
        mdi-content-save
      </v-icon>
      Save
    </v-btn>
  </v-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { dbsPlaceRules } from '@/util/rules';

export default {
  name: 'BasicDetailsBirth',
  props: {
    caseId: {
      type: String,
      required: true,
    },
    ops: {
      type: Boolean,
      default: false,
    },
    isEbulk: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    formValid: false,
    form: {
      country: '',
      country_dbs: '',
      town: '',
      town_dbs: '',
      county: '',
      county_dbs: '',
    },
    fetchingData: true,
    loading: true,
    loadingError: false,
    dbsPlaceRules,
    showConfirmation: false,
    confirmation: {
      subjectConsent: false,
      understandUpdate: false,
    },
  }),
  computed: {
    ...mapGetters('utils', ['countryItems', 'ebulkCountryItems']),
    ...mapState('utils', ['countryMap']),
    invalidDbsCountry() {
      return this.isEbulk && this.form.country && this.countryMap[this.form.country].ebulk_missing;
    },
    invalidDbsTown() {
      if (!this.form.town) {
        return false;
      }
      return dbsPlaceRules.map((func) => func(this.form.town)).some((v) => v !== true);
    },
    invalidDbsCounty() {
      if (!this.form.county) {
        return false;
      }
      return dbsPlaceRules.map((func) => func(this.form.county)).some((v) => v !== true);
    },
    invalidDbsDetails() {
      return this.invalidDbsCountry || this.invalidDbsTown || this.invalidDbsCounty;
    },
    sv() {
      return this.ops
        ? this.$service.product.ops.caseBirthDetails
        : this.$service.product.caseBirthDetails;
    },
  },

  watch: {
    form: {
      handler() {
        if (!this.loading) {
          this.showConfirmation = true;
        }
      },
      deep: true,
    },
  },
  async created() {
    await this.getBirthDetails();
    this.loading = false;
  },
  methods: {
    async getBirthDetails() {
      this.fetchingData = true;
      const resp = await this.sv.retrieve(this.caseId);
      if (resp.status === 200) {
        this.form = resp.data;
      } else {
        this.loadingError = true;
      }
      this.fetchingData = false;
    },
    async formSubmit() {
      this.fetchingData = true;
      const resp = await this.sv.update(this.caseId, this.form);
      if (resp.status === 200) {
        this.form = resp.data;
        this.clearConfirmation();
      } else {
        this.loadingError = true;
      }
      this.fetchingData = false;
    },
    clearConfirmation() {
      this.confirmation = {
        subjectConsent: false,
        understandUpdate: false,
      };
    },
  },
};
</script>
