import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import notifications from './notifications';
import ui from './ui';
import users from './users';
import casePortal from './casePortal';
import utils from './utils';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    ui,
    users,
    auth,
    utils,
    notifications,
    casePortal,
  },
});
