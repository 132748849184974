import { viewsetActions } from '@/service/plugin';

export default {
  portalPayments: viewsetActions('portal-payments'),
  productPayments: viewsetActions('product-payments'),
  productPaymentIntents: viewsetActions('product-payment-intents', {
    extraActions: {
      createExternal: { path: 'external', method: 'post', detail: false },
      deleteExternal: { path: 'external', method: 'delete', detail: true },
      getStripeDetails: { path: 'stripe-details', method: 'get', detail: true },
      fakePayment: { path: 'fake-payment', method: 'post', detail: true },
    },
  }),
};
