import axios from 'axios';

const axiosInstance = axios.create();

const callbacks = {
  success: null,
  error: null,
};

const proccessResponse = (axiosPromise, options) => axiosPromise
  .then((resp) => (callbacks.success ? callbacks.success(resp, options) : resp))
  .catch((err) => (callbacks.error ? callbacks.error(err, options) : err.response));

// TODO: copy&pasted from vue-router (minus the ? at the start), it'd be nice to import it somehow
const encodeReserveRE = /[!'()*]/g;
const encodeReserveReplacer = (c) => `%${c.charCodeAt(0).toString(16)}`;
const commaRE = /%2C/g;

const encode = (str) => encodeURIComponent(str)
  .replace(encodeReserveRE, encodeReserveReplacer)
  .replace(commaRE, ',');

const stringifyQuery = (obj) => {
  const res = obj
    ? Object.keys(obj)
      .map((key) => {
        const val = obj[key];

        if (val === undefined) {
          return '';
        }

        if (val === null) {
          return encode(key);
        }

        if (Array.isArray(val)) {
          const result = [];
          val.forEach((val2) => {
            if (val2 === undefined) {
              return;
            }
            if (val2 === null) {
              result.push(encode(key));
            } else {
              result.push(`${encode(key)}=${encode(val2)}`);
            }
          });
          return result.join('&');
        }

        return `${encode(key)}=${encode(val)}`;
      })
      .filter((x) => x.length > 0)
      .join('&')
    : null;
  return res ? (`${res}`) : '';
};

// ------------------------------------------------------------------------

const request = (method, path, options = {}) => {
  const axiosPromise = axiosInstance.request({
    method,
    url: path,
    params: options.params,
    paramsSerializer: { serialize: stringifyQuery, encode: stringifyQuery },
    data: options.data,
    responseType: options.responseType,
  });
  return proccessResponse(axiosPromise, options);
};

const viewsetActions = (resource, config = {}) => {
  const basePath = config.basePath || '/api/';
  const path = `${basePath}${resource}/`;
  const extraActions = config.extraActions || {};

  const actions = {
    list: (options = {}) => {
      const viewset = { resource, config, action: 'list' };
      return request('get', path, { ...options, viewset });
    },
    retrieve: (pk, options = {}) => {
      const viewset = { resource, config, action: 'retrieve' };
      return request('get', `${path}${pk}/`, { ...options, viewset });
    },
    create: (data, options = {}) => {
      const viewset = { resource, config, action: 'create' };
      return request('post', path, { ...options, viewset, data });
    },
    update: (pk, data, options = {}) => {
      const viewset = { resource, config, action: 'update' };
      return request('put', `${path}${pk}/`, { ...options, viewset, data });
    },
    partialUpdate: (pk, data, options = {}) => {
      const viewset = { resource, config, action: 'partialUpdate' };
      return request('patch', `${path}${pk}/`, { ...options, viewset, data });
    },
    delete: (pk, options = {}) => {
      const viewset = { resource, config, action: 'delete' };
      return request('delete', `${path}${pk}/`, { ...options, viewset });
    },
  };

  Object.keys(extraActions).forEach((name) => {
    const action = extraActions[name];
    const viewset = { resource, config, action: name };
    let actionRequest;
    if (['get', 'delete'].includes(action.method)) {
      if (action.detail) {
        actionRequest = (pk, options) => request(action.method, `${path}${pk}/${action.path}/`, { ...options, viewset });
      } else {
        actionRequest = (options) => request(action.method, `${path}${action.path}/`, { ...options, viewset });
      }
    } else if (['post', 'put', 'patch'].includes(action.method)) {
      if (action.detail) {
        actionRequest = (pk, data, options) => request(action.method, `${path}${pk}/${action.path}/`, { ...options, viewset, data });
      } else {
        actionRequest = (data, options) => request(action.method, `${path}${action.path}/`, { ...options, viewset, data });
      }
    } else {
      throw new Error(`Unrecognised axios ${action.method}`);
    }
    actions[name] = actionRequest;
  });

  return actions;
};

export {
  axiosInstance,
  callbacks,
  request,
  viewsetActions,
};
