import Vue from 'vue';
import router from '@/router';

import settings from '@/settings';

const data = {
  status: localStorage.getItem('status') || 'unknown',
  userType: null,
  isOpsSession: false,
  TotpIsRequired: false,
  lastCheckSession: Date.now(),
};

const authGetters = {
  isAuthenticated: (state) => state.status === 'logged-in',
};

const mutations = {
  authSuccess(state) {
    localStorage.setItem('status', 'logged-in');
    state.status = 'logged-in';
  },
  totpRequired(state, payload) {
    state.TotpIsRequired = payload;
  },
  logoutSuccess(state) {
    localStorage.setItem('status', 'logged-out');
    state.status = 'logged-out';
  },
  authFailure(state) {
    localStorage.setItem('status', 'error');
    state.status = 'error';
  },
  setCsrfToken(state, token) {
    Vue.service.setCommonHeaders({
      'X-CSRFTOKEN': token,
    });
  },
  setUserType(state, userType) {
    state.userType = userType;
  },
  setOpsSession(state) {
    state.isOpsSession = true;
  },
  clearOpsSession(state) {
    state.isOpsSession = false;
  },
  updateLastCheckSession(state) {
    state.lastCheckSession = Date.now();
  },
};

const setMaintenanceWindow = ({ resp, commit, rootState }) => {
  if (resp.data.maintenance_window && resp.data.maintenance_window.start) {
    commit('ui/setMaintenanceWindow', resp.data.maintenance_window, { root: true });
  } else if (rootState.ui.maintenanceWindow.start) {
    commit('ui/clearMaintenanceWindow', {}, { root: true });
  }
};

const setAuthenticated = ({
  resp, state, commit, getters,
}) => {
  if (!getters.isAuthenticated) {
    // Commits auth if state is not authenticated
    commit('authSuccess');
  }
  const sessionCustomer = resp.data.session_customer;
  if (state.isOpsSession && !(sessionCustomer && sessionCustomer.id)) {
    // Clears Ops session state if expired
    commit('clearOpsSession');
    commit('ui/setBootstrapped', false, { root: true });
    router.push({ name: 'bootstrap' });
  } else if (sessionCustomer && sessionCustomer.id && !state.isOpsSession) {
    // Commits Ops session if not already in that state
    commit('setOpsSession');
    commit('ui/setBootstrapped', false, { root: true });
  }
  commit('ui/setActiveFlags', { activeFlags: resp.data.active_flags }, { root: true });
};

const actions = {
  async checkSession({
    commit, getters, state, rootState,
  }, payload) {
    const isDue = Date.now() > state.lastCheckSession + 1000 * settings.SESSION_POLL_INTERVAL;

    if (payload.initial || (isDue && document.visibilityState === 'visible')) {
      commit('updateLastCheckSession');
    } else {
      return null;
    }

    const resp = await Vue.service.public.accounts.checkSession();

    if (resp.status === 200) {
      setMaintenanceWindow({ resp, commit, rootState });

      if (payload.initial) {
        commit('setCsrfToken', resp.data.csrf_token);
        commit('setUserType', resp.data.user_type);
      }

      if (resp.data.authenticated) {
        setAuthenticated({
          resp, state, commit, getters,
        });
      } else if (getters.isAuthenticated) {
        commit('logoutSuccess');
        commit('setCsrfToken', resp.data.csrf_token);
        router.push({ name: 'login' });
      }
    } else if (resp.status === 403) {
      commit('logoutSuccess');
      router.push({ name: 'login' });
    } else if (resp.status === 503) {
      setMaintenanceWindow({ resp, commit, rootState });
    } else {
      commit('authFailure');
    }
    return null;
  },
  async localLogin({ commit, state }, payload) {
    const loginData = {
      username: payload.username,
      password: payload.password,
    };
    if (state.TotpIsRequired) {
      loginData.totp = payload.totp;
    }
    const resp = await Vue.service.public.accounts.login(loginData);
    if (resp.status === 200) {
      commit('authSuccess');
      commit('setCsrfToken', resp.data.csrf_token);
      commit('setUserType', resp.data.user_type);
      router.push(payload.next ? payload.next : { name: 'bootstrap' });
    } else if (resp.status === 400) {
      commit('totpRequired', true);
    } else {
      commit('authFailure');
    }
  },
  async logout({ commit }) {
    // clear cookies & session and go home
    const resp = await Vue.service.public.accounts.logout();
    if (resp.status === 200) {
      commit('logoutSuccess');
      commit('clearOpsSession');
      commit('setCsrfToken', resp.data.csrf_token);
      commit('setUserType', null);
      commit('ui/setBootstrapped', false, { root: true });
      commit('users/cleanProfile', null, { root: true });
      commit('totpRequired', false);
      router.push({ name: 'login' });
    }
  },
  async startOpsSession({ commit }, payload) {
    const resp = await Vue.service.product.ops.customers.startOpsSession(payload.id);
    if (resp.status === 200) {
      commit('setOpsSession');
      commit('ui/setBootstrapped', false, { root: true });
      router.push({ name: 'bootstrap' });
    }
  },
  async endOpsSession({ commit }) {
    const resp = await Vue.service.product.ops.customers.endOpsSession();
    if (resp.status === 200) {
      commit('clearOpsSession');
      commit('ui/setBootstrapped', false, { root: true });
      router.push({ name: 'bootstrap' });
    }
  },
};

export default {
  namespaced: true,
  state: data,
  getters: authGetters,
  actions,
  mutations,
};
