<template>
  <canvas
    ref="canvas"
    class="canvas"
    @click="$emit('click')"
  />
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist/legacy/build/pdf';
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {
  name: 'PdfThumbnail',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data: () => ({}),
  mounted() {
    this.renderThumbnail();
  },
  methods: {
    async renderThumbnail() {
      const loadingTask = pdfjsLib.getDocument(this.src);
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(1);
      const { canvas } = this.$refs;
      const viewport = page.getViewport({
        scale: canvas.clientWidth / page.getViewport({ scale: 1 }).width,
      });

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      await page.render({
        canvasContext: canvas.getContext('2d'),
        viewport,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.canvas {
  width: 100%;
}
</style>
