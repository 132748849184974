<template>
  <div>
    <v-form
      v-if="stage === 'pending'"
      v-model="pendingFormValid"
    >
      <v-select
        v-model="selectedCase"
        :items="retrievedData.dbs_case_options"
        label="DBS Enhanced Case ID"
        :rules="[(v) => Boolean(v)]"
      >
        <template #item="{ item }">
          <span>{{ item.id }} ({{ item.state }})</span>
        </template>
        <template #selection="{ item }">
          <span>{{ item.id }}</span>
        </template>
      </v-select>
      <div v-if="selectedCase">
        <two-line-copy-list-item
          title="Subject Name"
          :subtitle="subjectName"
        />
        <two-line-copy-list-item
          title="Subject Date of Birth"
          :subtitle="selectedCase.basic_details.dob "
        />
        <two-line-copy-list-item
          title="E Reference"
          :subtitle="selectedCase.dbs_ca[0]?.result_data?.application_details?.form_reference"
        />
        <two-line-copy-list-item
          title="Registered Body"
          :subtitle="retrievedData.rb_number"
        />
        <two-line-copy-list-item
          title="Countersignatory Code"
          :subtitle="selectedCase.countersignatory.countersignatory_number"
        />
        <two-line-copy-list-item
          title="Countersignatory Date of Birth"
          :subtitle="selectedCase.countersignatory.dob"
        />
      </div>
      <v-text-field
        v-model="reference"
        label="DBS Adult First Reference"
        :rules="[(v) => Boolean(v)]"
      />
      <v-btn
        class="mt-2"
        :disabled="!pendingFormValid"
        @click="progressToRequested"
      >
        Save reference and progress
      </v-btn>
    </v-form>
    <v-form
      v-if="stage === 'requested'"
      v-model="requestedFormValid"
    >
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>
            E Bulk referance
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ retrievedData.e_number }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <two-line-copy-list-item
        title="Linked Case ID"
        :subtitle="retrievedData.dbs_case"
      />
      <v-select
        v-model="result"
        :items="resultOptions"
        label="Result"
        :rules="[(v) => Boolean(v)]"
      />
      <v-btn
        class="mt-2"
        :disabled="!requestedFormValid"
        @click="progressToResult"
      >
        Save Result and Complete
      </v-btn>
    </v-form>
  </div>
</template>
<script>
import TwoLineCopyListItem from '@/components/common/TwoLineCopyListItem.vue';

export default {
  name: 'DBSAdultFirstOpsDetail',
  components: {
    TwoLineCopyListItem,
  },
  props: {
    caseActivity: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    retrievedData: {
      dbs_case_options: [],
    },
    selectedCase: null,
    reference: '',
    pendingFormValid: false,
    result: null,
    resultOptions: ['No Match', 'Wait for Certificate', 'Certificate Already Issued'],
    requestedFormValid: false,
  }),
  computed: {
    stage() {
      return this.retrievedData?.processing_data?.stage;
    },
    subjectName() {
      if (!this.selectedCase) { return ''; }
      const basicDetails = this.selectedCase.basic_details;
      const forename = (basicDetails.forename_dbs || basicDetails.forename);
      const middlename = (basicDetails.middlename_dbs || basicDetails.middlename);
      const surname = (basicDetails.surname_dbs || basicDetails.surname);
      return middlename ? `${forename} ${middlename} ${surname}` : `${forename} ${surname}`;
    },
  },
  async mounted() {
    const resp = await this.$service.product.ops.caseActivities.opsServicedStageRetrieve(
      this.caseActivity.id,
    );
    if (resp.status === 200) {
      this.retrievedData = resp.data;
    }
  },
  methods: {
    async progressToRequested() {
      if (!this.pendingFormValid) { throw new Error('Pending Form Invalid'); }
      const resp = await this.$service.product.ops.caseActivities.opsServicedStageProgress(
        this.caseActivity.id,
        {
          stage: 'requested',
          data: {
            dbs_case: this.selectedCase.id,
            reference: this.reference,
            e_number: this.selectedCase.dbs_ca[0]?.result_data?.application_details?.form_reference,
          },
        },
      );
      if (resp.status === 200) {
        this.$router.go();
      }
    },
    async progressToResult() {
      if (!this.requestedFormValid) { throw new Error('Requested Form Invalid'); }
      const resp = await this.$service.product.ops.caseActivities.opsServicedStageProgress(
        this.caseActivity.id,
        {
          stage: 'result',
          data: {
            result: this.result,
          },
        },
      );
      if (resp.status === 200) {
        this.$router.push({ name: 'ops-outstanding-activity-list' });
      }
    },
  },
};
</script>
