import { viewsetActions, request } from '@/service/plugin';

const extraActions = {
  activate: { path: 'activate', method: 'post', detail: true },
  deactivate: { path: 'deactivate', method: 'post', detail: true },
  startOpsSession: { path: 'start-ops-session', method: 'post', detail: true },
  endOpsSession: { path: 'end-ops-session', method: 'post', detail: false },
  inactive_entitlement_choices: { path: 'inactive-entitlement-choices', method: 'get', detail: true },
};
const customers = viewsetActions('customers', { basePath: '/api/ops/', extraActions });

const extraUserActions = {
  activate: { path: 'activate', method: 'post', detail: true },
  deactivate: { path: 'deactivate', method: 'post', detail: true },
  resetPassword: { path: 'reset-password', method: 'post', detail: true },
};

const users = viewsetActions('users', { basePath: '/api/ops/', extraActions: extraUserActions });

const activityTypes = {
  list: (options) => request('get', '/api/ops/activity-types/', options),
  failedStats: (options) => request('get', '/api/ops/activity-types/error_stats', options),
};

export default {
  customers,
  users,
  activityTypes,
  customerSettings: viewsetActions('customer-settings', { basePath: '/api/ops/' }),
  customerServiceSettings: viewsetActions('customer-service-settings', { basePath: '/api/ops/' }),
  customerPaymentSettings: viewsetActions('customer-payment-settings', { basePath: '/api/ops/' }),
  entitlements: viewsetActions('entitlements', { basePath: '/api/ops/' }),
  segments: viewsetActions('segments', { basePath: '/api/ops/' }),
  processes: { list: (options) => request('get', '/api/ops/processes/', options) },
  products: { list: (options) => request('get', '/api/ops/products/', options) },
  apiCalls: viewsetActions('service-api-calls', { basePath: '/api/ops/' }),
};
