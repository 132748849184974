export default [
  {
    path: '/subjects',
    component: () => import(
      /* webpackChunkName: "subjects" */
      '@/pages/product/agents/Subjects.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "subjects" */
          '@/components/product/subjects/List.vue'
        ),
        name: 'subject-list',
        meta: {
          permissions: ['subjects.view_subject'],
        },
      },
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "subjects" */
          '@/components/product/subjects/Detail.vue'
        ),
        props: true,
        children: [
          {
            path: '',
            component: () => import(
              /* webpackChunkName: "subjects" */
              '@/components/product/subjects/cases/List.vue'
            ),
            name: 'subject-detail',
            meta: {
              permissions: ['subjects.view_subject'],
            },
          },
          {
            path: 'subscriptions/',
            component: () => import(
              /* webpackChunkName: "subjects" */
              '@/components/product/subjects/subscriptions/List.vue'
            ),
            name: 'subject-subscription-list',
            meta: {
              permissions: ['processes.view_subscription'],
              entitlements: ['cycles'],
            },
          },
          {
            path: 'subscriptions/add',
            component: () => import(
              /* webpackChunkName: "subjects" */
              '@/components/product/subjects/subscriptions/Add.vue'
            ),
            name: 'subject-subscription-add',
            meta: {
              permissions: ['processes.add_subscription'],
              entitlements: ['cycles'],
            },
          },
          {
            path: 'registrations/',
            component: () => import(
              /* webpackChunkName: "subjects" */
              '@/components/product/subjects/registrations/List.vue'
            ),
            name: 'subject-registration-list',
            meta: {
              permissions: ['payments.view_portalpayment'],
              entitlements: ['regportals'],
            },
          },
          {
            path: 'consents/',
            component: () => import(
              /* webpackChunkName: "subjects" */
              '@/components/product/subjects/activity_consents/List.vue'
            ),
            name: 'subject-activity-consent-list',
            meta: {
              permissions: ['subjects.view_subjectactivityconsent'],
            },
          },
        ],
      },
      {
        path: '/portal-registrations',
        component: () => import(
          '@/pages/product/agents/Registrations.vue'
        ),
        children: [
          {
            path: ':id',
            component: () => import(
              /* webpackChunkName: "subjects" */
              '@/components/product/registrations/Detail.vue'
            ),
            props: true,
            name: 'registration-detail',
            meta: {
              permissions: ['payments.view_portalpayment'],
              entitlements: ['regportals'],
            },
          },
        ],
      },
    ],
  },
];
