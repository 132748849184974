<template>
  <v-form
    ref="form"
    v-model="formValid"
    class="mt-4"
    @submit.prevent="formSubmit"
  >
    <p
      v-if="current"
      class="mb-8"
    >
      Please enter your current address:
    </p>
    <v-autocomplete
      v-model="form.country"
      :items="countryItems"
      :rules="[(v) => !!v || 'Required']"
      label="Country"
      placeholder="Start typing to search..."
    />
    <template v-if="form.source === 'OS'">
      <v-row>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-text-field
            v-model.trim="form.postcode"
            label="Postcode lookup"
            :rules="postcodeLookupRules"
            @keyup="uppercase"
          >
            <template #append-outer>
              <v-btn
                color="primary"
                :disabled="!formValid || form.postcode === osDatahub.currentLookup"
                small
                :loading="osDatahub.loading"
                @click="findAddress"
              >
                <v-icon left>
                  mdi-home-search
                </v-icon>
                Find
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <template v-if="osDatahub.lookupResults.length && form.postcode === osDatahub.currentLookup">
        <v-autocomplete
          v-model="matchedAddress"
          :items="osDatahub.lookupResults"
          item-text="ADDRESS"
          item-value="UPRN"
          return-object
          :rules="[(v) => !!v || 'Required']"
          label="Address"
          placeholder="Start typing to search..."
        />
        <a
          v-if="!matchedAddress"
          class="mt-8"
          @click="form.source = 'manual'"
        >Can't find your address?</a>
      </template>
    </template>
    <template v-if="form.source === 'manual' || matchedAddress">
      <v-text-field
        v-model.trim="form.line_one"
        label="Line one"
        :rules="[(v) => !!v || 'Required']"
      />
      <v-text-field
        v-model.trim="form.line_two"
        label="Line two"
      />
      <v-text-field
        v-model.trim="form.town"
        label="Town"
        :rules="[(v) => !!v || 'Required']"
      />
      <v-text-field
        v-model.trim="form.postcode"
        label="Postcode"
        :rules="form.country === 'GBR' ? dbsPostcodeRules : dbsOptionalPostcodeRules"
        @keyup="uppercase"
      />
      <DateSelector
        v-model="form.from_date"
        prepend-label="From"
      />
      <DateSelector
        v-if="!current"
        v-model="form.to_date"
        prepend-label="To"
      />
      <v-alert
        v-if="toDateError"
        type="error"
      >
        To date cannot be before from date.
      </v-alert>
      <v-alert
        v-if="invalidDbsDetails"
        class="mt-8"
        type="warning"
      >
        Some of the address details don't meet the requirements for a DBS application. You can fix
        them above or provide an alternative value for the application (e.g. they don't accept some
        characters like dots or commas for addresses).
      </v-alert>
      <v-autocomplete
        v-if="invalidDbsCountry"
        v-model="form.country_dbs"
        :items="ebulkCountryItems"
        :rules="[(v) => !!v || 'Required']"
        label="DBS country"
        placeholder="Start typing to search..."
      />
      <v-text-field
        v-if="invalidDbsLineOne"
        v-model.trim="form.line_one_dbs"
        label="Address line one (for DBS)"
        :placeholder="form.line_one"
        :rules="dbsAddressLineRules"
        persistent-placeholder
      />
      <v-text-field
        v-if="invalidDbsLineTwo"
        v-model.trim="form.line_two_dbs"
        label="Address line two (for DBS)"
        :placeholder="form.line_two"
        :rules="dbsAddressLineRules"
        persistent-placeholder
      />
      <v-text-field
        v-if="invalidDbsTown"
        v-model.trim="form.town_dbs"
        label="Address town (for DBS)"
        :placeholder="form.town"
        :rules="dbsPlaceRules"
        persistent-placeholder
      />
    </template>
    <div class="mt-16">
      <v-btn
        small
        color="success"
        :disabled="!formValid || toDateError"
        @click="addAddress"
      >
        <v-icon left>
          mdi-content-save
        </v-icon>
        Save
      </v-btn>
      <v-btn
        v-if="!current"
        small
        class="ml-2"
        color="error"
        @click="$emit('cancel')"
      >
        <v-icon left>
          mdi-cancel
        </v-icon>
        Cancel
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapState } from 'vuex';

import {
  dbsAddressLineRules,
  dbsOptionalAddressLineRules,
  dbsPlaceRules,
  dbsPostcodeRules,
  dbsOptionalPostcodeRules,
} from '@/util/rules';
import DateSelector from '@/components/common/DateSelector.vue';
import { parseOsDataHubAddress } from '@/util/osDataHub';

export default {
  name: 'AddressHistoryForm',
  components: {
    DateSelector,
  },
  props: {
    current: {
      type: Boolean,
      default: false,
    },
    fromDate: {
      type: Date,
      default: null,
    },
    toDate: {
      type: Date,
      default: null,
    },
    ops: {
      type: Boolean,
      default: false,
    },
    isEbulk: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      country: '',
      line_one: '',
      line_two: '',
      town: '',
      postcode: '',
      from_date: null,
      to_date: null,
      metadata: {},
      country_dbs: '',
      line_one_dbs: '',
      line_two_dbs: '',
      town_dbs: '',
      source: '',
    },
    matchedAddress: null,
    formValid: false,
    osDatahub: {
      loading: false,
      token: null,
      tokenExpiry: null,
      currentLookup: null,
      lookupResults: [],
    },
    dbsAddressLineRules,
    dbsOptionalAddressLineRules,
    dbsPlaceRules,
    dbsPostcodeRules,
    dbsOptionalPostcodeRules,
  }),
  computed: {
    ...mapGetters('utils', ['countryItems', 'ebulkCountryItems']),
    ...mapState('utils', ['countryMap']),
    ...mapState('users', ['profile']),
    postcodeLookupRules() {
      return [(v) => v.length > 4 || 'Min 5 characters', ...dbsOptionalPostcodeRules];
    },
    invalidDbsCountry() {
      return this.isEbulk && this.form.country && this.countryMap[this.form.country].ebulk_missing;
    },
    invalidDbsLineOne() {
      if (!this.form.line_one) {
        return false;
      }
      return dbsAddressLineRules.map((func) => func(this.form.line_one)).some((v) => v !== true);
    },
    invalidDbsLineTwo() {
      if (!this.form.line_two) {
        return false;
      }
      return dbsOptionalAddressLineRules.map(
        (func) => func(this.form.line_two),
      ).some((v) => v !== true);
    },
    invalidDbsTown() {
      if (!this.form.town) {
        return false;
      }
      return dbsPlaceRules.map((func) => func(this.form.town)).some((v) => v !== true);
    },
    invalidDbsDetails() {
      return (
        this.invalidDbsCountry
        || this.invalidDbsLineOne
        || this.invalidDbsLineTwo
        || this.invalidDbsTown
      );
    },
    toDateError() {
      return this.form.from_date && this.form.to_date && this.form.to_date < this.form.from_date;
    },
    sv() {
      return this.ops
        ? this.$service.product.ops.caseAddresses
        : this.$service.product.caseAddresses;
    },
  },
  watch: {
    'form.country': function countryChange(val) {
      this.form.source = val === 'GBR' ? 'OS' : 'manual';
    },
    matchedAddress(val) {
      Object.assign(this.form, parseOsDataHubAddress(val));
      this.$nextTick(() => {
        this.$refs.form.validate();
      });
    },
  },
  created() {
    this.form.from_date = this.fromDate;
    this.form.to_date = this.toDate;
  },
  methods: {
    uppercase() {
      this.form.postcode = this.form.postcode.toUpperCase();
    },
    addAddress() {
      this.$emit('save', { ...this.form, current: this.current });
    },
    async osLookup() {
      const token = await this.sv.osDatahubToken();
      try {
        const resp = await axios.get('https://api.os.uk/search/match/v1/match', {
          params: { query: this.form.postcode },
          headers: { Authorization: `Bearer ${token}` },
        });
        this.osDatahub.lookupResults = resp.data.results.map((r) => r.DPA);
        this.osDatahub.currentLookup = this.form.postcode;
      } catch (err) {
        this.$store.commit('notifications/notify', { text: 'An error has occurred', type: 'error' });
      }
    },
    async osFakeLookup() {
      const resp = await this.sv.osFakeLookup(
        { params: { postcode: this.form.postcode } },
      );
      if (resp.status === 200) {
        this.osDatahub.lookupResults = resp.data;
      }
      this.osDatahub.currentLookup = this.form.postcode;
    },
    async findAddress() {
      this.osDatahub.loading = true;
      const useFakeLookup = this.profile.customer.demo_mode && !this.ops;
      const lookupMethod = useFakeLookup ? this.osFakeLookup : this.osLookup;
      await lookupMethod();

      this.osDatahub.loading = false;
    },
  },
};
</script>
