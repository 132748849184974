<template>
  <v-form
    v-if="!fetchingData"
    v-model="formValid"
    @submit.prevent="formSubmit"
  >
    <v-row>
      <v-col
        cols="12"
        lg="4"
      >
        <v-text-field
          v-model.trim="form.forename"
          label="Forename"
          :rules="[(v) => !!v || 'Required']"
          :disabled="form.online_idv"
        />
      </v-col>
      <v-col
        cols="12"
        lg="4"
      >
        <v-text-field
          v-model.trim="form.middlename"
          placeholder="Leave blank if you don't have one"
          label="Middle name(s)"
          :disabled="form.online_idv"
        />
      </v-col>
      <v-col
        cols="12"
        lg="4"
      >
        <v-text-field
          v-model.trim="form.surname"
          label="Surname"
          :rules="[(v) => !!v || 'Required']"
          :disabled="form.online_idv"
        />
      </v-col>
    </v-row>
    <DateSelector
      v-model="form.dob"
      append-label="of birth"
      :disabled="form.online_idv"
    />
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="form.gender"
          :items="genderItems"
          :rules="[(v) => !!v || 'Required']"
          hint="Select the gender as stated on the subjects birth certificate"
          persistent-hint
          label="Gender"
        />
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          v-model="form.title"
          :items="titleItems"
          :rules="[(v) => !!v || 'Required']"
          label="Title"
          placeholder="Start typing to search..."
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          v-model.trim="form.email"
          label="Email"
          :rules="[(v) => !!v || 'Required', (v) => /.+@.+\..+/.test(v) || 'Invalid email']"
          disabled
        />
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <vue-tel-input-vuetify
          ref="phone"
          v-model="form.phone_number"
          label="Phone number"
          :rules="[(v) => !!v || 'Required', () => validPhone || 'Invalid number']"
          default-country="GB"
          disabled-fetching-country
          mode="international"
          @validate="validatePhone"
        />
      </v-col>
    </v-row>
    <v-autocomplete
      v-model="form.nationality"
      :rules="[(v) => !!v || 'Required']"
      :items="nationalityItems"
      label="Nationality"
      placeholder="Start typing to search..."
    />
    <v-text-field
      v-model="form.nin"
      label="National Insurance number"
      :rules="optionalNinRules"
      @keyup="() => form.nin = form.nin.toUpperCase()"
    />
    <v-alert
      v-if="invalidDbsDetails"
      class="mt-8"
      type="warning"
    >
      The name you have provided does not meet the requirements for a DBS,
      for example, the DBS do not accept accented letters or punctuation.
      Please amend your name above or enter an alternative value for the application
    </v-alert>
    <v-row>
      <v-col
        v-if="invalidDbsForename"
        cols="12"
        lg="4"
      >
        <v-text-field
          v-model.trim="form.forename_dbs"
          label="Forename (for DBS)"
          :rules="dbsNameRules"
        />
      </v-col>
      <v-col
        v-if="invalidDbsMiddlename"
        cols="12"
        lg="4"
      >
        <v-text-field
          v-model.trim="form.middlename_dbs"
          placeholder="Leave blank if you don't have one"
          label="Middle name(s) (for DBS)"
          :rules="dbsNameRules"
        />
      </v-col>
      <v-col
        v-if="invalidDbsSurname"
        cols="12"
        lg="4"
      >
        <v-text-field
          v-model.trim="form.surname_dbs"
          label="Surname (for DBS)"
          :rules="dbsSurnameRules"
        />
      </v-col>
    </v-row>
    <v-row v-show="showConfirmation">
      <v-col>
        <p>Confirm the following:</p>
        <v-checkbox v-model="confirmation.subjectConsent" label="I have consent from the Subject to submit this data" :rules="[(v) => !!v || 'Please confirm']" />
        <v-checkbox v-model="confirmation.understandUpdate" label="I understand this form updates data on this case only, not other cases or the subject record" :rules="[(v) => !!v || 'Please confirm']" />
      </v-col>
    </v-row>
    <v-btn
      type="submit"
      color="primary"
      class="mt-8"
      :disabled="!formValid"
      :loading="loading"
    >
      <v-icon left>
        mdi-content-save
      </v-icon>
      Save
    </v-btn>
  </v-form>
</template>
<script>
import { mapGetters } from 'vuex';
import DateSelector from '@/components/common/DateSelector.vue';
import {
  genderItems, titleItems, maleTitleItems, femaleTitleItems,
} from '@/util/choices';
import { dbsNameRules, dbsSurnameRules, optionalNinRules } from '@/util/rules';

export default {
  name: 'EditCaseBasicDetail',
  components: {
    DateSelector,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    ops: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    formValid: false,
    form: {
      gender: '',
      title: '',
      forename: '',
      forename_dbs: '',
      middlename: '',
      middlename_dbs: '',
      surname: '',
      surname_dbs: '',
      email: '',
      email_dbs: '',
      dob: null,
      phone_number: '',
      nin: '',
      nationality: '',
      online_idv: false,
    },
    validPhone: false,
    genderItems,
    dbsNameRules,
    dbsSurnameRules,
    optionalNinRules,
    fetchingData: false,
    loading: true,
    loadingError: false,
    showConfirmation: false,
    confirmation: {
      subjectConsent: false,
      understandUpdate: false,
    },
  }),

  computed: {
    ...mapGetters('utils', ['nationalityItems']),
    titleItems() {
      const genderTitleMap = { male: maleTitleItems, female: femaleTitleItems };
      const { gender } = this.form;
      const items = gender in genderTitleMap ? genderTitleMap[gender] : titleItems;
      return items;
    },
    invalidDbsForename() {
      if (this.form.forename) {
        return dbsNameRules.map((func) => func(this.form.forename)).some((v) => v !== true);
      }
      return false;
    },
    invalidDbsMiddlename() {
      if (this.form.middlename) {
        return dbsNameRules.map((func) => func(this.form.middlename)).some((v) => v !== true);
      }
      return false;
    },
    invalidDbsSurname() {
      if (this.form.surname) {
        return dbsSurnameRules.map((func) => func(this.form.surname)).some((v) => v !== true);
      }
      return false;
    },
    invalidDbsDetails() {
      return this.invalidDbsForename || this.invalidDbsMiddlename || this.invalidDbsSurname;
    },
    sv() {
      return this.ops
        ? this.$service.product.ops.caseBasicDetails
        : this.$service.product.caseBasicDetails;
    },
  },

  watch: {
    form: {
      handler() {
        if (!this.loading) {
          this.showConfirmation = true;
        }
      },
      deep: true,
    },
  },

  async mounted() {
    await this.getBasicDetails();
    this.loading = false;
  },

  methods: {
    async getBasicDetails() {
      this.fetchingData = true;
      const resp = await this.sv.retrieve(this.caseId);
      if (resp.status === 200) {
        this.form = resp.data;
      } else {
        this.loadingError = true;
      }
      this.fetchingData = false;
    },
    validatePhone(val) {
      this.validPhone = val.isValid;
      if (val.number.input) {
        this.$refs.phone.$refs.input.validate();
      }
    },
    async formSubmit() {
      this.fetchingData = true;
      const resp = await this.sv.update(this.caseId, this.form);
      if (resp.status === 200) {
        this.form = resp.data;
        this.clearConfirmation();
      } else {
        this.loadingError = true;
      }
      this.fetchingData = false;
    },
    clearConfirmation() {
      this.confirmation = {
        subjectConsent: false,
        understandUpdate: false,
      };
    },
  },
};
</script>
