<template>
  <v-app
    :style="{ fontFamily: productFont }"
  >
    <v-app-bar
      app
      flat
      dense
      clipped-left
    >
      <router-link to="/">
        <img
          :src="logoPath"
          height="20px"
        >
      </router-link>
    </v-app-bar>

    <v-main>
      <v-container>
        <h2 class="mt-8 mb-4">
          The site is currently down for maintenance.
        </h2>
        <p>We will be back soon. Thank you for your patience.</p>
      </v-container>
    </v-main>

    <v-footer
      app
    >
      <v-row>
        <v-col>
          <span class="body-2">&copy; {{ new Date().getFullYear() }}</span>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Maintenance',
  computed: {
    ...mapState('ui', ['product']),
    productFont() {
      if (this.product.font) {
        return this.product.font;
      }
      return "'Century Gothic'";
    },
    logoPath() {
      return `${this.product.logo}`;
    },
  },
};
</script>
