const tpDomain = process.env.VUE_APP_PRODUCT_DOMAIN_TP;
const pcDomain = process.env.VUE_APP_PRODUCT_DOMAIN_PC;
const defaultDomain = process.env.VUE_APP_PRODUCT_DOMAIN_DEFAULT || 'unknown';
const tpStripeKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY_TP;
const pcStripeKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY_PC;

const products = {
  unknown: {
    name: 'Unknown Product',
  },
};

products[tpDomain] = {
  name: 'TaxiPlus',
  publicContactEmail: 'hello@taxiplus.co.uk',
  contactEmail: 'hello@taxiplus.co.uk',
  contactNumber: '01254 355696',
  supportNumber: '01254 355679',
  publicDomain: 'www.taxiplus.co.uk',
  productTerms: 'https://www.taxiplus.co.uk/product-terms-of-use',
  productPrivacy: 'https://www.taxiplus.co.uk/privacy-policy',
  subjectTerms: 'https://www.taxiplus.co.uk/enduser-licence-agreement',
  subjectPrivacy: 'https://www.taxiplus.co.uk/subject-privacy-policy',
  knowledgeBase: 'https://help.taxiplus.co.uk/support/home',
  logo: '/TaxiPlus/taxiplus-48px-horizontal.png',
  favicon: '/TaxiPlus/favicon/favicon.ico',
  font: "'Source Sans Pro', sans-serif",
  themes: {
    light: {
      primary: '#1A9696',
    },
    dark: {
      primary: '#1A9696',
    },
  },
  stripeKey: tpStripeKey,
};

products[pcDomain] = {
  name: 'Personnel Checks',
  publicContactEmail: 'applicants@personnelchecks.co.uk',
  contactEmail: 'letstalk@personnelchecks.co.uk',
  contactNumber: '01254 355688',
  supportNumber: '01254 355651',
  publicDomain: 'www.personnelchecks.co.uk',
  productTerms: 'https://www.personnelchecks.co.uk/terms-and-conditions',
  productPrivacy: 'https://www.personnelchecks.co.uk/privacy-policy',
  subjectTerms: 'https://www.personnelchecks.co.uk/enduser-licence-agreement',
  subjectPrivacy: 'https://www.personnelchecks.co.uk/subject-privacy-policy',
  knowledgeBase: 'https://help.personnelchecks.co.uk/support/home',
  logo: '/PersonnelChecks/pc-logo-blue.png',
  favicon: '/PersonnelChecks/favicon/favicon.ico',
  font: "'Source Sans Pro', sans-serif",
  themes: {
    light: {
      primary: '#f47920',
    },
    dark: {
      primary: '#f47920',
    },
  },
  stripeKey: pcStripeKey,
};

const getProduct = (hostname) => {
  const domain = hostname in products ? hostname : defaultDomain;
  return products[domain];
};

export default getProduct;
