<template>
  <v-row>
    <v-col>
      <span v-if="(getPurpose() != false)">
        <p>
          <span class="body-2 text--secondary">Purpose: </span>
          <span>{{ caseActivityDetails.config.purpose }}</span>
          <br>
          <span v-if="getPurpose().includes('Employment')">
            <span class="body-2 text--secondary">Employment Sector: </span>
            <span>{{ caseActivityDetails.config.employment_sector }}</span>
            <br>
            <span class="body-2 text--secondary">Employer Name: </span>
            <span>{{ caseActivityDetails.config.employer_name }}</span>
            <br>
            <span class="body-2 text--secondary">Position: </span>
            <span>{{ caseActivityDetails.config.position }}</span>
            <br>
          </span>
        </p>
      </span>
      <span v-else>
        <p>
          <span class="body-2 text--secondary">Other Purpose: </span>
          <span>{{ caseActivityDetails.config.other_purpose }}</span>
          <br>
        </p>
      </span>
    </v-col>
    <v-col>
      <span class="body-2 text--secondary">Online Certificate Email: </span>
      <span>{{ caseActivityDetails.config.online_cert_email }}</span>
      <span v-if="getPaperCert">
        <br>
        <span class="body-2 text--secondary">Paper Certificate: </span>
        <span>{{ caseActivityDetails.config.paper_cert }}</span>
      </span>
      <span v-else>
        <br>
        <span class="body-2 text--secondary">Paper Certificate: </span>
        <span>No</span>
      </span>
    </v-col>
    <v-col>
      <span v-if="caseActivityDetails.config.paper_cert.includes('company')">
        <span class="body-2 text--secondary">Paper Certificate: </span>
        <span>{{ caseActivityDetails.config.paper_cert }}</span>
        <br>
        <span class="body-2 text--secondary">Paper Certificate Name: </span>
        <span>{{ caseActivityDetails.config.paper_cert_name }}</span>
        <br>
        <span class="body-2 text--secondary">Paper Certificate Department: </span>
        <span>{{ caseActivityDetails.config.paper_cert_department }}</span>
        <br>
        <span class="body-2 text--secondary">Paper Certificate Line One: </span>
        <span>{{ caseActivityDetails.config.paper_cert_line_one }}</span>
        <br>
        <span class="body-2 text--secondary">Paper Certificate Line Two: </span>
        <span>{{ caseActivityDetails.config.paper_cert_line_two }}</span>
        <br>
        <span class="body-2 text--secondary">Paper Certificate Town: </span>
        <span>{{ caseActivityDetails.config.paper_cert_town }}</span>
        <br>
        <span class="body-2 text--secondary">Paper Certificate Postcode: </span>
        <span>{{ caseActivityDetails.config.paper_cert_postcode }}</span>
      </span>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'RequirementConfig',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getPurpose() {
      if (this.caseActivityDetails.config.other_purpose !== '') {
        return this.caseActivityDetails.config.other_purpose;
      }
      if (this.caseActivityDetails.config.purpose !== '') {
        return this.caseActivityDetails.config.purpose;
      }
      return false;
    },
    getPaperCert() {
      if (this.caseActivityDetails.config.paper_cert.includes('')) {
        return false;
      }
      return true;
    },
  },
};
</script>
