<template>
  <div>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title>
          {{ title }}
          <v-icon
            left
            small
            @click="copyValue('name')"
          >
            mdi-content-copy
          </v-icon>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ subtitle }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>
<script>
export default {
  name: 'TwoLineCopyItem',
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    copyFrom: {
      type: String,
      default: 'subtitle',
      validator(value) {
        return ['title', 'subtitle'].includes(value);
      },
    },
  },
  methods: {
    copyValue() {
      navigator.clipboard.writeText(this[this.copyFrom])
        .then(() => {
          this.$store.commit('notifications/notify', { text: 'Copied', type: 'success' });
        })
        .catch(() => {
          this.$store.commit('notifications/notify', { text: 'Error copying', type: 'error' });
        });
    },
  },
};
</script>
