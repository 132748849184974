<template>
  <div>
    <v-data-table
      :headers="tableHeaders"
      :items="otherNames"
      hide-default-footer
      disable-pagination
      class="mt-12 mb-16"
    >
      <template #item.name="{ item }">
        {{ getFullName(item) }}
      </template>
      <template #item.actions="{ item }">
        <v-btn
          color="error"
          small
          @click="removeOtherName(item.id)"
        >
          <v-icon left>
            mdi-trash-can-outline
          </v-icon>
          Remove
        </v-btn>
      </template>
      <template #no-data>
        No data yet.
      </template>
      <template #footer>
        <v-btn
          v-if="!showForm"
          color="success"
          class="mt-4 float-right"
          small
          @click="showForm = true"
        >
          <v-icon left>
            mdi-plus
          </v-icon>
          Add
        </v-btn>
      </template>
    </v-data-table>

    <v-form
      v-if="showForm"
      v-model="formValid"
      @submit.prevent="formSubmit"
    >
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model.trim="form.forename"
            label="Forename"
            :rules="[(v) => !!v || 'Required']"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model.trim="form.middlename"
            label="Middle name(s)"
          />
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model.trim="form.surname"
            label="Surname"
            :rules="[(v) => !!v || 'Required']"
          />
        </v-col>
      </v-row>
      <v-alert
        v-if="invalidDbsDetails"
        class="mt-8"
        type="warning"
      >
        The name you have provided does not meet the requirements for a DBS, for example,
        the DBS fo not accept accented letters or punctuation.
        Please amend your name above or enter an alternative value for the application
      </v-alert>
      <v-row>
        <v-col
          v-if="invalidDbsForename"
          cols="12"
          lg="4"
        >
          <v-text-field
            v-model.trim="form.forename_dbs"
            label="Forename (for DBS)"
            :rules="dbsNameRules"
          />
        </v-col>
        <v-col
          v-if="invalidDbsMiddlename"
          cols="12"
          lg="4"
        >
          <v-text-field
            v-model.trim="form.middlename_dbs"
            placeholder="Leave blank if you don't have one"
            label="Middle name(s) (for DBS)"
            :rules="dbsNameRules"
          />
        </v-col>
        <v-col
          v-if="invalidDbsSurname"
          cols="12"
          lg="4"
        >
          <v-text-field
            v-model.trim="form.surname_dbs"
            label="Surname (for DBS)"
            :rules="dbsSurnameRules"
          />
        </v-col>
      </v-row>
      <DateSelector
        v-model="form.from_date"
        prepend-label="From"
      />
      <DateSelector
        v-model="form.to_date"
        prepend-label="To"
      />
      <v-alert
        v-if="toDateError"
        type="error"
      >
        To date cannot be before from date.
      </v-alert>
      <v-btn
        class="mt-4"
        small
        color="success"
        :disabled="!formValid || toDateError"
        @click="addOtherName"
      >
        <v-icon left>
          mdi-content-save
        </v-icon>
        Save
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import DateSelector from '@/components/common/DateSelector.vue';
import { dbsNameRules, dbsSurnameRules } from '@/util/rules';

export default {
  name: 'EditCaseOtherNames',
  components: {
    DateSelector,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    ops: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    form: {
      forename: '',
      middlename: '',
      surname: '',
      from_date: null,
      to_date: null,
    },
    formValid: false,
    showForm: false,
    otherNames: [],
    tableHeaders: [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'From',
        value: 'from_date',
      },
      {
        text: 'To',
        value: 'to_date',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ],
    dbsNameRules,
    dbsSurnameRules,
  }),
  computed: {
    toDateError() {
      return this.form.from_date && this.form.to_date && this.form.to_date < this.form.from_date;
    },
    invalidDbsForename() {
      if (!(this.form.forename)) {
        return false;
      }
      return dbsNameRules.map((func) => func(this.form.forename)).some((v) => v !== true);
    },
    invalidDbsMiddlename() {
      if (!(this.form.middlename)) {
        return false;
      }
      return dbsNameRules.map((func) => func(this.form.middlename)).some((v) => v !== true);
    },
    invalidDbsSurname() {
      if (!(this.form.surname)) {
        return false;
      }
      return dbsSurnameRules.map((func) => func(this.form.surname)).some((v) => v !== true);
    },
    invalidDbsDetails() {
      return this.invalidDbsForename || this.invalidDbsMiddlename || this.invalidDbsSurname;
    },
    sv() {
      return this.ops
        ? this.$service.product.ops.caseOtherNames
        : this.$service.product.caseOtherNames;
    },
  },
  created() {
    this.getOtherNames();
  },
  methods: {
    async getOtherNames() {
      const resp = await this.sv.list({ params: { case: this.caseId } });
      this.otherNames = resp.data;
    },
    getFullName(item) {
      if (item.middlename) {
        return [item.forename, item.middlename, item.surname].join(' ');
      }
      return [item.forename, item.surname].join(' ');
    },
    async addOtherName() {
      const resp = await this.sv.create(
        { ...this.form, case: this.caseId },
      );
      if (resp.status === 201) {
        this.otherNames.push({ ...this.form, id: resp.data.id });
        this.showForm = false;
        this.form = {};
      }
    },
    async removeOtherName(itemId) {
      const resp = await this.sv.delete(itemId);
      if (resp.status === 204) {
        const index = this.otherNames.findIndex((item) => item.id === itemId);
        this.otherNames.splice(index, 1);
      }
    },
  },
};
</script>
