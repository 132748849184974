<template>
  <div v-if="caseActivityDetails.id">
    <v-row>
      <v-col
        v-if="hasApplicationData"
      >
        <span
          class="overline"
        >
          Application Details
        </span>
        <p>
          <span class="body-2 text--secondary">E-Bulk Reference:</span>
          <span> {{ applicationData['Application ID'] }}</span>
          <br>
          <span class="body-2 text--secondary">Status:</span>
          <span> {{ applicationData['Status'] }}</span>
          <br>
          <span class="body-2 text--secondary">Name:</span>
          <span> {{ applicationData['Name'] }}</span>
          <br>
          <span class="body-2 text--secondary">Date of Birth:</span>
          <span> {{ applicationData['Date of Birth'] }}</span>
          <br>
          <span class="body-2 text--secondary">Current address:</span>
          <span> {{ applicationData['Current address'].trim() }}</span>
        </p>
      </v-col>
      <v-divider
        vertical
        inset
      />
      <v-col>
        <span class="overline">Receipt Data</span>
        <template
          v-if="hasReceipts"
        >
          <p>
            <span class="body-2 text--secondary">Status</span>
            <span> {{ receiptData['Status'] }}</span>
            <br>
            <span class="body-2 text--secondary">DBS Reference</span>
            <span> {{ receiptData['DBS Reference'] }}</span>
            <br>
            <span class="body-2 text--secondary">Receipt Timestamp</span>
            <span> {{ receiptData['Timestamp'] }}</span>
          </p>
        </template>
        <template
          v-else
        >
          <p>
            <span class="font-italic text--secondary">DBS Receipts not yet available</span>
          </p>
        </template>
      </v-col>
      <v-divider
        vertical
        inset
      />
      <v-col>
        <span class="overline">Result Data</span>

        <template
          v-if="hasResults"
        >
          <p>
            <span class="body-2 text--secondary">Disclosure Status</span><br>
            <span class="font-weight-bold">{{ resultData['Disclosure Status'] }}</span>
          </p>
          <p>
            <span class="body-2 text--secondary">Disclosure Number</span>
            <span> {{ resultData['Disclosure Number'] }}</span>
            <br>
            <span class="body-2 text--secondary">Disclosure Issue Date</span>
            <span> {{ resultData['Disclosure Issue Date'] }}</span>
            <br>
            <span class="body-2 text--secondary">Disclosure Type</span>
            <span> {{ resultData['Disclosure Type'] }}</span>
            <br>
            <span class="body-2 text--secondary">Result Timestamp</span>
            <span> {{ resultData['Timestamp'] }}</span>
          </p>
        </template>
        <template
          v-else
        >
          <p>
            <span class="font-italic text--secondary">DBS Results not yet available</span>
          </p>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'CantiumBasic',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    activityData() {
      return this.caseActivityDetails.result_data;
    },
    applicationData() {
      return 'Application Data' in this.activityData ? this.activityData['Application Data'] : null;
    },
    receiptData() {
      return (
        'DBS Basic Receipts' in this.activityData ? this.activityData['DBS Basic Receipts'] : null
      );
    },
    resultData() {
      return (
        'DBS Basic Results' in this.activityData ? this.activityData['DBS Basic Results'] : null
      );
    },
    hasApplicationData() {
      return this.applicationData && !('placeholder' in this.applicationData);
    },
    hasReceipts() {
      return this.receiptData && !('placeholder' in this.receiptData);
    },
    hasResults() {
      return this.resultData && this.resultData.placeholder !== 'No results data available.';
    },
  },
};
</script>
