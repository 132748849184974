import authRoutes from './auth';

const publicRoutes = [
  ...authRoutes,
  {
    path: '/self-initiate/:segmentId/:portalPath',
    component: () => import(
      /* webpackChunkName: "public" */
      '@/components/public/registration_portals/Landing.vue'
    ),
    props: true,
    name: 'public-registration-portal',
  },
  {
    path: '/payment-portal/:id',
    component: () => import(
      /* webpackChunkName: "public" */
      '@/components/public/payments/ProductPaymentIntent.vue'
    ),
    props: true,
    name: 'external-payment-portal',
  },
  {
    path: '/dbs-document-picker',
    component: () => import(
      /* webpackChunkName: "public" */
      '@/pages/public/DBSDocumentPicker.vue'
    ),
    props: false,
    name: 'dbs-document-picker',
  },
];

export default publicRoutes.map(
  (r) => Object.assign(r, { meta: { public: true, hideBreadcrumbs: true } }),
);
