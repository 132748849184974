<template>
  <div id="q-app">
    <MaintenancePage v-if="isDown" />
    <transition
      v-else
      name="fade"
      mode="out-in"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MaintenancePage from '@/pages/Maintenance.vue';

export default {
  components: {
    MaintenancePage,
  },
  data: () => ({
    drawer: null,
  }),
  computed: {
    ...mapGetters('ui', ['isDown']),
  },
  beforeCreate() {
    this.$store.dispatch('ui/init', { vuetify: this.$vuetify });
  },
  created() {
    this.sessionPoll = setInterval(() => this.$store.dispatch('auth/checkSession', { initial: false }), 5000);
  },
  beforeDestroy() {
    clearInterval(this.sessionPoll);
  },
};
</script>
