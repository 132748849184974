export default [
  {
    path: '/audit-log',
    component: () => import(/* webpackChunkName: "audit" */ '@/pages/product/agents/AuditLog.vue'),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "audit" */
          '@/components/product/audit_log/List.vue'
        ),
        name: 'audit-list',
        meta: {
          permissions: ['audit.view_auditaction'],
        },
      },
    ],
  },
];
