import {
  axiosInstance, callbacks, request, viewsetActions,
} from '@/service/plugin/util';

class RestService {
  constructor(options = {}) {
    Object.assign(this, options.resources);
    this.request = request;
    this.setCommonHeaders = (headers) => {
      axiosInstance.defaults.headers.common = headers;
    };
  }
}

RestService.install = (Vue) => {
  Vue.mixin({
    beforeCreate() {
      const options = this.$options;
      if (options.service) {
        Vue.service = options.service; // eslint-disable-line no-param-reassign
        this.$service = options.service;
      } else if (options.parent && options.parent.$service) {
        this.$service = options.parent.$service;
      }
    },
  });
};

RestService.axios = axiosInstance;

export {
  RestService,
  viewsetActions,
  request,
  callbacks,
};
