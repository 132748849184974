import { viewsetActions } from '@/service/plugin';

const extraActions = {
  getStripeDetails: { path: 'stripe-details', method: 'get', detail: true },
  refund: { path: 'refund', method: 'post', detail: true },
};
const portalPayments = viewsetActions('portal-payments', { basePath: '/api/ops/', extraActions });

export default {
  portalPayments,
  productPayments: viewsetActions('product-payments', { basePath: '/api/ops/' }),
};
