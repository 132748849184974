export default [
  {
    path: '/payment-settings',
    component: () => import(
      /* webpackChunkName: "settings" */
      '@/pages/product/config/PaymentSettings.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "settings" */
          '@/components/product/payment_settings/Detail.vue'
        ),
        name: 'payment-settings',
        meta: {
          permissions: ['customers.change_customerpaymentsettings'],
        },
      },
      {
        path: 'add-payment-method',
        component: () => import(
          /* webpackChunkName: "settings" */
          '@/components/product/payment_settings/AddPaymentMethod.vue'
        ),
        name: 'payment-method-add',
        meta: {
          permissions: ['customers.change_customerpaymentsettings'],
        },
      },
    ],
  },
];
