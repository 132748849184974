<template>
  <div>
    <input
      ref="input"
      type="hidden"
      :value="identifier"
    >
    <v-btn
      outlined
      x-small
      class="uuid-hoverable"
      @click="copyId"
    >
      <v-icon
        left
        x-small
      >
        mdi-content-copy
      </v-icon>
      <span
        v-if="identifier"
        class="uuid-span d-inline-block truncate-text"
      >
        ID: {{ identifier }}
      </span>
      <span
        v-else
        class="uuid-span d-inline-block truncate-text"
      >
        ID: {{ uuid }}
      </span>
    </v-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'UuidCopy',
  props: {
    uuid: {
      type: String,
      required: true,
    },
    contentTypeName: {
      type: String,
      required: false,
      default: null,
    },
    contentTypeNumber: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState('ui', ['contentTypes']),
    identifier() {
      if (this.contentTypeNumber != null) {
        return `${this.contentTypeNumber}:${this.uuid}`;
      }
      if (this.contentTypeName != null) {
        return `${Object.keys(this.contentTypes)
          .find((key) => this.contentTypes[key] === this.contentTypeName)}:${this.uuid}`;
      }
      return false;
    },
  },
  methods: {
    copyId() {
      const val = this.$refs.input;
      val.setAttribute('type', 'text');
      val.select();
      try {
        document.execCommand('copy');
        this.$store.commit('notifications/notify', { text: 'Copied', type: 'success' }, { root: true });
      } catch (err) {
        this.$store.commit('notifications/notify', { text: 'Error copying', type: 'error' }, { root: true });
      }
      val.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    },
  },
};
</script>

<style lang='scss'>
.uuid-hoverable {

  .uuid-span{
    width:4rem;
    overflow:hidden;
    transition:width .2s;
  }

  &:hover {

    .uuid-span{
      width:14.1rem;
    }
  }

}

</style>
