<template>
  <v-form
    ref="form"
    v-model="formValid"
    class="mt-4"
    @submit.prevent="formSubmit"
  >
    <v-autocomplete
      v-model="form.country"
      :items="countryItems"
      :rules="[(v) => !!v || 'Required']"
      label="Country"
      placeholder="Start typing to search..."
    />
    <v-text-field
      v-model.trim="form.line_one"
      label="Line One"
      :rules="[(v) => !!v || 'Required']"
    />
    <v-text-field
      v-model.trim="form.line_two"
      label="Line two"
    />
    <v-text-field
      v-model.trim="form.town"
      label="Town"
      :rules="[(v) => !!v || 'Required']"
    />
    <v-text-field
      v-model.trim="form.postcode"
      label="Postcode"
      :rules="form.country === 'GBR' ? dbsPostcodeRules : dbsOptionalPostcodeRules"
      @keyup="uppercase"
    />
    <v-alert
      v-if="invalidDbsDetails"
      class="mt-8"
      type="warning"
    >
      Some of the address details don't meet the requirements for a DBS application. You can fix
      them above or provide an alternative value for the application (e.g. they don't accept some
      characters like dots or commas for addresses).
    </v-alert>
    <v-autocomplete
      v-if="invalidDbsCountry"
      v-model="form.country_dbs"
      :items="ebulkCountryItems"
      :rules="[(v) => !!v || 'Required']"
      label="DBS country"
      placeholder="Start typing to search..."
    />
    <v-text-field
      v-if="invalidDbsLineOne"
      v-model.trim="form.line_one_dbs"
      label="Address line one (for DBS)"
      :placeholder="form.line_one"
      :rules="dbsAddressLineRules"
      persistent-placeholder
    />
    <v-text-field
      v-if="invalidDbsLineTwo"
      v-model.trim="form.line_two_dbs"
      label="Address line two (for DBS)"
      :placeholder="form.line_two"
      :rules="dbsAddressLineRules"
      persistent-placeholder
    />
    <v-text-field
      v-if="invalidDbsTown"
      v-model.trim="form.town_dbs"
      label="Address town (for DBS)"
      :placeholder="form.town"
      :rules="dbsPlaceRules"
      persistent-placeholder
    />
    <div class="mt-16">
      <v-btn
        small
        color="success"
        :disabled="!formValid"
        @click="editAddress"
      >
        <v-icon left>
          mdi-content-save
        </v-icon>
        Save
      </v-btn>
      <v-btn
        small
        class="ml-2"
        color="error"
        @click="$emit('cancel')"
      >
        <v-icon left>
          mdi-cancel
        </v-icon>
        Cancel
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import {
  dbsAddressLineRules,
  dbsOptionalAddressLineRules,
  dbsPlaceRules,
  dbsPostcodeRules,
  dbsOptionalPostcodeRules,
} from '@/util/rules';

export default {
  name: 'EditAddressForm',
  props: {
    ops: {
      type: Boolean,
      default: false,
    },
    isEbulk: {
      type: Boolean,
      default: false,
    },
    addressToEdit: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data: () => ({
    form: {
      country: '',
      line_one: '',
      line_two: '',
      town: '',
      postcode: '',
      from_date: null,
      to_date: null,
      metadata: {},
      country_dbs: '',
      line_one_dbs: '',
      line_two_dbs: '',
      town_dbs: '',
      source: '',
    },
    formValid: false,
    dbsAddressLineRules,
    dbsOptionalAddressLineRules,
    dbsPlaceRules,
    dbsPostcodeRules,
    dbsOptionalPostcodeRules,
  }),
  computed: {
    ...mapGetters('utils', ['countryItems', 'ebulkCountryItems']),
    ...mapState('utils', ['countryMap']),
    invalidDbsCountry() {
      return this.isEbulk && this.form.country && this.countryMap[this.form.country].ebulk_missing;
    },
    invalidDbsLineOne() {
      if (!this.form.line_one) {
        return false;
      }
      return dbsAddressLineRules.map((func) => func(this.form.line_one)).some((v) => v !== true);
    },
    invalidDbsLineTwo() {
      if (!this.form.line_two) {
        return false;
      }
      return dbsOptionalAddressLineRules.map(
        (func) => func(this.form.line_two),
      ).some((v) => v !== true);
    },
    invalidDbsTown() {
      if (!this.form.town) {
        return false;
      }
      return dbsPlaceRules.map((func) => func(this.form.town)).some((v) => v !== true);
    },
    invalidDbsDetails() {
      return (
        this.invalidDbsCountry
        || this.invalidDbsLineOne
        || this.invalidDbsLineTwo
        || this.invalidDbsTown
      );
    },
    sv() {
      return this.ops
        ? this.$service.product.ops.caseAddresses
        : this.$service.product.caseAddresses;
    },
  },
  created() {
    this.form = this.addressToEdit ? this.addressToEdit : {};
  },
  methods: {
    uppercase() {
      this.form.postcode = this.form.postcode.toUpperCase();
    },
    editAddress() {
      this.$emit('save', { ...this.form });
    },
  },
};
</script>
