const data = {
  snackBarText: '',
  color: '',
  showSnackBar: false,
};

const getters = {
};

const notificationTypes = {
  success: 'green',
  error: 'red',
};

const mutations = {
  notify: (state, payload) => {
    state.snackBarText = payload.text;
    state.showSnackBar = true;

    if (Object.keys(notificationTypes).indexOf(payload.type) > -1) {
      state.color = notificationTypes[payload.type];
    } else {
      state.color = '';
    }
  },
  hideSnackBar: (state) => {
    state.showSnackBar = false;
  },
};

const actions = {
};

export default {
  namespaced: true,
  state: data,
  getters,
  actions,
  mutations,
};
