import { viewsetActions } from '@/service/plugin';

import cases from './cases';
import details from './details';
import payments from './payments';
import processes from './processes';

export default {
  ...cases,
  ...details,
  ...payments,
  ...processes,
  activityConsents: viewsetActions('activity-consents', { basePath: '/api/s/' }),
  contentTypes: viewsetActions('content-types', { basePath: '/api/s/' }),
};
