const parseOsDataHubAddress = (address) => {
  const parsedAddress = {};
  const buildingLine = [
    address.SUB_BUILDING_NAME,
    address.BUILDING_NAME,
  ].filter(Boolean).join(' ').replace('.', '');
  if (buildingLine) {
    parsedAddress.line_one = buildingLine;
    parsedAddress.line_two = [
      address.BUILDING_NUMBER,
      address.DEPENDENT_THOROUGHFARE_NAME,
      address.THOROUGHFARE_NAME,
      address.DEPENDENT_LOCALITY,
    ].filter(Boolean).join(' ');
  } else {
    parsedAddress.line_one = [
      address.BUILDING_NUMBER,
      address.DEPENDENT_THOROUGHFARE_NAME,
      address.THOROUGHFARE_NAME,
    ].filter(Boolean).join(' ');
    parsedAddress.line_two = address.DEPENDENT_LOCALITY || '';
  }
  parsedAddress.town = address.POST_TOWN;
  parsedAddress.postcode = address.POSTCODE;
  parsedAddress.metadata = address;
  parsedAddress.source = 'OS';
  return parsedAddress;
};

export {
  parseOsDataHubAddress, // eslint-disable-line import/prefer-default-export
};
