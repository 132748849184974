<template>
  <div>
    <ul class="value-list">
      <li
        v-for="(item, index) in items"
        :key="index"
      >
        <span class="font-weight-light mr-1">{{ item.name }}:</span>
        <template
          v-if="item.value"
        >
          <span class="font-weight-bold">{{ item.value }}</span>
        </template>
        <template
          v-else
        >
          -
        </template>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'ValueList',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang='scss'>
ul.value-list {
  list-style:none;
  padding-left:0;
}

</style>
