import Vue from 'vue';
import Vuetify from 'vuetify';
import VueTelInputVuetify from 'vue-tel-input-vuetify';

import vuetify from '@/plugins/vuetify';
import router from '@/router';
import service from '@/service';
import store from '@/store';
import App from './App.vue';
import filters from './filters'; // eslint-disable-line no-unused-vars

Vue.config.productionTip = false;

Vue.use(Vuetify);
Vue.use(VueTelInputVuetify, {
  vuetify,
});

new Vue({
  router,
  service,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
