<template>
  <v-row>
    <v-col>
      <p>
        <span class="body-2 text--secondary">Employer Name: </span>
        <span>{{ caseActivityDetails.config.employer_name }}</span>
        <br>
        <span class="body-2 text--secondary">Position: </span>
        <span>{{ caseActivityDetails.config.position }}</span>
      </p>
    </v-col>
    <v-col>
      <p>
        <span class="body-2 text--secondary">Workforce: </span>
        <span>{{ workforces[caseActivityDetails.config.workforce] }}</span>
        <br>
        <span class="body-2 text--secondary">Volunteer: </span>
        <span>{{ caseActivityDetails.config.volunteer ? 'Yes' : 'No' }}</span>
      </p>
    </v-col>
    <v-col>
      <p>
        <span class="body-2 text--secondary">Working with vulnerable adults: </span>
        <span>{{ caseActivityDetails.config.adult_barred_list ? 'Yes' : 'No' }}</span>
        <br>
        <span class="body-2 text--secondary">Working with children: </span>
        <span>{{ caseActivityDetails.config.child_barred_list ? 'Yes' : 'No' }}</span>
        <br>
        <span class="body-2 text--secondary">Working with children or adults at home address: </span>
        <span>{{ caseActivityDetails.config.working_at_home_address ? 'Yes' : 'No' }}</span>
      </p>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'RequirementConfig',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    workforces: {
      O: 'Other',
      C: 'Child',
      A: 'Adult',
      CA: 'Child and Adult',
    },
  }),
};
</script>
