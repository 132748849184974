const genderItems = [
  { value: 'male', text: 'Male' },
  { value: 'female', text: 'Female' },
];

const titleItems = [
  'Miss',
  'Mr',
  'Mrs',
  'Ms',
  'Mx',
  'Baron',
  'Baroness',
  'Brigadier',
  'Canon',
  'Captain',
  'Dr',
  'Duchess',
  'Duke',
  'Esq',
  'Father',
  'Hon',
  'Inspector',
  'Lady',
  'Lord',
  'Lt Col',
  'Major',
  'Most Rever',
  'Pastor',
  'Professor',
  'Rabbi',
  'Rev Dr',
  'Reverend',
  'Rt Reveren',
  'Sir',
  'Sister',
  'Squadron L',
  'Wg Cdr',
];

const maleTitleItems = [
  'Mr',
  'Mx',
  'Baron',
  'Brigadier',
  'Canon',
  'Captain',
  'Dr',
  'Duke',
  'Esq',
  'Father',
  'Hon',
  'Inspector',
  'Lord',
  'Lt Col',
  'Major',
  'Most Rever',
  'Pastor',
  'Professor',
  'Rabbi',
  'Rev Dr',
  'Reverend',
  'Rt Reveren',
  'Sir',
  'Squadron L',
  'Wg Cdr',
];

const femaleTitleItems = [
  'Miss',
  'Mrs',
  'Ms',
  'Mx',
  'Baroness',
  'Brigadier',
  'Canon',
  'Captain',
  'Dr',
  'Duchess',
  'Esq',
  'Hon',
  'Inspector',
  'Lady',
  'Lt Col',
  'Major',
  'Most Rever',
  'Pastor',
  'Professor',
  'Rabbi',
  'Rev Dr',
  'Reverend',
  'Rt Reveren',
  'Sister',
  'Squadron L',
  'Wg Cdr',
];

export {
  genderItems,
  titleItems,
  maleTitleItems,
  femaleTitleItems,
};
