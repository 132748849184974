const generateLicenceNumber = (subject) => {
  let surnamePart = subject.surname.replace(/[^A-Za-z]/g, '').slice(0, 5).padEnd(5, '9').toUpperCase();
  let initialsPart = (
    subject.forename.charAt(0) + (subject.middlename || '9').charAt(0)
  ).toUpperCase();
  if (subject.surname.toUpperCase() === 'N/A') {
    surnamePart = subject.forename.replace(/[^A-Za-z]/g, '').slice(0, 5).padEnd(5, '9').toUpperCase();
    initialsPart = '99';
  }
  const dob = subject.dob || '0000-00-00';
  const dobPart = dob.charAt(2) + dob.slice(5, 7) + dob.slice(8, 10) + dob.charAt(3);
  return `${surnamePart + dobPart + initialsPart}8AD44`;
};

const endorsementData = (code) => {
  // Via https://en.wikipedia.org/wiki/List_of_UK_driving_licence_endorsements & some regexes
  // Keys are the offence code, values are Description / Points / Years on licence
  // Manually changed double quotes to single on TT90

  const cleanCode = code.replaceAll(' ', '');

  const definitions = {
    AC10: ['Failing to stop after an accident', '5 - 10', '4'],
    AC20: ['Failing to give particulars or to report an accident within 24 hours', '5 - 10', '4'],
    AC30: ['Undefined accident offences', '4 - 9', '4'],
    BA10: ['Driving while disqualified by order of Court', '6', '4'],
    BA30: ['Attempting to drive while disqualified by order of Court', '6', '4'],
    BA40: ['Causing death by driving while disqualified', '3 - 11', '4'],
    BA60: ['Causing serious injury by driving while disqualified', '3 - 11', '4'],
    CD10: ['Driving without due care and attention', '3 - 9', '4'],
    CD20: ['Driving without reasonable consideration for other road users', '3 - 9', '4'],
    CD30: ['Driving without due care and attention/reasonable consideration', '3 - 9', '4'],
    CD40: ['Causing death through careless driving when unfit through drink', '3 - 11', '11'],
    CD50: ['Causing death by careless driving when unfit through drugs', '3 - 11', '11'],
    CD60: ['Causing death by careless driving when alcohol level above limit', '3 - 11', '11'],
    CD70: ['Causing death by careless driving then failing to supply a specimen for alcohol analysis', '3 - 11', '11'],
    CD80: ['Causing death by careless, or inconsiderate, driving', '3 - 11', '4'],
    CD90: ['Causing death by driving: unlicensed, disqualified or uninsured drivers', '3 - 11', '4'],
    CU10: ['Using a vehicle with defective brakes', '3', '4'],
    CU20: ['Causing or likely to cause danger by reason of use of unsuitable vehicle or using a vehicle with parts or accessories (excluding brakes, steering or tyres) in a dangerous condition', '3', '4'],
    CU30: ['Using a vehicle with defective tyre(s)', '3', '4'],
    CU40: ['Using a vehicle with defective steering', '3', '4'],
    CU50: ['Causing or likely to cause danger by reason of load or passengers', '3', '4'],
    CU80: ['Breach of requirements as to control of the vehicle, mobile telephone etc.', '6', '4'],
    DD10: ['Causing serious injury by dangerous driving', '3 - 11', '4'],
    DD40: ['Dangerous Driving', '3 - 11', '4'],
    DD60: ['Manslaughter or culpable homicide while driving a vehicle', '3 - 11', '4'],
    DD80: ['Causing death by dangerous driving', '3 - 11', '4'],
    DD90: ['Furious driving', '3 - 9', '4'],
    DR10: ['Driving or attempting to drive with alcohol concentration above limit', '3 - 11', '11'],
    DR20: ['Driving or attempting to drive when unfit through drink', '3 - 11', '11'],
    DR30: ['Driving or attempting to drive then refusing to provide a specimen', '3 - 11', '11'],
    DR31: ['Driving or attempting to drive then refusing to give permission for analysis of a blood sample that was taken without consent due to incapacity', '3 - 11', '11'],
    DR40: ['In charge of a vehicle while alcohol level above limit', '10', '4'],
    DR50: ['In charge of a vehicle while unfit through drink', '10', '4'],
    DR60: ['Failure to provide a specimen for analysis in circumstances other than driving or attempting to drive', '10', '4'],
    DR61: ['Refusing to give permission for analysis of a blood sample that was taken without consent due to incapacity in circumstances other than driving or attempting to drive', '10', '11'],
    DR70: ['Failing to provide specimen for breath test', '4', '4'],
    DG10: ['Driving or attempting to drive with drug level above the specified limit', '3 - 11', '11'],
    DG40: ['In charge of a vehicle while drug level above specified limit', '10', '4'],
    DG60: ['Causing death by careless driving with drug level above the limit', '3 - 11', '11'],
    DR80: ['Driving or attempting to drive when unfit through drugs', '3 - 11', '11'],
    DR90: ['In charge of a vehicle when unfit through drugs', '10', '4'],
    IN10: ['Using a vehicle uninsured against third party risks', '6 - 8', '4'],
    LC20: ['Driving otherwise than in accordance with a licence', '3 - 6', '4'],
    LC30: ['Driving after making a false declaration about fitness when applying for a licence', '3 - 6', '4'],
    LC40: ['Driving a vehicle having failed to notify a disability', '3 - 6', '4'],
    LC50: ['Driving after a licence has been revoked or refused on medical grounds', '3 - 6', '4'],
    MS10: ['Leaving a vehicle in a dangerous position', '3', '4'],
    MS20: ['Unlawful pillion riding', '3', '4'],
    MS30: ['Playstreet Offence', '2', '4'],
    MS50: ['Motor racing on the highway', '3 - 11', '4'],
    MS60: ['Offences not covered by other codes (including offences relating to breach of requirements as to control of vehicle)', '3', '4'],
    MS70: ['Driving with uncorrected defective eyesight', '3', '4'],
    MS80: ['Refusing to submit to an eyesight test', '3', '4'],
    MS90: ['Failing to give information as to identity of driver etc.', '6', '4'],
    MW10: ['Contravention of Special Roads Regulations (excluding speed limits)', '3', '4'],
    PC10: ['Undefined contravention of Pedestrian crossing Regulations', '3', '4'],
    PC20: ['Contravention of Pedestrian crossing Regulations with moving vehicle', '3', '4'],
    PC30: ['Contravention of Pedestrian crossing Regulations Stationary vehicle', '3', '4'],
    SP10: ['Exceeding goods vehicle speed limit', '3 - 6', '4'],
    SP20: ['Exceeding speed limit for type of vehicle (excluding goods or passenger vehicles)', '3 - 6', '4'],
    SP30: ['Exceeding statutory speed limit on a public road', '3 - 6', '4'],
    SP40: ['Exceeding passenger vehicle speed limit', '3 - 6', '4'],
    SP50: ['Exceeding speed limit on a motorway', '3 - 6', '4'],
    TS10: ['Failing to comply with traffic light signals', '3', '4'],
    TS20: ['Failing to comply with double white lines', '3', '4'],
    TS30: ["Failing to comply with a 'Stop' sign", '3', '4'],
    TS40: ['Failing to comply with directions of a constable/traffic warden', '3', '4'],
    TS50: ['Failing to comply with traffic sign (excluding ‘stop’ signs, traffic lights or double white lines)', '3', '4'],
    TS60: ['Failing to comply with a school crossing patrol sign', '3', '4'],
    TS70: ['Undefined failure to comply with a traffic direction sign', '3', '4'],
    TT99: ["Disqualified for having more than 12 points in the 'totting up' process", 'N/A', '4 (This endorsement removes the ones that contribute to it).'],
    UT50: ['Aggravated taking of a vehicle', '3 - 11', '4'],
    MR09: ['Reckless or dangerous driving (whether or not resulting in death, injury or serious risk)', 'N/A', '4'],
    MR19: ['Wilful failure to carry out the obligation placed on driver after being involved in a road accident (hit and run)', 'N/A', '4'],
    MR29: ['Driving a vehicle while under the influence of alcohol or other substance affecting or diminishing the mental and physical abilities of a driver', 'N/A', '4'],
    MR39: ['Driving a vehicle faster than the permitted speed', 'N/A', '4'],
    MR49: ['Driving a vehicle whilst disqualified', 'N/A', '4'],
    MR59: ['Other conduct constituting an offence for which a driving disqualification has been imposed by the State of Offence', 'N/A', '4'],
  };

  if (cleanCode in definitions) {
    return definitions[cleanCode];
  }
  return ['Unrecognised code', '-', '-'];
};

export {
  generateLicenceNumber,
  endorsementData,
};
