<template>
  <v-simple-table>
    <template #default>
      <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="text-left" />
          <th class="text-left">
            Percentage
          </th>
          <th class="text-left">
            Points
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="row in items"
          :key="row[0]"
        >
          <td>{{ row[0] }}</td>
          <td>{{ row[1] }}</td>
          <td>{{ row[2] }}</td>
          <td>{{ row[3] }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'ResultTable',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    finishedAt() {
      return this.result.time_finished * 1000;
    },
  },
};
</script>
