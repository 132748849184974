export default [
  {
    path: '/ops/portal-payments/',
    component: () => import(
      /* webpackChunkName: "ops-payments" */
      '@/pages/product/ops/PortalPayments.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "ops-payments" */
          '@/components/product/ops/portal_payments/List.vue'
        ),
        name: 'ops-portal-payment-list',
        meta: {
          permissions: ['payments.view_all_portal_payments'],
        },
      },
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "ops-payments" */
          '@/components/product/ops/portal_payments/Detail.vue'
        ),
        props: true,
        name: 'ops-portal-payment-detail',
        meta: {
          permissions: ['payments.view_all_portal_payments'],
        },
      },
    ],
  },
  {
    path: '/ops/product-payments/',
    component: () => import(
      /* webpackChunkName: "ops-payments" */
      '@/pages/product/ops/ProductPayments.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "ops-payments" */
          '@/components/product/ops/product_payments/List.vue'
        ),
        name: 'ops-product-payment-list',
        meta: {
          permissions: ['payments.view_all_product_payments'],
        },
      },
    ],
  },
];
