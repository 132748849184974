export default [
  {
    path: '/cases',
    component: () => import(/* webpackChunkName: "cases" */ '@/pages/product/agents/Cases.vue'),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "cases" */
          '@/components/product/cases/List.vue'
        ),
        name: 'case-list',
        meta: {
          permissions: ['cases.view_case'],
        },
      },
      {
        path: 'add',
        component: () => import(
          /* webpackChunkName: "cases" */
          '@/components/product/cases/Add.vue'
        ),
        name: 'case-add',
        meta: {
          permissions: ['cases.add_case', 'subjects.add_subject'],
        },
      },
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "cases" */
          '@/components/product/cases/Detail.vue'
        ),
        props: true,
        name: 'case-detail',
        meta: {
          permissions: ['cases.view_case'],
        },
      },
    ],
  },
];
