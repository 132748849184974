import { viewsetActions } from '@/service/plugin';

const flagExtraActions = {
  clear: { path: 'clear', method: 'post', detail: true },
  reactivate: { path: 'reactivate', method: 'post', detail: true },
  activeCount: { path: 'active-count', method: 'get', detail: false },
};

const flags = viewsetActions('flags', { extraActions: flagExtraActions });

export default flags;
