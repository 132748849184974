<template>
  <v-data-table
    :headers="headers"
    :items="addresses"
    hide-default-footer
    disable-pagination
    class="ml-4 mr-4"
  >
    <template #item.fullAddress="{ item }">
      {{ [item.line_one, item.line_two, item.town, item.postcode].filter(Boolean).join(', ') }}
    </template>
    <template #item.from_date="{ item }">
      {{ longIsoDate(item.from_date) }}
    </template>
    <template #item.to_date="{ item }">
      {{ item.current ? 'Current' : longIsoDate(item.to_date) }}
    </template>
  </v-data-table>
</template>

<script>
import { longIsoDate } from '@/filters';

export default {
  name: 'AddressHistory',
  props: {
    caseActivityDetails: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    addresses: [],
    headers: [
      {
        text: 'Address',
        value: 'fullAddress',
      },
      {
        text: 'Country',
        value: 'country',
      },
      {
        text: 'From',
        value: 'from_date',
      },
      {
        text: 'To',
        value: 'to_date',
      },
    ],
  }),
  created() {
    this.getAddresses();
  },
  methods: {
    async getAddresses() {
      const resp = await this.$service.subject.caseAddresses.list(
        { params: { case: this.caseActivityDetails.case.id } },
      );
      this.addresses = resp.data;
    },
    longIsoDate,
  },
};
</script>
