export default [
  {
    path: '/segments',
    component: () => import(
      /* webpackChunkName: "segments" */
      '@/pages/product/config/Segments.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "segments" */
          '@/components/product/segments/List.vue'
        ),
        name: 'segment-list',
        meta: {
          permissions: ['customers.view_segment'],
        },
      },
      {
        path: 'add',
        component: () => import(
          /* webpackChunkName: "segments" */
          '@/components/product/segments/Add.vue'
        ),
        props: true,
        name: 'segment-add',
        meta: {
          permissions: ['customers.add_segment'],
        },
      },
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "segments" */
          '@/components/product/segments/Detail.vue'
        ),
        props: true,
        children: [
          {
            path: '',
            component: () => import(
              /* webpackChunkName: "segments" */
              '@/components/product/segments/detail_tabs/Overview.vue'
            ),
            name: 'segment-overview',
            meta: {
              permissions: ['customers.view_segment'],
            },
          },
          {
            path: 'details/',
            component: () => import(
              /* webpackChunkName: "segments" */
              '@/components/product/segments/detail_tabs/Details.vue'
            ),
            name: 'segment-details',
            meta: {
              permissions: ['customers.change_segment'],
            },
          },
          {
            path: 'users/',
            component: () => import(
              /* webpackChunkName: "segments" */
              '@/components/product/segments/detail_tabs/Users.vue'
            ),
            name: 'segment-users',
            meta: {
              permissions: ['users.view_productuser'],
            },
          },
          {
            path: 'processes/',
            component: () => import(
              /* webpackChunkName: "segments" */
              '@/components/product/segments/detail_tabs/Processes.vue'
            ),
            name: 'segment-processes',
            meta: {
              permissions: ['processes.view_process'],
            },
          },
        ],
      },
    ],
  },
];
