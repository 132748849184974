<template>
  <div>
    <div v-if="!addressEdit && !addressAdd">
      <v-container fluid>
        <v-data-table
          :headers="tableHeaders"
          :items="addressesAndGaps"
          hide-default-footer
          disable-pagination
          class="mt-4 mb-16"
        >
          <template #item.fullAddress="{ item }">
            {{ item.fullAddress === 'Gap' ? 'Missing address information' : item.fullAddress }}
          </template>
          <template #item.fromDate="{ item }">
            {{ longDate(item.fromDate) }}
          </template>
          <template #item.toDate="{ item }">
            {{ item.current ? 'Current' : longDate(item.toDate) }}
          </template>
          <template #item.actions="{ item }">
            <v-menu>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list v-if="item.fullAddress !== 'Gap'">
                <v-list-item
                  :address-edit="true"
                  :disabled="item.source == 'idsp'"
                  @click="editAddressDetails(item)"
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-pencil
                  </v-icon>
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>
                <v-list-item
                  :disabled="item.source == 'idsp'"
                  @click="removeAddress(item.id)"
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-delete
                  </v-icon>
                  <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
              </v-list>
              <v-list v-else>
                <v-list-item
                  @click="fillGap(item)"
                >
                  <v-icon
                    small
                    left
                  >
                    mdi-calendar-expand-horizontal
                  </v-icon>
                  <v-list-item-title>Fill Gap</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template #no-data>
            No data yet.
          </template>
          <template #footer>
            <v-btn
              color="success"
              class="mt-4 float-right"
              small
              @click="addressAdd = true"
            >
              <v-icon left>
                mdi-plus
              </v-icon>
              Add
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </div>
    <EditCaseAddress
      v-if="addressEdit"
      :address-to-edit="addressToEdit"
      :ops="ops"
      :is-ebulk="isEbulk"
      @save="editAddress"
      @cancel="addressEdit = false"
    />
    <AddCaseAddress
      v-if="addressAdd"
      :current="!hasCurrentAddress"
      :ops="ops"
      :is-ebulk="isEbulk"
      :to-date="formGap.toDate"
      :from-date="formGap.fromDate"
      @save="addAddress"
      @cancel="addressAdd = false"
    />
  </div>
</template>

<script>
import { longDate } from '@/filters';
import EditCaseAddress from './EditCaseAddress.vue';
import AddCaseAddress from './AddCaseAddress.vue';

export default {
  name: 'AddressHistory',
  components: {
    EditCaseAddress,
    AddCaseAddress,
  },
  props: {
    caseId: {
      type: String,
      required: true,
    },
    ops: {
      type: Boolean,
      default: false,
    },
    isEbulk: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    addresses: [],
    addressEdit: false,
    addressToEdit: null,
    addressAdd: false,
    address: null,
    formGap: {
      fromDate: null,
      toDate: null,
    },
    tableHeaders: [
      {
        text: 'Address',
        value: 'fullAddress',
      },
      {
        text: 'From',
        value: 'fromDate',
      },
      {
        text: 'To',
        value: 'toDate',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ],
  }),
  computed: {
    hasCurrentAddress() {
      return Boolean(this.addresses.find((address) => address.current));
    },
    addressesAndGaps() {
      const items = [];

      // TODO remove this and allow adding for as long as user wants
      const start = 12 * 5;

      const sortedAddresses = [...this.addresses].sort(
        (a, b) => ((a.from_date > b.from_date) ? 1 : -1),
      );

      let coverageStart = new Date(new Date().toDateString());
      coverageStart.setMonth(coverageStart.getMonth() - start);

      sortedAddresses.forEach((address) => {
        const fromDate = new Date(address.from_date);
        const toDate = address.current ? new Date() : new Date(address.to_date);
        const gapEnd = new Date(address.from_date);
        // make sure dates are at hour zero due to timezone funkyness
        gapEnd.setDate(gapEnd.getDate() - 1);
        gapEnd.setHours(0);
        coverageStart.setHours(0);
        if (gapEnd > coverageStart) {
          items.push({
            fullAddress: 'Gap',
            fromDate: coverageStart,
            toDate: gapEnd,
          });
        }
        items.push({
          id: address.id,
          fullAddress: this.getFullAddress(address),
          line_one: address.line_one,
          line_two: address.line_two,
          town: address.town,
          postcode: address.postcode,
          current: address.current,
          source: address.source,
          country: address.country,
          fromDate,
          toDate,
        });
        if (toDate > coverageStart) {
          coverageStart = toDate;
        }
      });
      return items.reverse();
    },
    hasGaps() {
      return (
        this.addresses.length === 0
        || Boolean(this.addressesAndGaps.find((item) => item.fullAddress === 'Gap'))
      );
    },
    sv() {
      return this.ops
        ? this.$service.product.ops.caseAddresses
        : this.$service.product.caseAddresses;
    },
  },
  created() {
    this.getAddresses();
  },
  methods: {
    editAddressDetails(address) {
      this.addressToEdit = address;
      this.addressEdit = true;
      this.addressToEdit.metadata = {};
      this.addressToEdit.source = 'manual';
    },
    async getAddresses() {
      const resp = await this.sv.list({ params: { case: this.caseId } });
      this.addresses = resp.data;
    },
    getFullAddress(item) {
      return [item.line_one, item.line_two, item.town, item.postcode].filter(Boolean).join(', ');
    },
    fillGap(item) {
      this.formGap.fromDate = item.fromDate;
      this.formGap.toDate = item.toDate;
      this.addressAdd = true;
    },
    async addAddress(address) {
      const resp = await this.sv.create({ ...address, case: this.caseId });
      if (resp.status === 201) {
        this.addresses.push({ ...address, id: resp.data.id });
        this.addressAdd = false;
        this.getAddresses();
      }
    },
    async editAddress(address) {
      const resp = await this.sv.partialUpdate(address.id, { ...address });
      if (resp.status === 200) {
        this.addresses.push({ ...address, id: resp.data.id });
        this.addressEdit = false;
        this.getAddresses();
      }
    },
    async removeAddress(itemId) {
      this.formGap.fromDate = null;
      this.formGap.toDate = null;
      const resp = await this.sv.delete(itemId);
      if (resp.status === 204) {
        const index = this.addresses.findIndex((item) => item.id === itemId);
        this.addresses.splice(index, 1);
      }
    },
    longDate,
  },
};
</script>
1
