<template>
  <div>
    <v-card-subtitle>{{ collector.name }}</v-card-subtitle>
    <v-row
      v-for="(doc, index) in documents.filter(d => !d.rejected)"
      :key="doc.id"
      class="mt-4"
    >
      <v-col
        v-if="index > 0"
        cols="12"
        class="mt-6"
      >
        <v-divider />
      </v-col>
      <v-col cols="6">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ doc.document_type.name }}</v-list-item-title>
              <v-list-item-subtitle>Type</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="doc.source !== 'idsp'">
            <v-list-item-content>
              <v-list-item-title>{{ doc.group.name }}</v-list-item-title>
              <v-list-item-subtitle>Group</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ doc.route_index + 1 }}</v-list-item-title>
              <v-list-item-subtitle>Route</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="6">
        <v-card
          outlined
          class="pa-2"
        >
          <PdfThumbnail
            v-if="doc.extension === 'pdf'"
            class="doc-image"
            :src="doc.content_url"
            @click="showDocument(doc)"
          />
          <v-img
            v-else
            class="doc-image"
            :src="doc.content_url"
            @click="showDocument(doc)"
          />
        </v-card>
        <v-btn
          class="mt-4 float-right"
          :href="getDownloadLink(doc)"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon
            left
          >
            mdi-open-in-new
          </v-icon>
          Open
        </v-btn>
      </v-col>
    </v-row>
    <DocumentViewer
      v-if="docViewer.doc.id"
      :key="`viewer-${docViewer.doc.id}`"
      v-model="docViewer.show"
      :src="docViewer.doc.content_url"
      :title="docViewer.doc.document_type.name"
      :extension="docViewer.doc.extension"
    />
  </div>
</template>
<script>
import PdfThumbnail from '@/components/common/PdfThumbnail.vue';
import DocumentViewer from '@/components/common/DocumentViewer.vue';

export default {
  name: 'CollectorDetails',
  components: {
    PdfThumbnail,
    DocumentViewer,
  },
  props: {
    collector: {
      type: Object,
      required: true,
    },
    documents: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    docViewer: {
      show: false,
      doc: {},
    },
  }),
  methods: {
    showDocument(doc) {
      this.docViewer.doc = doc;
      this.docViewer.show = true;
    },
    getDownloadLink(doc) {
      return `/api/s/case-documents/${doc.id}/download/?inline=true`;
    },
  },
};
</script>
<style scoped lang="scss">
.doc-image:hover {
  cursor: pointer;
}
</style>
