import { viewsetActions } from '@/service/plugin';

export default {
  subjects: viewsetActions('subjects', {
    extraActions: {
      archive: { path: 'archive', method: 'post', detail: true },
    },
  }),
  dbsUpdateDetails: viewsetActions('dbs-update-details'),
  subjectActivityConsents: viewsetActions('subject-activity-consents'),
};
