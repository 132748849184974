export default [
  {
    path: '/ops/outstanding-activities',
    component: () => import(
      /* webpackChunkName: "ops-activities" */
      '@/pages/product/ops/OutstandingActivities.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "ops-activities" */
          '@/components/product/ops/outstanding_activities/List.vue'
        ),
        name: 'ops-outstanding-activity-list',
        meta: {
          permissions: ['cases.view_all_activities'],
        },
      },
      {
        path: ':id',
        component: () => import(
          /* webpackChunkName: "ops-activities" */
          '@/components/product/ops/outstanding_activities/Detail.vue'
        ),
        props: true,
        name: 'ops-outstanding-activity-detail',
        meta: {
          permissions: ['cases.view_all_activities'],
        },
      },
    ],
  },
  {
    path: '/ops/davis-to-start-activities',
    component: () => import(
      /* webpackChunkName: "ops-activities" */
      '@/pages/ops/DavisChecksToStart.vue'
    ),
    children: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "ops-activities" */
          '@/components/ops/davis_to_start/List.vue'
        ),
        name: 'davis-to-start-activity-list',
        meta: {
          permissions: ['cases.view_all_activities'],
        },
      },
    ],
  },
];
