import Vue from 'vue';
import VueRouter from 'vue-router';

import productRoutes from './product';
import publicRoutes from './public';
import subjectRoutes from './subject';
import lmsProductRoutes from './lms/product';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "generic" */ '@/layouts/product/Main.vue'),
    children: [
      ...productRoutes,
      ...lmsProductRoutes,
    ],
  },
  {
    path: '/s/',
    component: () => import(/* webpackChunkName: "generic" */ '@/layouts/subject/Main.vue'),
    children: subjectRoutes,
  },
  {
    path: '/',
    component: () => import(/* webpackChunkName: "generic" */ '@/layouts/public/Main.vue'),
    children: [
      ...publicRoutes,
      {
        path: 'bootstrap',
        component: () => import(/* webpackChunkName: "generic" */ '@/pages/Bootstrap.vue'),
        name: 'bootstrap',
      },
      {
        path: '*',
        component: () => import(/* webpackChunkName: "generic" */ '@/pages/404.vue'),
        name: '404',
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

const checkAccess = (to, next) => {
  const { profile } = router.app.$store.state.users;
  const hasEntitlement = router.app.$store.getters['ui/hasEntitlement'];
  const userPerms = profile.permissions;
  const { userType } = router.app.$store.state.auth;
  const dashboardName = userType === 'ProductUser' ? 'dashboard' : 's:dashboard';

  const invalidPath = (
    to.meta.public
    || (to.meta.permissions && to.meta.permissions.some((p) => !userPerms.includes(p)))
    || (to.meta.entitlements && to.meta.entitlements.some((e) => !hasEntitlement(e)))
    || (userType === 'SubjectUser' && !to.path.startsWith('/s/'))
    || (userType === 'ProductUser' && to.path.startsWith('/s/'))
  );

  const missingProfileData = userType === 'SubjectUser' && !(profile.dob && profile.termsAgreedAt);

  if (to.path === '/') {
    next({ name: dashboardName });
  } else if (to.name === 'public-registration-portal' && userType === 'SubjectUser') {
    next({ name: 's:public-registration-portal', params: to.params });
  } else if (invalidPath && !to.path.startsWith('/auth/callback')) {
    router.app.$store.commit('notifications/notify', { text: 'Permission denied', type: 'error' });
    next({ name: dashboardName });
  } else if (missingProfileData && to.name !== 's:complete-profile') {
    next({ name: 's:complete-profile', query: { next: to.fullPath } });
  } else {
    next();
  }
};

router.beforeResolve((to, from, next) => {
  const store = router.app.$store;
  if (store.getters['auth/isAuthenticated']) {
    if (to.name === 'bootstrap' || to.path.startsWith('/auth/callback')) {
      next();
    } else if (router.app.$store.state.ui.bootstrapped) {
      checkAccess(to, next);
    } else {
      const query = to.fullPath === '/' ? {} : { next: to.fullPath };
      next({ name: 'bootstrap', query });
    }
  } else if (to.meta.public) {
    next();
  } else {
    const query = to.fullPath === '/' ? {} : { next: to.fullPath };
    if (to.path.startsWith('/s/')) {
      query.subject = 'y';
    }
    next({ name: 'login', query });
  }
});

export default router;
